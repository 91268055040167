<template>
  <b-modal
    id="modal-profile-privacy"
    ref="modal-profile-privacy"
    size="lg"
    close-on-backdrop
    hide-footer
  >
    <template v-slot:modal-header="{ close }">
      <h4>Profile Privacy</h4>
      <b-button 
        size="sm"
        class="btn-close"
        @click="close"
      />
    </template>

    <template v-slot:default>
      <template v-if="currentStep === 1">
        <b-alert
          class="info round"
          show
        >
          <div class="alert-box-content text-center">
            <b-icon
              icon="exclamation-circle"
              variant="warning"
            />              
            <span class="alert-box-message">
              Be careful about your data privacy!
            </span>
            <span class="alert-box-details">
              <br>
              Call Of Duty API is public and does not allow you to configure where, when and with who you want to share your data.<br><br>
              By switching your profile data to visible on your Activision account, you will allow any tiers system to access them.<br>
              Their AIM are mostly to provide insights to the players by :<br>
              - Compiling stats and games details<br>
              - Consolidating leaderboards<br>
              - Consolidating loadout details<br>
              - Compiling time consumption<br>
              <br>
              Most of those systems will automatically pull any data they have access to without any consent from the player.
              <br>
              Those data may be used to improve the game, as economic value or just for fun.
              <br>
              You can find a sample of the data retrieved from this website <a
                href="https://repo.madbison.net/sample-stats.json"
                target="_blank"
                class="text-white"
              >
                here!</a>
              <br><br>
              At any point in time you can switch back your profile data to private!
              <b-button
                class="mt-3 d-block ml-auto mr-auto mt-3"
                @click="goNext()"
              >
                Ok, I understand
              </b-button>
            </span>
          </div>
        </b-alert>
      </template> 
      <template v-else>
        <div class="step">
          <div class="step-header">
            <div class="step-number">
              1
            </div>
            <div class="step-title">
              Login in Call Of Duty
            </div>              
          </div>
          <div class="step-content">
            <b-badge
              size="lg"
              variant="dark"
              class="mb-2"
            >
              <a
                class="text-white"
                target="_blank"
                href="https://profile.callofduty.com/cod/login"
              >
                https://profile.callofduty.com/cod/login
              </a>
            </b-badge>
            <img src="@/assets/images/help/login.png">
            <small class="text-gray">
              You may choose to login with your linked account
            </small>
          </div>          
        </div>

        <div class="step">
          <div class="step-header">
            <div class="step-number">
              2
            </div>
            <div class="step-title">
              Go to "Linked Accounts"
            </div>              
          </div>
          <div class="step-content">
            <img src="@/assets/images/help/linked-accounts.png">
          </div>          
        </div>

        <div class="step">
          <div class="step-header">
            <div class="step-number">
              3
            </div>
            <div class="step-title">
              Set your data visibility to "All"
            </div>              
          </div>
          <div class="step-content">
            <img src="@/assets/images/help/visibility.png">

            <small class="text-gray">
              May depends on your linked account
            </small>
          </div>          
        </div>  
      </template>      
    </template>   
  </b-modal>
</template>

<style lang="scss" scoped>

  @import '@/assets/style/colors.scss';

  .modal {
    & .modal-custom-footer {
      display: flex;
      margin-top: 0px;
      height: 50px;
    }
  }

  div.step {
    display: flex;
    flex-direction: column;

    & > div.step-header {
      display: flex;
      margin-bottom: 16px;
      margin-top: 16px;

      &:first-child {
        margin-top: 0px;
      }

      & > div.step-number {
        width: 40px;
        height: 40px;
        background-color: $color-woodsmoke;
        border-radius: 20px;
        color: $color-yellow;
        font-size: 24px;
        line-height: 40px;
        text-align: center;        
      }

      & > div.step-title {
        line-height: 40px;
        margin-left: 16px;
      }
    }

    & > div.step-content {
      text-align: center;

      & > img {
        width: 100%;
        max-width: 420px;
        display: block;
        margin: 0 auto;
      }
    }
  }
</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'ProfilePrivacyModal',
  components: {

  },
  props: {

  },
  data() {
    return {
      currentStep: 1
    }
  },
  computed: mapState({

  }),
  created: function() {

  },
  mounted: function() {

  },
  methods: {
    goNext() {
      this.currentStep += 1
    }
  }  
}
</script>
