<template>
  <swiper
    class="swiper"
    :options="swiperOption"
  >
    <div
      slot="pagination"
      class="swiper-pagination"
    />        
    <swiper-slide :index="1">
      <h6>Summary</h6>
      <game-chart
        :stats-keys="['kdRatio']"
        :stats="game.playerStats"
      />
      <game-chart
        :stats-keys="['kills', 'deaths', 'downs', 'assists']"
        :stats="game.playerStats"
      />                 
    </swiper-slide>
    <swiper-slide :index="2">
      <h6>Combat achievements</h6>
      <game-chart
        :stats-keys="['headshots', 'executions', 'objectiveTeamWiped', 'longestStreak', 'objectiveLastStandKill', 'objectiveReviver']"
        :stats="game.playerStats"
      />
    </swiper-slide>
    <swiper-slide :index="3">
      <h6>Damages</h6>
      <game-chart
        :stats-keys="['damageDone', 'damageTaken']"
        :stats="game.playerStats"
      />
      <game-chart
        :stats-keys="['damageDonePerKill', 'damageTakenPerDeath']"
        :stats="game.playerStats"
      />
    </swiper-slide>               
    <swiper-slide :index="4">
      <h6>Downs Repartition</h6>
      <game-chart
        :stats-keys="downsStatsKeys"
        :stats="game.playerStats"
      /> 
    </swiper-slide>  
    <swiper-slide :index="5">
      <h6>~ Lifetime</h6>
      <game-chart
        :stats-keys="['percentTimeAlive', 'percentTimeDead']"
        :stats="game.playerStats"
      />
      <game-chart
        :stats-keys="['timeAlive', 'timeDead']"
        :stats="game.playerStats"
      />        
    </swiper-slide>                        
    <swiper-slide :index="6">
      <h6>Scavenging</h6>
      <game-chart
        :stats-keys="['objectiveBrCacheOpen']"
        :stats="game.playerStats"
      />
      <game-chart
        :stats-keys="['objectiveBrCacheOpenPerTime']"
        :stats="game.playerStats"
      />        
      <game-chart
        :stats-keys="['objectiveBrCacheOpenPerDistance']"
        :stats="game.playerStats"
      />                                         
    </swiper-slide>
    <swiper-slide :index="7">
      <h6>Travelling</h6>
      <game-chart
        :stats-keys="['distanceTraveled']"
        :stats="game.playerStats"
      />                                              
    </swiper-slide>                                                              
    <swiper-slide :index="8">
      <h6>Score</h6>
      <game-chart
        :stats-keys="['score']"
        :stats="game.playerStats"
      />
      <game-chart
        :stats-keys="['scorePerMinute']"
        :stats="game.playerStats"
      />    
    </swiper-slide>                                   
  </swiper>  
</template>

<style lang="scss" scoped>
  h6 {
    text-align: center;
  }

  ul.gulag-result {
    padding: 0;

    & > li {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      & .gulag-boxes {
        max-width: calc(100vw - 60px); 
        
        & small {
          margin-top: auto;
          font-size: 8px;
        }
      }
    }
  }

  img.gulag {
    margin-top: 6px;
    width: 32px;

    &.lost {
      filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg);      
    }

    &.win {
      filter: brightness(50%) sepia(100) saturate(100) hue-rotate(90deg);      
    }    
  }  
</style>

<script>

import { mapState } from 'vuex' 

import GameChart from '@/components/games/GameChart.vue'

export default {
  name: 'GameCharts',
  components: {
    GameChart
  },
  props: {   
    game: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        freeMode: false,
        grabCursor: true,
        loop: false,
        loopFillGroupWithBlank: false,
        mousewheel: {
          forceToAxis: true,
          invert: true
        },
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }        
      }
    }
  },
  computed: mapState({
    downsStatsKeys() {
      const downKeys = ['objectiveBrDownEnemyCircle1', 'objectiveBrDownEnemyCircle2', 'objectiveBrDownEnemyCircle3', 'objectiveBrDownEnemyCircle4', 'objectiveBrDownEnemyCircle5', 'objectiveBrDownEnemyCircle6', 'objectiveBrDownEnemyCircle7', 'objectiveBrDownEnemyCircle8', 'objectiveBrDownEnemyCircle9', 'objectiveBrDownEnemyCircle10']
      let keys = []
      downKeys.forEach(
        (key) => {
          const result = this.game.playerStats[key]
          if (result > 0) {
            keys.push(key)
          }
        }
      )

      return keys
    } 
  }),  
  watch: {

  },  
  created: function() {

  },
  mounted: function() {

  },  
  beforeDestroy() {

  },  
  methods: {

  }  
}
</script>
