<template>
  <section>
    <swiper
      class="swiper"
      :options="swiperOption"
    >
      <div
        slot="pagination"
        class="swiper-pagination"
      />        
      <swiper-slide :index="1">
        <h6>Summary</h6>
        <session-chart
          :stats-keys="['kdRatio']"
          :stats="session.playersSummary"
        />
        <session-chart
          :stats-keys="['kills', 'deaths', 'downs', 'assists']"
          :stats="session.playersSummary"
        />
        <session-chart
          :stats-keys="['scorePerMinute']"
          :stats="session.playersSummary"
        />                           
      </swiper-slide>
      <swiper-slide :index="2">
        <h6>Gulag</h6>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <session-chart
            :stats-keys="['gulagPercentage']"
            :stats="session.playersSummary"
            :max="100"
          />           
          <ul class="gulag-result">
            <li
              v-for="(player, index) in Object.keys(session.playersSummary)"
              :key="index"
            >
              <div class="d-flex flex-column justify-content-center">
                <div class="text-left">
                  <b-badge
                    variant="dark"
                    class="mb-1"
                  >
                    {{ player }}
                  </b-badge>
                </div>
                <div class="d-flex flex-wrap justify-content-center gulag-boxes">
                  <div
                    v-for="(gulagResult, j) in session.playersSummary[player].gulagResults.slice().reverse()"
                    :key="j"
                    class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                    :class="isSmallDevice ? 'box-sm' : ''"
                  >
                    <template v-if="gulagResult !== null">
                      <img
                        src="~@/assets/images/gulag.png"
                        class="gulag"
                        :class="{'win': gulagResult === true, 'lost': gulagResult === false}"
                      >
                      <small class="text-gray">
                        Circle {{ session.playersSummary[player].gulagCircles.slice().reverse()[j] }}
                      </small>
                    </template>
                    <template v-else>
                      <h2 class="text-white">
                        -
                      </h2>
                    </template> 
                  </div> 
                </div> 
              </div>             
            </li>
          </ul>
        </div>
      </swiper-slide>      
      <swiper-slide :index="3">
        <h6>Combat achievements</h6>
        <session-chart
          :stats-keys="['headshots', 'executions', 'objectiveTeamWiped', 'longestStreak', 'objectiveLastStandKill', 'objectiveReviver']"
          :stats="session.playersSummary"
        />
      </swiper-slide>
      <swiper-slide :index="4">
        <h6>Damages</h6>
        <session-chart
          :stats-keys="['damageDone', 'damageTaken']"
          :stats="session.playersSummary"
        />
        <session-chart
          :stats-keys="['damageDonePerKill', 'damageTakenPerDeath']"
          :stats="session.playersSummary"
        />                
        <session-chart
          :stats-keys="['damageDonePerGame', 'damageTakenPerGame']"
          :stats="session.playersSummary"
        />
      </swiper-slide>      
      <swiper-slide
        v-if="showMoreStats === false"
        :index="5"
      >    
        <small class="text-gray">
          There are more stats available!
        </small>
        <ul>
          <li>Downs Repartition</li>
          <li>~ Lifetime</li>
          <li>Scavenging</li>
          <li>Travelling</li>
          <li>Score</li>
        </ul>
        <b-button
          size="sm"
          @click="showMore()"
        >
          Show more
        </b-button><br>        
      </swiper-slide>           
      <template v-if="showMoreStats === true">
        <swiper-slide
          :index="6"
        >
          <h6>Downs Repartition</h6>
          <session-chart
            :stats-keys="downsStatsKeys"
            :stats="session.playersSummary"
          /> 
        </swiper-slide>  
        <swiper-slide :index="7">
          <h6>~ Lifetime</h6>
          <session-chart
            :stats-keys="['percentTimeAlive', 'percentTimeDead']"
            :stats="session.playersSummary"
          />
          <session-chart
            :stats-keys="['timeAlive', 'timeDead']"
            :stats="session.playersSummary"
          />        
        </swiper-slide>                        
        <swiper-slide :index="8">
          <h6>Scavenging</h6>
          <session-chart
            :stats-keys="['objectiveBrCacheOpen']"
            :stats="session.playersSummary"
          />
          <session-chart
            :stats-keys="['objectiveBrCacheOpenPerGame']"
            :stats="session.playersSummary"
          /> 
          <session-chart
            :stats-keys="['objectiveBrCacheOpenPerTime']"
            :stats="session.playersSummary"
          />        
          <session-chart
            :stats-keys="['objectiveBrCacheOpenPerDistance']"
            :stats="session.playersSummary"
          />                                         
        </swiper-slide>
        <swiper-slide :index="9">
          <h6>Travelling</h6>
          <session-chart
            :stats-keys="['distanceTraveled']"
            :stats="session.playersSummary"
          /> 
          <session-chart
            :stats-keys="['distanceTraveledPerGame']"
            :stats="session.playersSummary"
          />
          <session-chart
            :stats-keys="['speed']"
            :stats="session.playersSummary"
          />                                                     
        </swiper-slide>                                                              
        <swiper-slide :index="10">
          <h6>Score</h6>
          <session-chart
            :stats-keys="['score']"
            :stats="session.playersSummary"
          />
          <session-chart
            :stats-keys="['scorePerMinute']"
            :stats="session.playersSummary"
          /> 
          <session-chart
            :stats-keys="['scorePerGame']"
            :stats="session.playersSummary"
          />        
        </swiper-slide> 
      </template>                           
    </swiper>   
  </section>
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

  ul:not(.gulag-result) {
    display: block;
    height: calc(100% - 40px);
    padding-top: 16px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 16px;
    background-color: $color-woodsmoke;
    border-radius: 16px;

    & > li {
      padding: 8px;
      padding-left: 24px;
      padding-right: 24px;        

      &:hover {
        background-color: rgba(255, 255, 255, 0.2)
      }
    }
  }  

  ul.gulag-result {
    padding: 0;

    & > li {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      & .gulag-boxes {
        max-width: calc(100vw - 60px); 
        
        & small {
          margin-top: auto;
          font-size: 8px;
        }
      }
    }
  }

  img.gulag {
    margin-top: 6px;
    width: 32px;

    &.lost {
      filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg);      
    }

    &.win {
      filter: brightness(50%) sepia(100) saturate(100) hue-rotate(90deg);      
    }    
  }   
</style>

<script>

import { mapState } from 'vuex' 

import SessionChart from '@/components/sessions/SessionChart.vue'

export default {
  name: 'SessionStats',
  components: {
    SessionChart
  },
  props: {
    session: {
      type: Object,
      required: true,
    } 
  },
  data() {
    return {
      isLoading: false,
      games: null,
      showMoreStats: false,
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        freeMode: false,
        grabCursor: true,
        loop: false,
        loopFillGroupWithBlank: false,
        mousewheel: {
          forceToAxis: true,
          invert: true
        },
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }        
      }      
    }
  },
  computed: mapState({
    downsStatsKeys() {
      const downKeys = ['objectiveBrDownEnemyCircle1', 'objectiveBrDownEnemyCircle2', 'objectiveBrDownEnemyCircle3', 'objectiveBrDownEnemyCircle4', 'objectiveBrDownEnemyCircle5', 'objectiveBrDownEnemyCircle6', 'objectiveBrDownEnemyCircle7', 'objectiveBrDownEnemyCircle8', 'objectiveBrDownEnemyCircle9', 'objectiveBrDownEnemyCircle10']
      let keys = []
      downKeys.forEach(
        (key) => {
          const index = Object.values(this.session.playersSummary).findIndex(
            (summary) => {
              return summary[key] > 0
            }
          )
          if (index >= 0) {
            keys.push(key)
          }
        }
      )

      return keys
    } 
  }),  
  created: function() {
    
  },
  mounted: function() {

  },  
  methods: {
    showMore() {
      this.showMoreStats = true
    }
  }  
}
</script>
