<template>
  <b-modal
    id="modal-upload"
    size="lg"
    no-close-on-backdrop
    hide-footer
    @hidden="resetModal"
  >
    <template v-slot:modal-header="{ close }">
      <h4>Upload your video soldier!</h4>
      <b-button 
        v-if="isLoading === false"
        size="sm"
        class="btn-close"
        @click="close()"
      />
    </template>

    <template v-slot:default>
      <template v-if="isLoading === true || gaming === true">
        <div class="text-center">
          <div
            v-if="isLoading === true"
            class="m-3"
          >
            <div class="p-3 text-center">
              Video is being uploaded... do not close the browser
            </div>
            <template v-if="progress && progress.size !== undefined && progress.percent !== undefined">
              <b-progress
                :max="100"
                show-progress
                height="48px"
              >
                <b-progress-bar :value="progress.percent">
                  <div>
                    <div>
                      <strong>{{ `${progress.percent}%` }}</strong>
                    </div>
                    <div>
                      <small>{{ `${progress.size} / ${(totalChunks * chunkSize / 1000 / 1000)} MB` }}</small>
                    </div>
                  </div>
                </b-progress-bar>
              </b-progress>
            </template>
          </div>

          <div
            v-else
            class="mb-3"
          >
            <div class="p-3 text-center">
              Video uploaded!
            </div>            
            <div class="text-center">
              <b-icon
                variant="success"
                icon="check-circle"
                style="width: 45px; height: 45px;"
              />
            </div>
          </div>

          <div class="mb-3">
            <space-invader />
          </div>

          <b-button
            v-if="isLoading === false"
            variant="primary"
            size="lg"
            class="ml-auto mr-auto"
            @click="resetModal"
          >
            Upload a new one
          </b-button>
        </div>
      </template>

      <template v-else>       
        <b-form 
          ref="upload-form"
        >
          <b-form-group
            id="input-group-file"
            class="floating-labels"
          >
            <b-form-file
              v-model="$v.form.file.$model"
              :state="Boolean($v.form.file.$model)"
              accept=".mp4"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            />
          </b-form-group>

          <b-form-group
            id="input-group-title"
            class="floating-labels"
          >
            <b-form-input
              id="input-title"
              v-model="$v.form.title.$model"
              type="text"
              required
              :placeholder="'Title'"
              :state="validateState('title')"
              aria-describedby="feedback-title"
            />
            <label for="input-title">
              Title
            </label>

            <b-form-invalid-feedback id="feedback-title">
              Please set a title
            </b-form-invalid-feedback>              
          </b-form-group> 

          <b-form-group
            id="input-group-date"
            class="datepicker floating-labels"
          >
            <date-range-picker
              ref="picker"
              v-model="$v.form.date.$model"
              :single-date-picker="true"
              :linked-calendars="true"
              :auto-apply="true"
              :always-show-calendars="true"
              :ranges="false"
              :show-dropdowns="true"
            >
              <template v-slot:input="picker">
                <span v-if="picker.startDate">
                  {{ formatDate(picker.startDate, 'dd/MM/yyyy') }}
                </span>
              </template>  

              <!--    footer slot-->
              <template v-slot:footer="">
                <div />
              </template>                            
            </date-range-picker>
            <label
              :class="{'is-floating': $v.form.date.$model}"
              class="required"
              for="input-date"
            >
              Date
            </label>
          </b-form-group>                  

          <b-form-group
            id="input-group-type"
            class="floating-labels"
          >
            <multiselect
              id="input-type"
              v-model="$v.form.type.$model"
              :placeholder="'Type'"
              required
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"              
              :options="types"
              :searchable="isSmallDevice ? false : true"
              :allow-empty="false"
              :class="{ 'is-invalid': typeInvalid === true }"
              aria-describedby="feedback-type"
              @close="validateType('reasonId')"
            >
              <template
                slot="singleLabel"
                slot-scope="{ option }"
              >
                {{ option }}
              </template>
            </multiselect>
                
            <label
              v-if="$v.form.type.$model"
              class="is-floating required"
              for="input-type"
            >
              Type
            </label>
            <b-form-invalid-feedback
              v-if="typeInvalid === true"
              id="feedback-reasonId"
            >
              Please select a type
            </b-form-invalid-feedback>
          </b-form-group>         

          <b-form-group
            id="input-group-players"
            class="floating-labels"
          >
            <multiselect
              id="input-players"
              v-model="$v.form.players.$model"
              track-by="id"
              label="label"
              :placeholder="'Squad'"
              required
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="false"              
              :options="players"
              :searchable="false"
              :allow-empty="true"
              :class="{ 'is-invalid': playersInvalid === true }"
              aria-describedby="feedback-players"
              @close="validatePlayers()"
            >
              <template
                slot="singleLabel"
                slot-scope="{ option }"
              >
                {{ option.label }}
              </template>
            </multiselect>
                
            <label
              v-if="$v.form.players.$model"
              class="is-floating required"
              for="input-players"
            >
              Squad
            </label>
            <b-form-invalid-feedback
              v-if="playersInvalid === true"
              id="feedback-reasonId"
            >
              Please select a squad
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-description"
            class="floating-labels"
          >
            <b-form-textarea
              id="input-description"
              v-model="$v.form.description.$model"
              :placeholder="'Description'"
              :state="validateState('description')"
              aria-describedby="feedback-description"
            />
            <label for="input-description">
              Description
            </label>

            <b-form-invalid-feedback id="feedback-description">
              Please type a description
            </b-form-invalid-feedback>              
          </b-form-group>                                                      
        </b-form>


        <div class="modal-custom-footer ">
          <b-button
            variant="primary"
            class="ml-auto"
            :class="isLoading === true ? 'btn-loading' : ''"
            @click="submit"
          >
            Upload
          </b-button>  
        </div>
      </template>      
    </template>   
  </b-modal>
</template>

<style lang="scss" scoped>
  .modal {
    & .modal-custom-footer {
      display: flex;
      margin-top: 0px;
      height: 50px;
    }
  }
</style>

<script>
import http from '@/services/http.service.js'

import { mapState } from 'vuex' 
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

import DateRangePicker from 'vue2-daterange-picker'

import SpaceInvader from '@/components/space-invader/SpaceInvader.vue'

export default {
  name: 'UploadModal',
  components: {
    SpaceInvader,
    DateRangePicker
  },
  mixins: [validationMixin],  
  props: {

  },
  data() {
    return {
      isLoading: false,
      hasFormError: false,
      playersInvalid: false,
      typeInvalid: false,
      gaming: false,
      chunkSize: 1024 * 1000,
      chunks: [],
      totalChunks: 0,
      progress: null,
      types: [
        'TRIAL', 'GAMEPLAY', 'TOP1'
      ],
      form: {
        file: null,
        players: null,
        type: null,
        title: null,
        description: null,
        date: {
          startDate: new Date(),
          endDate: null
        }
      }      
    }
  },
  validations: {
    form: {
      file: {
        required
      },
      players: {
        required
      },
      type: {
        required
      },
      title: {
        required
      },
      description: {

      },
      date: {
        required
      }
    }
  }, 
  computed: mapState({
    players: state => state.global.players
  }),
  watch: {
    'form.file': function(newVal) {
      if (newVal
        && this.form.title === null) {
          this.form.title = newVal.name.replace('.mp4', '')
        }
    },
    chunks: function(newVal) {
      if (newVal.length > 0) {
        this.uploadChunk(this.chunks[0])
      }
    }
  },
  created: function() {
    window.onbeforeunload = (e) => {
      if (this.isLoading === false ) return null

      e = e || window.event;

      //old browsers
      if (e) {
        e.returnValue = 'Your video will be lost, sure??'
      }

      //safari, chrome(chrome ignores text)
      return 'Your video will be lost, sure?';
    }
  },
  mounted: function() {
    
  },
  destroyed: function() {
    window.onbeforeunload = () => {

    }
  },
  methods: {
    resetModal() {
      this.form = {
        file: null,
        players: null,
        type: null,
        title: null,
        description: null,
        date: {
          startDate: new Date(),
          endDate: null
        }
      }
      this.isLoading = false
      this.hasFormError = false
      this.playersInvalid = false
      this.typeInvalid = false
      this.gaming = false
      this.chunks = []
      this.totalChunks = 0
      this.progress = null
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];

      return $dirty ? !$error : null;
    },
    validatePlayers() {
      this.playersInvalid = this.form['players'] === null
    },   
    validateType() {
      this.typeInvalid = this.form['type'] === null
    },       
    validateForm: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      }

      return true
    },
    createChunks() {
      let parts = Math.ceil(this.form.file.size / this.chunkSize)
      let chunks = []
      for (let i = 0; i < parts; i++) {
        chunks.push(this.form.file.slice(
          i * this.chunkSize, Math.min(i * this.chunkSize + this.chunkSize, this.form.file.size), this.form.file.type
        ));
      }
      this.totalChunks = chunks.length
      this.chunks = chunks
    }, 
    uploadChunk(chunk) {
      const chunkIndex = this.totalChunks - this.chunks.length + 1

      const formData = new FormData();
      formData.append('chunk', chunk)
      formData.append('chunkIndex', chunkIndex)
      formData.append('totalChunks', this.totalChunks)
      formData.append('title', this.form.title)
      formData.append('type', this.form.type)
      formData.append('players', JSON.stringify(this.form.players))
      formData.append('description', this.form.description)
      formData.append('timestamp', this.form.date.startDate.getTime())      

      // Upload chunk to server
      http.uploadVideoChunk(formData)
        .then(
          (response) => {
            this.progress = {
              percent: Math.floor(chunkIndex / this.totalChunks * 100),
              size: (chunkIndex * this.chunkSize / 1000 / 1000)
            }

            this.chunks.shift();

            if (this.chunks.length === 0) {
              setTimeout(() => {
                this.isLoading = false
                
                this.$store.dispatch('global/addToast',
                  {
                    title: 'Video uploaded',
                    message: `Successfully uploaded`,
                    type: 'success'
                  }
                )

                this.$store.dispatch('global/addVideo', response.data)                
              }, 1000)
            }            
          }
        ).catch(
          () => {
            this.gaming = false
            this.isLoading = false
            this.$store.dispatch('global/addToast',
              {
                title: 'Oops, une erreur est survenue',
                message: `Failed to be uploaded`,
                type: 'danger'
              }
            )
          }
        )
    },
    submit(e) {
      this.hasFormError = false

      if (this.validateForm() === false) {
        return false
      }

      this.isLoading = true
      this.gaming = true

      this.progress = {
        percent: 0,
        size: 0
      }      

      // Create the chunks
      this.createChunks()

      e.preventDefault()
      e.stopPropagation()

      return false
    }     
  }  
}
</script>
