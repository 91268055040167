<template>
  <section>
    <div class="squad-id align-items-center">      
      <b-form
        ref="squad-id-form"
        class="flex-grow-1"
      >
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <b-form-group
              id="input-group-player"
              class="floating-labels mb-0"
            >
              <multiselect
                :id="`input-squad-id`"
                v-model="selectedSquadId"
                :placeholder="'Squad'"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"              
                :options="squadIds"
                :searchable="searchable === true"
                :allow-empty="false"
                :taggable="true"
                @tag="addsquadId"
                @select="submit"        
              >
                <template
                  slot="singleLabel"
                  slot-scope="{ option }"
                >
                  {{ option }}
                </template>             
              </multiselect> 
              <label
                v-if="selectedSquadId"
                class="is-floating"
                :for="`input-squad-id`"
              >
                Squad
              </label>                        
            </b-form-group>

            <b-button
              v-if="goButton === true"
              size="sm"
              class="mt-2"
              @click="go"
            >
              Go
            </b-button>
          </div>                 
        </div>          
      </b-form>
      <div class="d-flex pl-3 pr-3 pt-1 align-items-center">
        <div
          v-if="selectedSquadId !== null && allowFav === true"
          class="squad-fav mr-auto pr-2 clickable"
          :class="isFavoris ? 'is-favoris' : ''"
          @click="toggleFav"
        >
          <b-icon icon="star-fill" /> <small class="text-gray">Favoris</small>
        </div>      
        <div
          v-if="selectedSquadId !== null && allowShare === true"
          class="squad-link ml-auto pl-2 clickable"
          :class="isFavoris ? 'is-favoris' : ''"
          @click="copySquadLink"
        >
          <small class="text-gray">Share</small> <b-icon icon="share-fill" />
        </div>               

        <input
          id="squad-link"
          type="text"
          readonly="readonly"
          :value="squadLink"
        >
      </div>      
    </div>                
  </section>
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

  input#squad-link {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -9999;
  } 

  .squad-id {
    max-width: 500px;
    margin: 0 auto;
  }

  .squad-fav {
    &:hover {
      & > svg {
        color: $color-manatee;
      }
    }    
    & > svg {
      transition: all 0.3s;
      color: $color-manatee-opacity;      
    }
    &.is-favoris {
      & > svg {
        color: $color-blue;
      }      
    }
  }

  .squad-link {
    & > svg {
      transition: all 0.3s;
      color: $color-manatee-opacity;
      font-size: 14px;
    }
    &:hover {
      & > svg {
        color: $color-manatee;
      }
    }
  }  
</style>

<script>

import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'

export default {
  name: 'SquadSelect',
  components: {
    
  },
  mixins: [validationMixin],
  props: {
    errors: {
      type: Object,
      default: null
    },
    allowShare: {
      type: Boolean,
      default: true
    },
    allowFav: {
      type: Boolean,
      default: true
    },
    searchable: {
      type: Boolean,
      default: false
    },
    goButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectedSquadId: null,
      squadIds: []
    }
  },
  computed: mapState({
    isFavoris: function() {
      return this.squadIds.indexOf(this.selectedSquadId) >= 0
    },
    squadLink: function() {
      return window.location.href
    }    
  }),
  watch: {

  },
  created() {
    try {
      this.squadIds = JSON.parse(window.localStorage.getItem('favoriteSquadIds'))
      if (this.squadIds === null) {
        this.squadIds = []
      }
    } catch (e) {
      this.squadIds = []
    }

    this.selectedSquadId = this.squadId
  },
  methods: {
    submit(e) {
      if (e === null) {
        return false
      }
      this.$store.commit('global/SET_SQUAD_ID', e)

      if (this.allowFav
        && this.isFavoris) {
        window.localStorage.setItem('selectedSquadId', e)        
      }
    },
    addsquadId(id) {
      this.$store.commit('global/SET_SQUAD_ID', id)
      this.squadIds.push(id)
      this.selectedSquadId = id
    },
    toggleFav() {
      if (this.isFavoris === true) {
        this.remFav()
      } else {
        this.addFav()
      }

      window.localStorage.setItem('favoriteSquadIds', JSON.stringify(this.squadIds))
    },
    addFav() {
      this.squadIds.push(this.selectedSquadId)
    },
    remFav() {
      const index = this.squadIds.indexOf(this.selectedSquadId)
      if (index >= 0) {
        this.squadIds.splice(index, 1)
      }
    },
    copySquadLink() {
      /* Get the text field */
      const input = document.getElementById('squad-link');

      /* Select the text field */
      input.select();
      input.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");      
    },   
    go() {
      if (this.selectedSquadId !== null) {
        this.$store.commit('global/SET_SQUAD_ID', this.selectedSquadId)
        this.$router.push(
          {
            name: 'Sessions',
            query: {
              id: this.selectedSquadId
            }
          }
        )
      }
    } 
  }
}
</script>
