<template>
  <section class="p-2">
    <div
      ref="chart"
      class="chart br-16"
    />
  </section>
</template>

<style lang="scss" scoped>
  
  @import '@/assets/style/colors.scss';

  section {
    border-radius: 16px;
    background-color: $color-charade;
  }
  .chart {
    width: 100%;
    height: 500px;
  }

  /deep/ .plot-label {
    position: relative;
    top: -8px;
    left: -12px;
    display: inline-block;
    padding: 4px;
    background-color: $color-blue;
    color: $color-white;
  }
</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'PlayerEvolutionChart',
  components: {

  },
  props: {
    splineKeys: {
      type: Array,
      required: true
    },    
    statsKeys: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    plotLine: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: mapState({
    
  }),  
  watch: {

  },  
  created: function() {

  },
  mounted: function() {
    this.initChart()
  },  
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },  
  methods: {
    initChart() {
      // Create chart instance
      const data = this.getChartData()

      let chart = this.Highcharts.stockChart(this.$refs['chart'], {
          chart: {
            paddingLeft: 40,
            paddingRight: 40,
            marginTop: 16,  
            marginRight: 32,               
            marginLeft: 16,
            marginBottom: 40,
          },

          legend: {
            enabled: true,
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal'
          },
          rangeSelector: {
            allButtonsEnabled: true,
            buttons: [{
                type: 'day',
                count: 1,
                text: '1d',
                title: 'View 1 week'
            },{
                type: 'week',
                count: 1,
                text: '1w',
                title: 'View 1 week'
            },              
            {
                type: 'month',
                count: 1,
                text: '1m',
                title: 'View 1 month'
            }, {
                type: 'month',
                count: 3,
                text: '3m',
                title: 'View 3 months'
            }],
            selected: 0
          },          

          xAxis: {
            lineColor: 'rgba(143, 153, 172, 0.25)',
            gridLineColor: 'rgba(143, 153, 172, 0.25)',
            paddingRight: 40,
            marginRight: 40,            
            labels: {
              rotation: 0,
              y: 24
            }                
          }, 
          yAxis: [
            { // Main line
              visible: true,
              lineColor: 'rgba(143, 153, 172, 0.25)',
              gridLineColor: 'rgba(143, 153, 172, 0.25)',
              title: {
                text: null,
              }                                     
            },
            { // Progression
              visible: true,
              lineColor: 'rgba(143, 153, 172, 0.25)',
              gridLineColor: 'rgba(143, 153, 172, 0.25)',
              title: {
                text: null,
              },
              plotLines: this.plotLine ? 
                [{
                  color: 'rgba(0, 178, 221, 1)',
                  width: 1,
                  value: this.plotLine,
                  label: {
                    rotation: 0,
                    useHTML: true,              
                    formatter: function () {
                      return `<span class="plot-label">${this.options.value.toFixed(2)}</span>`
                    }
                  }
                }] : null                
            }
          ],          

          tooltip: {
            split: true
          },        

          navigator: {
            margin: 48,
            adaptToUpdatedData: true,
            outlineWidth: 0,
            height: 48,
            xAxis: {
              gridLineWidth: 0,
              labels: {
                enabled: false
              }
            },
            yAxis: {
              gridLineWidth: 0,
            }
          },          

          series: data
      });
      
      this.chart = chart      
    },
    getChartData() {
      let series = []

      // Spline keys
      this.splineKeys.forEach(
        (key) => {
          const category = this.mapping.statsMapping[key].label ? this.mapping.statsMapping[key].label : key
          const color = this.mapping.statsMapping[key].chartColor ? this.mapping.statsMapping[key].chartColor : null

          // Diff
          series.push(
            {
              name: category,
              yAxis: 1,
              data: this.data[key],
              type: 'spline',
              color: color
            }
          )
        }
      )

      // Stats columns
      this.statsKeys.forEach(
        (key) => {
          const category = this.mapping.statsMapping[key].label ? this.mapping.statsMapping[key].label : key
          const color = this.mapping.statsMapping[key].chartColor ? this.mapping.statsMapping[key].chartColor : null

          // Diff
          series.push(
            {
              name: category,
              yAxis: 0,
              data: this.data[key],
              type: 'column',
              color: color
            }
          )
        }
      )

      return series
    }
  }  
}
</script>
