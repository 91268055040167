<template>
  <section :key="squadId">
    <template v-if="isLoading === true">
      <loader />
    </template>

    <template v-else>
      <!-- SELECT SQUAD -->
      <div class="row justify-content-md-center">
        <div class="col col-12 mb-4">
          <squad-select />
        </div>         
      </div>       

      <template v-if="hasError === true">
        <b-alert
          class="info round"
          show
        >
          <div class="alert-box-content">
            <b-icon
              icon="exclamation-circle"
              variant="danger"
            />              
            <span class="alert-box-message">
              Oops, the squad does not exist
            </span>
            <span class="alert-box-details">
              The squad {{ squadId }} does not exist...
            </span>
          </div>
        </b-alert>
      </template>

      <template v-else>
        <!-- PLAYERS -->
        <div class="row justify-content-md-center">
          <div class="col col-12">
            <squad-players />
          </div>         
        </div>
      </template>
    </template>
  </section>
</template>

<style lang="scss" scoped>

  @import '~@/assets/style/colors.scss';

</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex'

import Loader from '@/components/layout/Loader.vue'
import SquadSelect from '@/components/squads/SquadSelect.vue'
import SquadPlayers from '@/components/squads/SquadPlayers.vue'

export default {
  name: 'Squad',
  components: {
    Loader,
    SquadSelect,
    SquadPlayers
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      error: null
    }
  },
  computed: mapState({
    
  }),
  watch: {

  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.isLoading = true
      this.hasError = false
      this.error = null

      http.getSquadExists({
        id: this.squadId
      }).then(
        () => {
          this.isLoading = false
        }
      ).catch(
        (error) => {
          this.hasError = true
          this.error = error.response.data
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    }
  }  
}
</script>
