<template>
  <section>
    <div class="row justify-content-center">
      <template v-if="isLoading === true">
        <loader />
      </template>

      <template v-else>
        <template v-if="hasError === true">
          <b-alert
            class="info round"
            show
          >
            <div class="alert-box-content">
              <b-icon
                icon="exclamation-circle"
                variant="danger"
              />              
              <span class="alert-box-message">
                Oops, something went wrong
              </span>
              <span class="alert-box-details">
                Unable to load the data
              </span>
            </div>
          </b-alert>
        </template>

        <template v-else>
          <div class="col col-12">
            <swiper
              v-if="showSlider === true"
              ref="slider"
              class="swiper"
              :options="swiperOption"
            >
              <div
                v-if="isSmallDevice === true"
                slot="pagination"
                class="swiper-pagination"
              /> 

              <swiper-slide
                v-for="(card, index) in cards"
                :key="index"
              >       
                <admin-stat-card
                  v-if="data[card.id]"
                  :id="card.id"
                  :title="card.title"
                  :value="data[card.id].total"
                  :diff="data[card.id].diff"
                  :color="card.color"
                  :repartition-map="card.repartitionMap"
                  :repartition-data="data[card.id].repartition"             
                />
              </swiper-slide>
            </swiper>
          </div>            
          <div class="col col-12 mt-2 charts">
            <admin-overview-chart :data="data" />
          </div>
        </template>
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import Loader from '@/components/layout/Loader.vue'

import AdminStatCard from '@/components/admin/cards/AdminStatCard.vue'
import AdminOverviewChart from '@/components/admin/charts/AdminOverviewChart.vue'

export default {
  name: 'AdminOverview',
  components: {
    Loader,
    AdminStatCard,
    AdminOverviewChart
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      showSlider: false,
      cards: [
        {
          id: 'visits',
          title: 'Visits',
          color: '#6794dc',
          repartitionMap: {
            'loggedPercent': {
              label: 'Logged',
            },
            'unloggedPercent': {
              label: 'Unlogged'
            }
          } 
        },        
        {
          id: 'sessions',
          title: 'Sessions created',
          color: '#6771dc',
          repartitionMap: {
            'loggedPercent': {
              label: 'Logged',
            },
            'unloggedPercent': {
              label: 'Unlogged'
            }
          }          
        },
        {
          id: 'games',
          title: 'Games pulled',
          color: '#8067dc',
          repartitionMap: {
            'loggedPercent': {
              label: 'Logged',
            },
            'unloggedPercent': {
              label: 'Unlogged'
            }
          }          
        },
        {
          id: 'players',
          title: 'Players pulled',
          color: '#a367dc',
          repartitionMap: {
            'addedPercent': {
              label: 'Added',
            },
            'updatedPercent': {
              label: 'Updated'
            }
          }           
        }                                
      ],
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        freeMode: false,
        grabCursor: true,
        loop: false,
        loopFillGroupWithBlank: false,
        mousewheel: {
          forceToAxis: true,
          invert: true
        },
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }        
      }       
    }
  },
  computed: mapState({

  }),  
  created: function() {
    this.$root.$on('refresh-admin', () => {
      this.load()
    })

    this.load()
  },
  mounted: function() {
    this.swiperOption.slidesPerView = this.isSmallDevice === true ? 1 : this.cards.length
    this.showSlider = true
  },  
  methods: {
    load() {
      this.isLoading = true
      this.hasError = false
      http.getAdminOverview().then(
        (response) => {
          this.data = response.data
        }
      ).catch(
        () => {
          this.hasError = true
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    },    
  }  
}
</script>
