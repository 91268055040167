<template>
  <section>
    <template v-if="isLoading === true">
      <loader />
    </template>

    <template v-else>
      <template v-if="hasError === true">
        <b-alert
          class="info round"
          show
        >
          <div class="alert-box-content">
            <b-icon
              icon="exclamation-circle"
              variant="danger"
            />              
            <span class="alert-box-message">
              Oops, something went wrong
            </span>
            <span class="alert-box-details">
              Unable to load the data
            </span>
          </div>
        </b-alert>
      </template>

      <template v-else>
        <div class="row justify-content-md-center">    
          <div class="col col-12 games-list">
            <player-results-stats
              :stats="results"
              :player="player"
            />
          </div>
        </div> 
      </template>
    </template>    
  </section> 
</template>

<style lang="scss" scoped>


</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import loader from '@/components/layout/Loader.vue'
import playerResultsStats from '@/components/players/PlayerResultsStats.vue'

export default {
  name: 'PlayerResults',
  components: {
    loader,
    playerResultsStats
  },
  props: {
    player: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      results: null,
      selectedMode: null
    }
  },
  computed: mapState({
    modes: function() {
      const modes = []
      const keys = this.results.modes
      keys.forEach((key) => {
        if (key === 'all') { return }
        modes.push({
          id: key,
          label: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].label : key,
          order: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].order : 9999,
        })
      })

      modes.sort((a, b) => {
        if(a.order < b.order) { return -1; }
        if(a.order > b.order) { return 1; }
        return 0;
      })

      return modes
    }   
  }),  
  created: function() {
    this.load()
  },
  mounted: function() {

  },  
  methods: {
    load() {
      this.isLoading = true
      http.getPlayerResults({
        player: this.player.username
      }).then(
        (response) => {
          this.results = response.data
          this.selectedMode = this.modes[1]
        }
      ).catch(
        () => {
          this.hasError = true
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    }
  }  
}
</script>
