
import getEnv from '@/utils/env.js'

import store from '@/store/index'

const VUE_APP_API_URL = getEnv('VUE_APP_API_URL')

const axios = require('axios').default;

const http = axios.create({
  withCredentials: true
})

// Globally catch 401 and ask user for credentials
function errorResponseHandler(error) {
  if (error.response.status === 401) {
    store.dispatch('global/setRequestAuth', true)

    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
}

// apply interceptor on response
http.interceptors.response.use(
 response => response,
 errorResponseHandler
);

// Get the auth barrer
function getAuth() {

  try {

    if (store.state.global.authCredentials !== null
      && store.state.global.authCredentials.username
      && store.state.global.authCredentials.password
    ) {
      return { 
        username: atob(unescape(decodeURIComponent(store.state.global.authCredentials.username))),
        password: atob(unescape(decodeURIComponent(store.state.global.authCredentials.password)))
      }    
    } else {
      return {
        username: null,
        password: null
      }
    }  

  } catch (e) {
    return {
      username: null,
      password: null
    }
  }

}

// Auth
function auth() {
  return http.request(
    {
      method: 'POST',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/authenticate`,
      headers: {
        'content-type': 'application/json'
      }
    }  
  )  
}

// Mapping
function getMapping() {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/mapping`,
      headers: {
        'content-type': 'application/json'
      }
    }  
  )
}

// Admin
function getAdminOverview() {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/admin/overview`,
      headers: {
        'content-type': 'application/json'
      }
    }  
  )   
}

function getAdminPull() {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/admin/pull`,
      headers: {
        'content-type': 'application/json'
      }
    }  
  )   
}

function getAdminPullRequests(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/admin/pull/requests`,
      headers: {
        'content-type': 'application/json'
      },
      params: data
    }  
  )   
}

function getAdminPlayers() {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/admin/players`,
      headers: {
        'content-type': 'application/json'
      }
    }  
  )   
}

function getAdminPlayersList(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/admin/players/list`,
      headers: {
        'content-type': 'application/json'
      },
      params: data
    }  
  )   
}

function getAdminSquads() {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/admin/squads`,
      headers: {
        'content-type': 'application/json'
      }
    }  
  )   
}

function getAdminSquadsList(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/admin/squads/list`,
      headers: {
        'content-type': 'application/json'
      },
      params: data
    }  
  )   
}

function getAdminSessions() {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/admin/sessions`,
      headers: {
        'content-type': 'application/json'
      }
    }  
  )   
}

function getAdminSessionsList(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/admin/sessions/list`,
      headers: {
        'content-type': 'application/json'
      },
      params: data
    }  
  )   
}

// Videos
function getVideos() {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/videos`,
      headers: {
        'content-type': 'application/json'
      }      
    }  
  )
}

function uploadVideoChunk(data) {
  return http.request(
    {
      method: 'POST',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/video-chunk`,
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      data: data
    }  
  )
}

// Sessions
function getSession(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/sessions`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )  
}

function getSessionGames(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/sessions/games`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )  
}

// Squad
function getSquadPlayers(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/squads/players`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )
}

function createSquad(data) {
  return http.request(
    {
      method: 'POST',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/squads/create`,
      headers: {
        'Content-type': 'application/json'
      },
      data: data
    }  
  )   
}

function updateSquad(data) {
  return http.request(
    {
      method: 'POST',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/squads/update`,
      headers: {
        'Content-type': 'application/json'
      },
      data: data
    }  
  )   
}

function getSquadSessions(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/squads/sessions`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )
}

function getSquadSummary(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/squads/summary`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )
}

function getSquadExists(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/squads/exists`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )  
}

// Players
function getPlayer(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/players`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )   
}

function createPlayer(data) {
  return http.request(
    {
      method: 'POST',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/players/create`,
      headers: {
        'Content-type': 'application/json'
      },
      data: data
    }  
  )  
}

function updatePlayer(data) {
  return http.request(
    {
      method: 'POST',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/players/update`,
      headers: {
        'Content-type': 'application/json'
      },
      data: data
    }  
  )   
}

function getPlayerSummary(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/players/summary`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )   
}

function getPlayerEvolution(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/players/evolution`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )   
}

function getPlayerResults(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/players/results`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )   
}

function getPlayerSessions(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/players/sessions`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )   
}

function getPlayerSessionsAvailable(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/players/sessions/available`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )  
}

function getPlayerGames(data) {
  return http.request(
    {
      method: 'GET',
      auth: getAuth(),
      url: `${VUE_APP_API_URL}/players/games`,
      headers: {
        'Content-type': 'application/json'
      },
      params: data
    }  
  )   
}

export default {
  // Auth
  auth: auth,

  // Mapping
  getMapping: getMapping,

  // Admin
  getAdminOverview: getAdminOverview,
  getAdminPull: getAdminPull,
  getAdminPullRequests: getAdminPullRequests,
  getAdminPlayers: getAdminPlayers,
  getAdminPlayersList: getAdminPlayersList,
  getAdminSquads: getAdminSquads,
  getAdminSquadsList: getAdminSquadsList,  
  getAdminSessions: getAdminSessions,
  getAdminSessionsList: getAdminSessionsList,  

  // Squad
  getSquadPlayers: getSquadPlayers,
  getSquadSessions: getSquadSessions,
  createSquad: createSquad,
  updateSquad: updateSquad,  
  getSquadSummary: getSquadSummary,
  getSquadExists: getSquadExists,  
  getPlayer: getPlayer,

  // Sessions
  getSessionGames: getSessionGames,
  getSession: getSession,

  // Players
  createPlayer: createPlayer,
  updatePlayer: updatePlayer,
  getPlayerSummary: getPlayerSummary,
  getPlayerEvolution: getPlayerEvolution,
  getPlayerResults: getPlayerResults,
  getPlayerGames: getPlayerGames,
  getPlayerSessions: getPlayerSessions,
  getPlayerSessionsAvailable: getPlayerSessionsAvailable,

  // Videos
  getVideos: getVideos,
  uploadVideoChunk: uploadVideoChunk
}