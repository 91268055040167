<template>
  <section>
    <div class="row justify-content-center p-2">
      <div class="col col-12">
        <h6 class="text-white">
          Welcome
        </h6>
        <h3 class="mb-4">
          Verdanskien!
        </h3>

        <p class="text-gray">
          Get in depth analysis about your last performances
        </p>           

        <p class="text-gray">
          Get funny stats and
          <router-link
            class="text-white"
            to="/badges"
          >
            rewards
          </router-link> about your last sessions with your friends!
        </p>      

        <div
          class="start p-2"
        >
          <router-link to="/squads/new">
            <a class="shiny text-gray">
              <small>I am a </small><br><b class="text-white">Squad</b><br> <small>player!</small>
              <i />
            </a>
          </router-link>                
        </div>  

        <div
          class="start p-2"
        >
          <router-link to="/players/new">
            <a class="shiny text-gray">
              <small>I am a </small><br><b class="text-white">Solo</b><br> <small>player!</small>
              <i />
            </a>
          </router-link>               
        </div>  

        <p class="mt-2">
          <span
            class="link text-white clickable"
            @click="goToSampleSquad()"
          >
            Check out what it looks like!
          </span>
        </p>                  

        <div class="guest-session-id">
          <small
            class="text-gray"
            v-b-tooltip.hover.top="'Once you have created your Squad, an ID will be given to you. You can use this ID to access easily your Squad'"
          >
            <b-icon icon="question-circle" />
            I already have a Squad ID 
          </small>            
          <squad-select
            :redirect-to="true"
            :allow-share="false"
            :allow-fav="false"
            :go-button="true"
            :searchable="true"
            class="mt-4"
          />
        </div>               

        <hr>

        <small class="text-gray">
          We, as Rotaldoz are doing that with no attempt to bring anything else than a bit of<br>
          <span class="text-white">FUN!</span>
          <br>
          Bugs may be found, problems may happen but please, it's a free service, done with pleasure!<br>
          <span class="text-white">
            ENJOY!
          </span>
        </small>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  section {
    max-width: 860px;
    margin: 0 auto;
  }
  .start {
    position: relative;

    & a.shiny {
      height: 100px;
      width: 100%;
      max-width: 420px;
      padding: 8px;
    }
  }
</style>

<script>

import { mapState } from 'vuex'

import SquadSelect from '@/components/squads/SquadSelect.vue'

export default {
  name: 'Home',
  components: {
    SquadSelect
  },
  props: {

  },
  data() {
    return {
      currentStep: 1,
      players: null,
      playersErrors: null
    }
  },
  computed: mapState({
    isAuth: state => state.global.isAuth,
  }),
  watch: {
    isAuth: function(newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.$router.push({
          name: 'Sessions',
          query: {
            id: this.squadId
          }
        })
      }
    }
  },
  created() {

  },
  methods: {
    goToSampleSquad() {
      this.$store.commit('global/SET_SQUAD_ID', 'squad-sample')
      this.$router.push(
        {
          name: 'Sessions',
          query: {
            id: 'squad-sample'
          }
        }
      )
    }
  }
}
</script>
