<template>
  <b-modal
    id="modal-squad-pull"
    ref="modal-squad-pull"
    size="lg"
    close-on-backdrop
    hide-footer
  >
    <template v-slot:modal-header="{ close }">
      <h4>Easy soldier!</h4>
      <b-button 
        size="sm"
        class="btn-close"
        @click="close"
      />
    </template>

    <template v-slot:default>
      <b-alert
        class="info round"
        show
      >
        <div class="alert-box-content text-center">
          <b-icon
            icon="exclamation-circle"
            variant="warning"
          />              
          <span class="alert-box-message">
            You just pulled the latest data soldier!
          </span>
          <span class="alert-box-details">
            If we let the players pull the data anytime they want we might be banned from Activision :S 
            <br><br>
            Try around<br>
            <span class="text-white next-try">
              {{ nextTry }}
            </span>
            <br><br>
            I hope you understand...
            <br><br>
            <b-button
              class="mt-3 d-block ml-auto mr-auto mb-3"
              @click="closeModal()"
            >
              Ok, I understand
            </b-button>
          </span>
        </div>
      </b-alert>      
    </template>   
  </b-modal>
</template>

<style lang="scss" scoped>

  @import '@/assets/style/colors.scss';

</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'SquadPullModal',
  components: {

  },
  props: {
    errors: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      
    }
  },
  computed: mapState({
    nextTry() {
      return this.formatDate(new Date(this.errors.nextTry), 'hh:mm')
    }
  }),
  created: function() {

  },
  mounted: function() {

  },
  methods: {
    closeModal() {
      this.$refs['modal-squad-pull'].hide()
    }
  }  
}
</script>
