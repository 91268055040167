<template>
  <section class="bg-charade br-16">
    <div
      class="d-flex p-3 game-card"
      @click="onClick"
    >
      <div class="game-graphic mr-3">
        <div class="graphic-overlay" />
        <div class="game-result d-flex p-1">   
          <div class="d-flex flex-column flex-column text-center justify-content-center">     
            <div class="mb-auto">
              <small class="text-white">
                {{ mapping.modesMapping[game.mode] ? mapping.modesMapping[game.mode].label : '-' }}
              </small> 
            </div>
            <div
              class="box d-flex flex-column align-items-center p-3 bg-woodsmoke ml-auto mr-auto"
              :class="isSmallDevice ? 'box-md' : ''"
            >
              <h6 class="text-white">
                Result
              </h6>              
              <img 
                v-if="game.playerStats.teamPlacement === 1"
                class="mt-1"
                src="~@/assets/images/crown.png"
              >
              <h2
                v-else
                :class="resultClass"
              >
                <small>#</small>{{ game.playerStats.teamPlacement }}
              </h2>
            </div>
            <div
              class="mt-auto"
              style="line-height: 10px;"
            >
              <small class="d-none d-lg-inline text-white">
                {{ formatDate(new Date(game.utcStartSeconds * 1000), 'dd/MM/yyyy hh:mm') }}
              </small>
              <small
                class="d-inline d-lg-none text-white"
                style="line-height: 10px;"
              >
                <small>{{ formatDate(new Date(game.utcStartSeconds * 1000), 'dd/MM/yy hh:mm') }}</small>
              </small>       
            </div>
          </div>

          <!-- HIGLIGHTS MOBILE -->
          <div class="d-flex d-lg-none flex-column">
            <div class="d-flex flex-wrap justify-content-center">
              <div
                v-for="statsKey in ['kdRatio', 'kills', 'deaths', 'damageDone', 'damageTaken', 'scorePerMinute', 'gulagResult']"
                :key="statsKey"            
                class="box box-sm d-flex flex-column align-items-center p-3 bg-woodsmoke"
              >
                <template v-if="statsKey !== 'gulagResult'">
                  <h6>
                    {{ mapping.statsMapping[statsKey].label }}
                  </h6>
                  <h2
                    class="text-white"
                    :class="['kdRatio', 'kills', 'deaths'].indexOf(statsKey) >= 0 ? ratioClass : ''"
                  >
                    {{ getStats(statsKey) }}
                  </h2>
                </template>
                <template v-else>
                  <template v-if="game.playerStats.gulagResult !== null">
                    <img
                      src="~@/assets/images/gulag.png"
                      class="gulag"
                      :class="game.playerStats.gulagResult === true ? 'win' : 'lost'"
                    >            
                    <small class="text-gray gulag-circle">
                      Circle {{ game.playerStats.gulagCircle }}
                    </small>                         
                  </template>
                  <template v-else>
                    <h2 class="text-white">
                      -
                    </h2>
                  </template>            
                </template>
              </div>             
            </div>   
            <div class="d-flex players-count pt-1">
              <div class="mr-auto">
                <small>{{ game.playerCount }} Players / {{ game.teamCount }} Teams</small>
              </div>
              <div class="ml-auto">
                <small>Duration : ~{{ Math.round(((game.utcEndSeconds * 1000 - game.utcStartSeconds * 1000) / (1000 * 60))).toFixed(0) }} min</small>
              </div>          
            </div>
          </div>                              
        </div>
        <img
          v-if="isBR === true"
          src="~@/assets/images/battleroyale.jpg"
        >
        <img
          v-else-if="isPlunder === true"
          src="~@/assets/images/plunder.jpg"
        >
        <img
          v-else
          src="~@/assets/images/other.jpg"
        >
      </div>

      <!-- HIGLIGHTS -->
      <div class="d-none d-lg-flex flex-column">
        <div class="d-flex flex-wrap justify-content-center">
          <div
            v-for="statsKey in ['kdRatio', 'kills', 'deaths', 'damageDone', 'damageTaken', 'scorePerMinute', 'score', 'gulagResult']"
            :key="statsKey"            
            class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
          >
            <template v-if="statsKey !== 'gulagResult'">
              <h6>
                {{ mapping.statsMapping[statsKey].label }}
              </h6>
              <h2
                class="text-white"
                :class="['kdRatio', 'kills', 'deaths'].indexOf(statsKey) >= 0 ? ratioClass : ''"
              >
                {{ getStats(statsKey) }}
              </h2>
            </template>
            <template v-else>
              <h6>
                Gulag
              </h6>
              <template v-if="game.playerStats.gulagResult !== null">
                <img
                  src="~@/assets/images/gulag.png"
                  class="gulag"
                  :class="game.playerStats.gulagResult === true ? 'win' : 'lost'"
                >           
                <small class="text-gray">
                  Circle {{ game.playerStats.gulagCircle }}
                </small>                       
              </template>
              <template v-else>
                <h2 class="text-white">
                  -
                </h2>
              </template>            
            </template>
          </div>
        </div>   
        <div class="d-flex players-count pt-1">
          <div class="mr-auto">
            <small>{{ game.playerCount }} Players / {{ game.teamCount }} Teams</small>
          </div>
          <div class="ml-auto">            
            <small>Duration : ~{{ Math.round(((game.utcEndSeconds * 1000 - game.utcStartSeconds * 1000) / (1000 * 60))).toFixed(0) }} min</small>            
          </div>          
        </div>
      </div>       
    </div>

    <div
      v-if="visible === true"
      class="row pt-3 m-0 game-stats"
    >
      <game-charts :game="game" />

      <div class="col-12 text-right p-3 game-id">
        <small>Game ID : {{ game.matchID }}</small>
      </div>      
    </div>
  </section>
</template>

<style lang="scss" scoped>

  @import '~@/assets/style/colors.scss';

  .game-card {
    border-radius: 16px;
    @media(max-width: 420.98px) {
      flex-direction: column
    }
  }

  img.gulag {
    margin-top: 6px;
    width: 32px;

    &.lost {
      filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg);      
    }

    &.win {
      filter: brightness(50%) sepia(100) saturate(100) hue-rotate(90deg);      
    }    
  }

  @media (max-width: 420px) {
    img.gulag {
      margin-top: 4px;
    }

    div.box {
      & small.gulag-circle {
        font-size: 8px;
      }
    }    
  }

  div.game-graphic {
    position: relative;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    height: auto;
    width: 100%;
    max-width: 180px;
    margin-left: -16px;
    margin-top: -16px;
    margin-bottom: -16px;
    object-fit: cover;

    @media(max-width: 420.98px) {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;      
      max-width: calc(100% + 32px);
      width: calc(100% + 32px);
      border-right: 0px;
      height: 140px;
    }    

    & .graphic-overlay {
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.68);
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;      

      @media(max-width: 420.98px) {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;      
      }       
    }

    & .game-result {
      position: absolute;
      z-index: 2;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      
      & > .d-flex {
        width: 100%;
      }

      @media(max-width: 420.98px) {
        padding-left: 8px !important;
        padding-right: 8px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;      
        
        & > .d-flex {
          width: auto;
        }

        & h1 {
          font-size: 32px;
          line-height: 1px;
          margin: 0;
        }

        & > small {
          font-size: 8px;

          &:last-child {
            font-size: 4px;
          }
        }
      }         

      & img:not(.sbmm-logo):not(.gulag) {
        width: 72px;

        @media(max-width: 421px) {
          width: 40px;          
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }

    & > img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px; 

      @media(max-width: 420.98px) {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;      
      }       
    }
  }  

  div.game-stats {
    border-top: 1px solid $color-woodsmoke;

    & ul {
      display: block;
      height: calc(100% - 40px);
      padding-top: 16px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 16px;
      background-color: $color-woodsmoke;
      border-radius: 16px;

      & > li {
        padding: 8px;
        padding-left: 24px;
        padding-right: 24px;        

        &:hover {
          background-color: rgba(255, 255, 255, 0.2)
        }
      }
    }
  }

  div.players-count {
    @media(max-width: 420.98px) {
      padding: 0px !important;
      margin-top: -4px;

      & small {
        font-size: 8px;
        line-height: 8px;
      }
    }
  }

  div.game-id {
    border-top: 1px solid $color-woodsmoke;
  }

  img.sbmm-logo {
    filter: grayscale(100%);
    transition: all 0.2s;

    &:hover {
      filter: none;
    }
  }

  /deep/ .swiper-container {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;    
  }

</style>

<script>

import { mapState } from 'vuex' 

import GameCharts from '@/components/games/GameCharts.vue'

export default {
  name: 'GameCard',
  components: {
    GameCharts
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    showStats: {
      type: Boolean,
      default: false
    },
    kdRatio: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      visible: false,
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        freeMode: false,
        grabCursor: true,
        loop: false,
        loopFillGroupWithBlank: false,
        mousewheel: {
          forceToAxis: true,
          invert: true
        },
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }        
      }      
    }
  },
  computed: mapState({
    isBR: function() {
      return this.mapping.gameTypePerMode[this.game.mode] === 'br'
    },
    isPlunder: function() {
      return this.mapping.gameTypePerMode[this.game.mode] === 'plunder'
    },
    ratioClass: function() {
      const kdRatio = this.game.playerStats.kdRatio
      if (kdRatio === null) {
        return 'text-white'
      }
      const diff = kdRatio - this.kdRatio
      if (diff >= 0.2) {
        return 'text-success'
      } else if (diff <= -0.2) {
        return 'text-danger'
      } else {
        return 'text-white'
      }
    },    
    resultClass: function() {
      const place = this.game.playerStats.teamPlacement
      if (place <= 5) {
        return 'text-blue'
      } else if (place <= 10) {
        return 'text-yellow'
      } else if (place <= 25) {
        return 'text-white'
      } else {
        return 'text-gray'
      }
    }
  }),  
  watch: {

  },
  created: function() {

  },
  mounted: function() {
    setTimeout(() => {
      this.visible = this.showStats
    }, 100)
  },  
  methods: {
    getStats(key) {
      if (this.mapping.statsMapping[key] === undefined) {
        return '<No mapping>'
      }

      const toFixed = this.mapping.statsMapping[key].fixed ? this.mapping.statsMapping[key].fixed : 0
      const divider = this.mapping.statsMapping[key].divider ? this.mapping.statsMapping[key].divider : 1
      const stats = this.game.playerStats[key]

      return stats ? (stats / divider).toFixed(toFixed) : '0'
    },
    onClick() {
      this.visible = !this.visible
    }    
  }
}
</script>
