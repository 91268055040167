<template>
  <section>
    <template v-if="isLoading === true">
      <loader />
    </template>

    <template v-else>
      <template v-if="hasError === true">
        <b-alert
          class="info round"
          show
        >
          <div class="alert-box-content">
            <b-icon
              icon="exclamation-circle"
              variant="danger"
            />              
            <span class="alert-box-message">
              Oops, something went wrong
            </span>
            <span class="alert-box-details">
              Unable to load the data
            </span>
          </div>
        </b-alert>
      </template>

      <template v-else>
        <div class="row justify-content-md-center">    
          <div class="col col-12 games-list">
            <player-evolution-stats
              :stats="evolution"
              :player="player"
            />
          </div>
        </div> 
      </template>
    </template>    
  </section> 
</template>

<style lang="scss" scoped>


</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import loader from '@/components/layout/Loader.vue'
import playerEvolutionStats from '@/components/players/PlayerEvolutionStats.vue'

export default {
  name: 'PlayerEvolution',
  components: {
    loader,
    playerEvolutionStats
  },
  props: {
    player: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      evolution: null,
    }
  },
  computed: mapState({

  }),  
  created: function() {
    this.load()
  },
  mounted: function() {

  },  
  methods: {
    load() {
      this.isLoading = true
      http.getPlayerEvolution({
        player: this.player.username
      }).then(
        (response) => {
          this.evolution = response.data
        }
      ).catch(
        () => {
          this.hasError = true
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    }
  }  
}
</script>
