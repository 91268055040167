<template>
  <section>
    <div
      class="d-flex flex-column box text-left p-2"
      :class="{'selectable': selectable === true, 'selected': selected === true}"
      :style="borderStyle"
      @click="onClick"
    >
      <div class="d-flex flex-wrap box-title p-2">
        <small class="text-yellow">{{ title }}</small>
        <small class="text-gray ml-auto">
          {{ formatDate(new Date(), 'dd/MM hh:mm:ss') }}
        </small>
      </div>
      <div class="p-2">
        <h2 class="text-white mb-0">
          {{ value }}
          <!-- <small :class="diffClass">
            {{ `${diffSign}${diff}` }}%
          </small>           -->
        </h2>
      </div>
      <div
        v-if="repartitionData && repartitionMap"
        class="p-2 box-repartition"
      >
        <div
          v-for="(key, index) in Object.keys(repartitionMap)"
          :key="index"        
          class="d-flex align-items-center"          
        >
          <div class="progress-label">
            <small class="text-uppercase text-gray">{{ repartitionMap[key].label }}</small>
          </div>
          <div class="flex-grow-1">
            <b-progress
              :max="100"
              show-value
            >
              <b-progress-bar
                variant="success"
                :value="repartitionData[key]"
              />
            </b-progress> 
          </div>           
        </div>    
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>

  @import '@/assets/style/colors.scss';

  section {
    max-width: 343px;
  }

  .box {
    border-radius: 16px;
    background-color: $color-charade;
    width: auto;
    height: auto;

    &.selectable:hover {
      background-color: $color-cadet-blue-opacity;
      cursor: pointer;
    }

    &.selected {
      background-color: $color-manatee-opacity;
    }

    & > .box-title {
      text-align: left;
      text-transform: uppercase;
      border-bottom: 1px solid $color-woodsmoke;
    }

    & > .box-repartition {
      border-top: 1px solid $color-woodsmoke;
    }

    & .progress-label {
      min-width: 80px;
    }
  }

</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'AdminStatCard',
  components: {
    
  },
  props: {
    id: {
      type: String,
      required: true
    },    
    title: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    diff: {
      type: Number,
      default: 0
    },
    repartitionMap: {
      type: Object,
      default: null
    },    
    repartitionData: {
      type: Object,
      default: null
    },
    selectable: {
      type: Boolean,
      default: false
    },    
    selected: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'false'
    }       
  },
  data() {
    return {

    }
  },
  computed: mapState({
    borderStyle: function() {
      return `border-bottom:4px solid ${this.color};`
    },
    diffSign: function() {
      if (this.diff < 0) {
        return '-'
      } else {
        return '+'
      } 
    },
    diffClass: function() {
      if (this.diff < 0) {
        return 'text-danger'
      } else {
        return 'text-success'
      }
    }
  }),  
  created: function() {

  },
  mounted: function() {

  },  
  methods: {
    onClick() { 
      this.$emit('on-click', this.id)
    }
  }  
}
</script>
