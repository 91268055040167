
/**
 * Global module
 */
const globalModule = {
  namespaced: true,
  state: {
    isInitialized: false,
    isAuth: false,
    requestAuth: false,
    authCredentials: null,

    // Mapping
    mapping: null,

    // Only used for videos
    players: [{
        id: 'Cl2ment',
        label: 'Cl2ment',
        acti: 'Cl2ment#6159588'
      },
      {
        id: 'DonLiloo',
        label: 'DonLiloo',
        acti: 'DonLiloo#5639107'
      },
      {
        id: 'Andre-Lobineau',
        label: 'Andre-Lobineau',
        acti: 'Andre-Lobineau#8766793'
      },
      {
        id: 'RaphaGranados',
        label: 'RaphaGranados',
        acti: 'RaphaGranados#7628617'
      },
      {
        id: 'WillyDonzag',
        label: 'WillyDonzag',
        acti: 'WillyDonzag#9584781'
      },
      {
        id: 'JohnDunBar91',
        label: 'JohnDunBar91',
        acti: 'JohnDunBar91#9739937'
      },
      {
        id: 'MAJOR_KROKPOR',
        label: 'MAJOR_KROKPOR',
        acti: 'MAJOR_KROKPOR#3966822'
      }                 
    ],

    // Videos
    videos: null,
    selectedVideo: null,

    // Player
    selectedPlayer: null,

    // Selected player for individual stats
    playerId: null,

    // Squad
    squadId: null,
    
    // Toast
    toasts: []
  },
  mutations: {
    // INIT
    SET_IS_INITIALIZED: (state, init) => {
      state.isInitialized = init
    },

    // Mapping
    SET_MAPPING: (state, mapping) => {
      state.mapping = mapping
    },

    // AUTH
    SET_AUTH: (state, auth) => {
      state.isAuth = auth
    },    
    SET_REQUEST_AUTH: (state, requestAuth) => {
      state.requestAuth = requestAuth
    },
    SET_AUTH_CREDENTIALS: (state, credentials) => {
      state.authCredentials = credentials
    },

    // PLAYER
    SET_SELECTED_PLAYER: (state, player) => {
      state.selectedPlayer = player
    },
    
    // PLAYER ID
    SET_PLAYER_ID: (state, id) => {
      state.playerId = id
    },
    UNSET_PLAYER_ID: (state) => {
      state.playerId = null
    },    
    
    // SQUAD
    SET_SQUAD_ID: (state, id) => {
      state.squadId = id
    },
    UNSET_SQUAD_ID: (state) => {
      state.squadId = null
    },    

    // VIDEOS
    SET_VIDEOS: (state, videos) => {
      state.videos = videos
    },    
    SET_SELECTED_VIDEO: (state, video) => {
      state.selectedVideo = video
    },   
    ADD_VIDEO: (state, video) => {
      if (state.videos !== null) {
        state.videos.unshift(video)
      }
    },  

    // TOAST
    ADD_TOAST(state, { title: title, message: message, type: type }) {
      state.toasts.push({ title: title, message: message, type: type, displayed: false })
    },
    REM_TOAST(state, index) {
      state.toasts.splice(index, 1)
    },    
  },
  actions: {
    // Init
    setIsInitialized: (context, init) => {
      context.commit('SET_IS_INITIALIZED', init)
    },

    // Auth
    setAuth: (context, auth) => {
      context.commit('SET_AUTH', auth)
    },
    setRequestAuth: (context, auth) => {
      context.commit('SET_REQUEST_AUTH', auth)
    },
    setAuthCredentials: (context, { username, password }) => {
      context.commit('SET_AUTH_CREDENTIALS', {
          username: btoa(unescape(encodeURIComponent(username))),
          password: btoa(unescape(encodeURIComponent(password)))
        }
      )
    },
    unsetAuthCredentials: (context) => {
      context.commit('SET_AUTH_CREDENTIALS', null)
    }, 
    
    // Player
    setSelectedPlayer: (context, player) => {
      context.commit('SET_SELECTED_PLAYER', player)
    },

    // Player id
    setPlayerId: (context, id) => {
      context.commit('SET_PLAYER_ID', id)
    },     
    unsetPlayerId: (context) => {
      context.commit('UNSET_PLAYER_ID')
    },

    // Squads
    setSquadId: (context, id) => {
      context.commit('SET_SQUAD_ID', id)
    },     
    unsetSquadId: (context) => {
      context.commit('UNSET_SQUAD_ID')
    },    

    // Videos
    setVideos: (context, videos) => {
      context.commit('SET_VIDEOS', videos)
    },    
    setSelectedVideo: (context, video) => {
      context.commit('SET_SELECTED_VIDEO', video)
    },
    addVideo: (context, video) => {
      context.commit('ADD_VIDEO', video)
    },   

    // Toast
    addToast(context, { title: title, message: message, type: type}) {
      context.commit('ADD_TOAST', { title: title, message: message, type: type, displayed: false })
    },
    remToast(context, index) {
      context.commit('REM_TOAST', index)
    }    
  }
}

export default globalModule
