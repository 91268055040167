<template>
  <section class="mb-4 bg-charade clickable">
    <div
      class="d-flex flex-wrap p-3 session-card"
      :class="sessionClass"
      @click="visible = !visible"
    >      
      <div class="session-graphic mr-3">
        <div 
          class="graphic-overlay"
          :class="sessionClass"
        />
        <div class="session-result d-flex p-1">   
          <div class="d-flex flex-column flex-column text-center justify-content-center">     
            <div class="mb-auto">
              <small class="text-white">
                {{ mapping.modesMapping[session.mode].label }}
              </small>           
            </div>
            <div
              class="box d-flex flex-column align-items-center p-3 bg-woodsmoke ml-auto mr-auto"
              :class="isSmallDevice ? 'box-md' : ''"
            >
              <h6 class="text-white">
                Best
              </h6>
              <template v-if="session.summary.bestPlacement === 1">
                <img 
                  v-if="session.summary.bestPlacement === 1"
                  class="mt-1"
                  src="~@/assets/images/crown.png"
                >
                <small :class="resultClass">
                  <small>#</small>{{ session.summary.bestPlacement }}
                </small>
              </template>
              <h2
                v-else
                :class="resultClass"
              >
                <small>#</small>{{ session.summary.bestPlacement }}
              </h2>
            </div>
            <div class="mt-auto">
              <small class="text-white">
                ~{{ Math.round(((session.end - session.start) / (1000 * 60))).toFixed(0) }} min
              </small>         
            </div>
          </div>

          <!-- Mobile Summary -->
          <div class="d-flex d-md-none flex-column session-summary">
            <div class="d-flex flex-wrap mt-auto mb-auto">
              <b-badge
                v-for="(player) in session.players"
                :key="player"
                variant="dark"
                class="mb-1"
              >
                {{ player }}
              </b-badge>
            </div>   

            <div class="d-flex flex-wrap">
              <div class="box box-sm d-flex flex-column align-items-center p-3 bg-woodsmoke">
                <h6>
                  Games
                </h6>
                <h2 class="text-white">
                  {{ session.games.length }}
                </h2>
              </div> 
              <div class="box box-sm d-flex flex-column align-items-center p-3 bg-woodsmoke">
                <h6>
                  K/D Ratio
                </h6>
                <h2 :class="ratioClass">
                  {{ session.summary.kdRatio ? session.summary.kdRatio.toFixed(2) : '-' }}
                </h2>
              </div>
              <div class="box box-sm d-flex flex-column align-items-center p-3 bg-woodsmoke">
                <h6>
                  Kills
                </h6>
                <h2 :class="ratioClass">
                  {{ session.summary.kills }}
                </h2>
              </div>
              <div class="box box-sm d-flex flex-column align-items-center p-3 bg-woodsmoke">
                <h6>
                  Deaths
                </h6>
                <h2 :class="ratioClass">
                  {{ session.summary.deaths }}
                </h2>
              </div>                                                                         
            </div>
            <div class="d-flex flex-wrap ml-auto">
              <small class="text-gray">
                {{ formatDate(new Date(session.start), 'dd/MM/yyyy') }} : {{ formatDate(new Date(session.start), 'hh:mm') }} - {{ formatDate(new Date(session.end), 'hh:mm') }}  
              </small>                                                                         
            </div>            
          </div>                              
        </div>
        <img
          v-if="isBR === true"
          src="~@/assets/images/battleroyale.jpg"
        >
        <img
          v-else-if="isPlunder === true"
          src="~@/assets/images/plunder.jpg"
        >
        <img
          v-else
          src="~@/assets/images/other.jpg"
        >
      </div>

      <!-- Summary desktop -->
      <div class="d-none d-md-flex flex-column desktop-summary">
        <div class="d-flex flex-wrap p-0">
          <div class="session-players mr-4 mt-auto mb-auto">
            <b-badge
              v-for="(player) in session.players"
              :key="player"
              variant="dark"
              class="mb-1 d-block"
            >
              {{ player }}
            </b-badge>          
          </div>
          <div class="box d-flex flex-column align-items-center p-3 bg-woodsmoke">
            <h6>
              Games
            </h6>
            <h2 class="text-white">
              {{ session.games.length }}
            </h2>
          </div> 
          <div class="box d-flex flex-column align-items-center p-3 bg-woodsmoke">
            <h6>
              K/D Ratio
            </h6>
            <h2 :class="ratioClass">
              {{ session.summary.kdRatio ? session.summary.kdRatio.toFixed(2) : '-' }}
            </h2>
          </div>
          <div class="box d-flex flex-column align-items-center p-3 bg-woodsmoke">
            <h6>
              Kills
            </h6>
            <h2 :class="ratioClass">
              {{ session.summary.kills }}
            </h2>
          </div>
          <div class="box d-flex flex-column align-items-center p-3 bg-woodsmoke">
            <h6>
              Deaths
            </h6>
            <h2 :class="ratioClass">
              {{ session.summary.deaths }}
            </h2>
          </div>
          <div class="box d-flex flex-column align-items-center p-3 bg-woodsmoke">
            <h6>
              Da Done
            </h6>
            <h2 class="text-white">
              {{ (session.summary.damageDone / 1000).toFixed(1) }}k
            </h2>
          </div>
          <div class="box d-flex flex-column align-items-center p-3 bg-woodsmoke">
            <h6>
              Score
            </h6>
            <h2 class="text-white">
              {{ (session.summary.score / 1000).toFixed(1) }}k
            </h2>
          </div>                                                                                                         
        </div>
        <div class="d-flex flex-wrap ml-auto">
          <small class="text-gray">
            {{ formatDate(new Date(session.start), 'dd/MM/yyyy') }} : {{ formatDate(new Date(session.start), 'hh:mm') }} - {{ formatDate(new Date(session.end), 'hh:mm') }}  
          </small>                                                                         
        </div>            
      </div>  

      <!-- SESSION BADGE SUMMARY -->
      <div class="flex-break d-none d-md-flex" />
      <div
        v-if="mustDisplayBadges"
        class="badges-summary d-flex"
      >
        <div class="badges-overlay" />
        <div
          v-for="badge in badgesSummary"
          :key="badge.grade"
          class="reward xs"
          :class="`${badge.grade} ${badge.order === 6 ? 'ml-auto' : ''}`"
        >
          <div class="circle">
            <div class="text-circle">
              {{ badge.value }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="badges-summary d-flex no-badge"
      >
        <template v-if="session.badges && Object.keys(session.badges.summary).length === 0">
          <small class="text-gray">
            No badge for this session...
          </small> 
        </template>

        <template v-else-if="isFull === false">
          <small class="text-gray">
            Badges only available for <span class="text-white">
              complete
            </span> sessions
          </small> 
        </template>
        <template v-else>
          <small class="text-gray">
            Badges available for BR - Quads, Trios and Duos only
          </small> 
        </template>        
      </div>                   
    </div>

    <template v-if="visible === true">
      <div class="row p-2 session-stats align-items-center justify-content-center">
        <!-- RESULTS -->
        <div class="col col-12 d-flex flex-column justify-content-center">
          <div class="d-flex justify-content-center flex-wrap bt-manatee bb-manatee pt-2 pb-2">
            <div
              v-for="(teamPlacement, index) in session.summary.teamPlacements.slice().reverse()"
              :key="index"
              class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
              :class="isSmallDevice ? 'box-xs' : ''"
            >
              <img 
                v-if="teamPlacement === 1"
                class="mt-1"
                src="~@/assets/images/crown.png"
              >
              <h2
                :class="resultPlacement(teamPlacement)"
              >
                <small>#</small>{{ teamPlacement }}
              </h2>
            </div> 
          </div>
        </div>    
      </div>  

      <div class="row p-2 session-stats align-items-center justify-content-center">
        <div class="col col-12">
          <b-tabs
            content-class="pt-2"  
          >
            <b-tab
              v-if="mustDisplayBadges === true"
              title="Rewards"
              :active="mustDisplayBadges === true"
              lazy
            >
              <session-badges
                :session="session"
              />
            </b-tab> 
            <b-tab
              title="Stats"
              :active="mustDisplayBadges === false"
              lazy
            >
              <session-charts :session="session" />  
            </b-tab>          
            <b-tab
              title="Games"
              lazy
            >
              <session-games
                :session="session"
                :kd-ratio="kdRatio"
                :session-kd-ratio="session.summary.kdRatio"
              /> 
            </b-tab>
          </b-tabs> 
        </div>
      </div>     
      <div class="row pt-3 m-0 session-stats">
        <div class="col-12 text-right p-3 session-id">
          <small>Session ID : {{ session.id }}</small>
        </div>                                           
      </div>
    </template>       
  </section>
</template>

<style lang="scss" scoped>

  @import '~@/assets/style/colors.scss';
  section {
    border-radius: 16px;
  }

  .session-card {
    border-left: 2px solid transparent;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    @media(max-width: 420.98px) {
      flex-direction: column
    }
  }

  .session-players {
    width: 140px;
  }

  /deep/ .tabs > div:not(.tab-content) > ul.nav-tabs {
    @media (max-width: 421px) {
      overflow-y: auto;
      width: calc(100vw - 40px);
      flex-wrap: nowrap;
      display: flex;  
      padding-bottom: 6px;
      
      & li:first-child {
        margin-left: auto;
      }

      & li:last-child {
        margin-right: auto;
      }
    }    
  }   

  div.session-graphic {
    position: relative;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    height: auto;
    width: 100%;
    max-width: 124px;
    margin-left: -16px;
    margin-top: -16px;
    margin-bottom: -16px;
    object-fit: cover;
    height: 124px;

    @media(max-width: 420.98px) {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;      
      max-width: calc(100% + 32px);
      width: calc(100% + 32px);
      border-right: 0px;
      height: 140px;
    }    

    & .graphic-overlay {
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.76);
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;    
      // border-top: 1px solid $color-yellow;  

      @media(max-width: 420.98px) {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;      
      }       
    }

    & .session-result {
      position: absolute;
      z-index: 2;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      
      & > .d-flex {
        width: 100%;
      }

      @media(max-width: 420.98px) {
        padding-left: 8px !important;
        padding-right: 8px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;      
        
        & > .d-flex {
          &:first-child {
            width: 120px;
          }
          
        }

        & h1 {
          font-size: 24px;
          line-height: 1px;
          margin: 0;
        }

        & > div > small {
          font-size: 8px;

          &:first-child {
            margin-bottom: auto;
          }

          &:last-child {
            margin-top: auto;
            font-size: 4px;
          }
        }
      }         

      & img {
        width: 24px;
      }
    }

    .session-summary {
      & small {
        font-size: 10px;
      }
    }

    & > img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px; 

      @media(max-width: 420.98px) {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;      
      }       
    }
  }

  .desktop-summary {
    height: 124px;
    flex-grow: 1;
  }  

  div.session-id {
    border-top: 1px solid $color-woodsmoke;
  }

  div.badges-summary {
    z-index: 2;
    width: 100%;

    @media(max-width: 422px) {
      top: 8px;
      margin-top: 16px;
      position: relative;
    }

    & > div.badges-overlay {
      background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
    }

    & > .reward {
      margin-right: 12px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

</style>


<script>

import { mapState } from 'vuex' 

import SessionBadges from '@/components/sessions/SessionBadges.vue'
import SessionGames from '@/components/sessions/SessionGames.vue'
import SessionCharts from '@/components/sessions/SessionCharts.vue'

export default {
  name: 'SessionCard',
  components: {
    SessionBadges,
    SessionGames,
    SessionCharts
  },
  props: {  
    session: {
      type: Object,
      required: true
    },
    kdRatio: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      visible: false,
      detailsVisible: false,
      badgesOrder: {
        'bronze': 0,
        'silver': 1,
        'gold': 2,
        'epic': 3,
        'master': 4,
        'legendary': 5,
        'special': 6,
        'god': 7
      },           
    }
  },
  computed: mapState({   
    isBR: function() {
      return this.mapping.gameTypePerMode[this.session.mode] === 'br'
    },
    isPlunder: function() {
      return this.mapping.gameTypePerMode[this.session.mode] === 'plunder'
    },
    isFull: function() {
      return this.session.players.length === this.mapping.playersPerMode[this.session.mode]
    },
    mustDisplayBadges: function() {
      return this.session.badges !== undefined
        && this.mapping.sessionsModes.indexOf(this.session.mode) >= 0
        && this.session.players.length >= 2
        && this.isFull === true
        && Object.keys(this.session.badges.summary).length > 0
    },
    badgesSummary: function() {
      let badges = []
      if (this.session.badges === undefined) {
        return badges
      }
      Object.keys(this.session.badges.summary).forEach(
        (grade) => {
          badges.push({
            grade: grade,
            value: this.session.badges.summary[grade],
            order: this.badgesOrder[grade]
          })
        }
      )
      
      badges.sort((a, b) => {
        a.order = this.badgesOrder[a.grade]
        b.order = this.badgesOrder[b.grade]

        if (this.badgesOrder[a.grade] > this.badgesOrder[b.grade]) {
          return 1
        } else if (this.badgesOrder[a.grade] < this.badgesOrder[b.grade]) {
          return -1
        } else {
          return 0
        }
      })

      return badges
    },
    resultClass: function() {
      const place = this.session.summary.bestPlacement
      return this.resultPlacement(place)
    },
    ratioClass: function() {
      const kdRatio = this.session.summary.kdRatio
      if (kdRatio === null) {
        return 'text-white'
      }
      const diff = kdRatio - this.kdRatio
      if (diff > 0) {
        return 'text-success'
      } else if (diff < 0) {
        return 'text-danger'
      } else {
        return 'text-white'
      }
    },
    sessionClass: function() {
      const kdRatio = this.session.summary.kdRatio
      if (kdRatio === null) {
        return 'border-white'
      }      
      const diff = kdRatio - this.kdRatio
      if (diff > 0) {
        return 'border-success'
      } else if (diff < 0) {
        return 'border-danger'
      } else {
        return 'border-gray'
      }
    }    
  }),  
  created: function() {
    this.detailsVisible = this.mustDisplayBadges === false
  },
  mounted: function() {

  },  
  methods: {
    resultPlacement(placement) {
      if (placement <= 5) {
        return 'text-blue'
      } else if (placement <= 10) {
        return 'text-yellow'
      } else if (placement <= 25) {
        return 'text-white'
      } else {
        return 'text-gray'
      }
    }
  }  
}
</script>
