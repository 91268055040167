<template>
  <section class="mb-2">
    <div
      ref="chart"
      class="chart br-16"
    />
  </section>
</template>

<style lang="scss" scoped>
  
  @import '@/assets/style/colors.scss';

  section {
    border-radius: 16px;
    background-color: $color-charade;
  }
  .chart {
    width: 100%;
    height: 300px;
  }

  /deep/ .plot-label {
    position: relative;
    font-size: 10px;
    top: 6px;
    left: -24px;
    display: inline-block;
    padding: 2px;
    background-color: $color-blue;
    color: $color-white;
  }

  /deep/ .highcharts-axis-labels {
    & img.crown {
      position: relative;
      display: block;
      top: -4px;
      width: 16px;      
    } 
  }  
</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'SessionGamesEvolutionChart',
  components: {

  },
  props: {
    statsKey: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    plotLine: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      chart: null,
      max: 0,
      crownImage: require('@/assets/images/crown.png')
    }
  },
  computed: mapState({
    
  }),  
  watch: {

  },  
  created: function() {

  },
  mounted: function() {
    this.initChart()
  },  
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },  
  methods: {
    initChart() {
      // Create chart instance
      const data = this.getChartData()

      const vm = this

      let chart = this.Highcharts.chart(this.$refs['chart'], {
          chart: {
            paddingLeft: 40,
            paddingRight: 40,
            marginTop: 16,  
            marginRight: 16,               
            marginLeft: 16,
            marginBottom: 96,
          },

          legend: {
            enabled: true,
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal'
          },      

          xAxis: {
            categories: data.categories,
            lineColor: 'rgba(143, 153, 172, 0.25)',
            gridLineColor: 'rgba(143, 153, 172, 0.25)',
            paddingRight: 40,
            marginRight: 40,            
            labels: {
              useHTML: true,
              rotation: 0,
              y: 24,
              formatter: function() {
                let html = `<span class="${vm.resultPlacement(this.value)}">`
                if (this.value === 1) {
                  html = `
                    ${html}
                    <img src="${vm.crownImage}" class="crown">
                    #${this.value}
                    </span>
                  ` 
                } else {
                  html = `${html}#${this.value}</span>`
                }
                return html
              }
            }                
          }, 
          yAxis: { // Progression
            visible: true,
            lineColor: 'rgba(143, 153, 172, 0.25)',
            gridLineColor: 'rgba(143, 153, 172, 0.25)',
            min: 0,
            max: this.max,
            title: {
              text: null,
            },   
            labels: {
              enabled: false
            },
            plotLines: this.plotLine ? 
              [{
                color: 'rgba(0, 178, 221, 1)',
                width: 1,
                value: this.plotLine,
                label: {
                  rotation: 0,
                  useHTML: true,              
                  formatter: function () {
                    return `<span class="plot-label">${this.options.value.toFixed(2)}</span>`
                  }
                }
              }] : null                
          },  
          
          plotOptions: {
            spline: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                color: '#ffffff',
                borderWidth: 0,
                borderColor: 'transparent',
                crop: false,
                overflow: 'justify',
                align: 'right',
                inside: true,
                x: 0,     
                style: {
                  fontSize: '10px',
                  textOutline: false
                },        
                formatter: function() {
                  return this.y > vm.plotLine ? this.y.toFixed(2) : null
                }
              }        
            }
          },        

          tooltip: {
            split: true
          },      

          series: data.series
      });
      
      this.chart = chart      
    },
    getChartData() {
      let series = {}
      const categories = []

      this.data.forEach(
        (dataSet) => {
          const teamPlacement = dataSet.teamPlacement
          categories.push(teamPlacement)
          const players = Object.keys(dataSet.playersStats)
          players.forEach(
            (player) => {
              const stats = dataSet.playersStats[player]
              if (stats[this.statsKey] > this.max) {
                this.max = stats[this.statsKey]
              }
              if (series[player]) {
                series[player].data.push(stats[this.statsKey])
              } else {
                series[player] = {
                  category: teamPlacement,
                  name: player.substring(0, 5),
                  data: [stats[this.statsKey]],
                  type: 'spline'
                }
              }
            }
          )
        }
      )

      return {
        categories: categories,
        series: Object.values(series)
      }
    },
    resultPlacement(placement) {
      if (placement <= 5) {
        return 'text-blue'
      } else if (placement <= 10) {
        return 'text-yellow'
      } else if (placement <= 25) {
        return 'text-white'
      } else {
        return 'text-gray'
      }
    }   
  }  
}
</script>
