<template>
  <section>
    <div class="username-id align-items-center">      
      <b-form
        ref="username-id-form"
        class="flex-grow-1"
      >
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <b-form-group
              id="input-group-player-select"
              class="floating-labels mb-0"
            >
              <multiselect
                :id="`input-username-id`"
                v-model="selectedPlayerId"
                :placeholder="'Player'"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"              
                :options="playersIds"
                :searchable="searchable === true"
                :allow-empty="false"
                :taggable="true"
                @tag="addplayerId"
                @select="submit"        
              >
                <template
                  slot="singleLabel"
                  slot-scope="{ option }"
                >
                  {{ option }}
                </template>             
              </multiselect> 
              <label
                v-if="selectedPlayerId"
                class="is-floating"
                :for="`input-username-id`"
              >
                Player
              </label>                        
            </b-form-group>

            <b-button
              v-if="goButton === true"
              size="sm"
              class="mt-2"
              @click="go"
            >
              Go
            </b-button>
          </div>                 
        </div>          
      </b-form>
      <div class="d-flex pl-3 pr-3 pt-1 align-items-center">
        <div
          v-if="selectedPlayerId !== null && allowFav === true && selectedPlayerId !== 'All Squad'"
          class="player-fav mr-auto pr-2 clickable"
          :class="isFavoris ? 'is-favoris' : ''"
          @click="toggleFav"
        >
          <b-icon icon="star-fill" /> <small class="text-gray">Favoris</small>
        </div>      
        <div
          v-if="selectedPlayerId !== null && allowShare === true"
          class="player-link ml-auto pl-2 clickable"
          :class="isFavoris ? 'is-favoris' : ''"
          @click="copyPlayerLink"
        >
          <small class="text-gray">Share</small> <b-icon icon="share-fill" />
        </div>               

        <input
          id="player-link"
          type="text"
          readonly="readonly"
          :value="playerLink"
        >
      </div>      
    </div>                
  </section>
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

  input#player-link {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -9999;
  } 

  .username-id {
    max-width: 500px;
    margin: 0 auto;
  }

  .player-fav {
    &:hover {
      & > svg {
        color: $color-manatee;
      }
    }    
    & > svg {
      transition: all 0.3s;
      color: $color-manatee-opacity;      
    }
    &.is-favoris {
      & > svg {
        color: $color-blue;
      }      
    }
  }

  .player-link {
    & > svg {
      transition: all 0.3s;
      color: $color-manatee-opacity;
      font-size: 14px;
    }
    &:hover {
      & > svg {
        color: $color-manatee;
      }
    }
  }  
</style>

<script>

import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'

export default {
  name: 'PlayerSelect',
  components: {
    
  },
  mixins: [validationMixin],
  props: {
    errors: {
      type: Object,
      default: null
    },
    players: {
      type: Array,
      default: null
    },
    addSquad: {
      type: Boolean,
      default: false
    },
    allowShare: {
      type: Boolean,
      default: true
    },
    allowFav: {
      type: Boolean,
      default: true
    },
    searchable: {
      type: Boolean,
      default: false
    },
    goButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectedPlayerId: null,
      playersIds: [],
      favIds: []
    }
  },
  computed: mapState({
    isFavoris: function() {
      return this.favIds.indexOf(this.selectedPlayerId) >= 0
    },
    playerLink: function() {
      return window.location.href
    }    
  }),
  watch: {

  },
  created() {
    try {
      this.favIds = JSON.parse(window.localStorage.getItem('favoritePlayersIds'))
      if (this.favIds === null) {
        this.favIds = []
      }
      if (this.players === null) {
        this.playersIds = this.favIds.slice()
      } else {
        this.playersIds = [...new Set(this.players.map(item => item.username))].sort()
      }
    } catch (e) {
      this.playersIds = []
    }

    if (this.addSquad === true) {
      this.playersIds.splice(0, 0, 'All Squad')
      if (this.playersIds.indexOf(this.playerId) < 0) {
        this.$store.commit('global/UNSET_PLAYER_ID')
        this.selectedPlayerId = this.playersIds[0]
      } else {
        this.selectedPlayerId = this.playerId  
      }
    } else {
      this.selectedPlayerId = this.playerId
    }
  },
  methods: {
    submit(e) {
      if (e === null) {
        return false
      }

      if (e === 'All Squad') {
        this.$store.commit('global/UNSET_PLAYER_ID')
      } else {
        this.$store.commit('global/SET_PLAYER_ID', e)
      }

      if (this.allowFav
        && this.isFavoris) {
        window.localStorage.setItem('selectedPlayerId', e)        
      }
    },
    addplayerId(id) {
      this.$store.commit('global/SET_PLAYER_ID', id)
      this.playersIds.push(id)
      this.selectedPlayerId = id
    },
    toggleFav() {
      if (this.isFavoris === true) {
        this.remFav()
      } else {
        this.addFav()
      }

      window.localStorage.setItem('favoritePlayersIds', JSON.stringify(this.favIds))
    },
    addFav() {
      this.playersIds.push(this.selectedPlayerId)
      this.favIds.push(this.selectedPlayerId)
    },
    remFav() {
      const index = this.playersIds.indexOf(this.selectedPlayerId)
      if (index >= 0) {
        this.playersIds.splice(index, 1)
        this.favIds.splice(index, 1)
      }
    },
    copyPlayerLink() {
      /* Get the text field */
      const input = document.getElementById('player-link');

      /* Select the text field */
      input.select();
      input.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");      
    },   
    go() {
      if (this.selectedPlayerId !== null) {
        this.$store.commit('global/SET_PLAYER_ID', this.selectedPlayerId)
        this.$router.push(
          {
            name: 'Player',
            query: {
              id: this.selectedPlayerId
            }
          }
        )
      }
    } 
  }
}
</script>
