<template>
  <section>
    <div class="row justify-content-md-center">
      <div class="col col-12">
        <player-select
          v-if="allowPlayerSelect"
          :searchable="true"
        />

        <template v-if="isLoading === true">
          <loader />
        </template>

        <template v-else>
          <template v-if="playerId === null">
            <small class="text-gray">
              Find or create a player
            </small>
            
            <div
              class="start p-2"
            >
              <router-link to="/players/new">
                <a class="shiny text-gray">
                  <small>Create a </small><br><b class="text-white">Solo</b><br> <small>player!</small>
                  <i />
                </a>
              </router-link>               
            </div>
          </template>
          <template v-else-if="player === false">
            <b-alert
              class="info round"
              show
            >
              <div class="alert-box-content">
                <b-icon
                  icon="exclamation-circle"
                  variant="danger"
                />              
                <span class="alert-box-message">
                  Player not found
                </span>
                <span class="alert-box-details">
                  You try to access a player who does not exist...
                </span>
              </div>
            </b-alert>
          </template>

          <template v-else-if="player !== null">
            <template v-if="error !== null && error.mustConfirm !== true">
              <b-alert
                class="info round mb-3"
                show
              >
                <div class="alert-box-content">
                  <b-icon
                    icon="exclamation-circle"
                    variant="danger"
                  />              
                  <span class="alert-box-message">
                    Did you check your profile Privacy?            
                  </span>
                  <span class="alert-box-details">
                    The system was not able to request your data!
                    <div class="mt-0 mb-1">
                      <strong class="text-white"> {{ error }}</strong>
                    </div>                    
                    <a
                      v-b-modal.modal-profile-privacy
                      class="text-white"
                    >Check how</a>                
                  </span>
                </div>
              </b-alert> 
            </template>

            <!-- CARD -->
            <section class="player">
              <player-card
                :player="player"
                @player-updated="load"
              />
            </section>

            <div class="update-player-button">
              <b-button
                variant="primary"
                size="sm"
                class="p-2"
                :disabled="isRefreshing === true"
                @click="updatePlayer"
              >
                <b-icon
                  icon="arrow-repeat"
                  :animation="isRefreshing ? 'spin' : ''"
                />
              </b-button>
            </div>            
          </template>
        </template>  
      </div>         
    </div>      

    <!-- SQUAD PULL MODAL -->
    <squad-pull-modal
      :errors="error"
    />    
  </section>
</template>

<style lang="scss" scoped>
  .player {
    margin: 0 auto;
    max-width: 1056px;
    border-radius: 16px;
  }  

 .update-player-button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 100;
  }   

  .start {
    position: relative;

    & a.shiny {
      height: 100px;
      width: 100%;
      max-width: 420px;
      padding: 8px;
    }
  }  
</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex'

import Loader from '@/components/layout/Loader.vue'

import PlayerSelect from '@/components/players/PlayerSelect.vue'
import PlayerCard from '@/components/players/PlayerCard.vue'
import SquadPullModal from '@/components/squads/SquadPullModal.vue'

export default {
  name: 'Session',
  components: {
    Loader,
    PlayerSelect,
    PlayerCard,
    SquadPullModal
  },
  props: {
    username: {
      type: String,
      default: null
    },
    allowPlayerSelect: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      error: null,
      isRefreshing: false,      
      player: null,
    }
  },
  computed: mapState({
    playerId: state => state.global.playerId
  }),
  watch: {
    playerId: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.load()
      }
    }
  },
  created() {
    this.load(true)
  },
  methods: {
    load(forceUpdate = false) {
      this.isLoading = true
      this.hasError = false
      this.error = null

      let username = null
      if (this.username === null) {
        username = this.playerId
      } else {
        username = this.username
      }

      // Get query params
      if (username) {
        const params = {
          player: username
        }

        http.getPlayer(params).then(
          (response) => {
            this.player = response.data
            if (
              forceUpdate === true
              && this.player.status.isUpdated === false) {
              this.updatePlayer()
            }
          }
        ).catch(
          () => {
            this.player = false
          }
        ).finally(
          () => {
            this.isLoading = false
          }
        )

        // Get session
      } else {
        this.player = false
        this.isLoading = false
      }
    },
    updatePlayer() {
      this.isRefreshing = true
      this.hasError = false
      this.error = null

      http.updatePlayer({
        player: this.player.username
      }).then(
        () => {
          this.load(false)
        }
      ).catch(
        (error) => {
          if (error.response.data.mustConfirm) {
            this.error = error.response.data
            this.$root.$emit('bv::show::modal', 'modal-squad-pull')
          } else {
            this.error = error.response.data
            this.hasError = true
          }          
        }
      ).finally(
        () => {
          this.isRefreshing = false
        }
      )
    }     
  }  
}
</script>
