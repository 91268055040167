<template>
  <section>
    <template v-if="isLoading === true">
      <div class="row justify-content-center p-2">
        <div class="col col-12">
          <h4 class="mb-4">
            Data processing...
          </h4> 
          <small class="text-gray">
            A bit of patience Verdanskien, your sessions are being compiled...
          </small>
          
          <loader />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row justify-content-center p-2">
        <div class="col col-12">
          <h4 class="mb-4">
            Welcome Verdanskien!
          </h4> 

          <b-alert
            v-if="hasError === false"
            class="info round"
            show
          >
            <div class="alert-box-content">
              <b-icon
                icon="exclamation-circle"
                variant="warning"
              />              
              <span class="alert-box-message">
                Did you check your profile Privacy?
              </span>
              <span class="alert-box-details">
                Your data must be visible<br>
                <a
                  v-b-modal.modal-profile-privacy
                  class="text-white"
                >Check how</a>
              </span>
            </div>
          </b-alert>
          <b-alert
            v-else-if="hasError === true"
            class="info round"
            show
          >
            <div class="alert-box-content">
              <b-icon
                icon="exclamation-circle"
                variant="danger"
              />              
              <span class="alert-box-message">
                <span class="text-gray">Your Activision profile is</span> <br>PRIVATE           
              </span>
              <span class="alert-box-details">         
                <div class="mt-2">
                  <p class="text-gray">
                    {{ errors.message }}
                  </p>
                </div>

                <a
                  v-b-modal.modal-profile-privacy
                  class="text-white"
                >Check how to open your <b>Stats</b></a>                
              </span>
            </div>
          </b-alert>
          <b-form 
            id="players-form"
            ref="players-form"        
            class="pt-4 mt-3 ml-auto mr-auto"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-form-group
                :id="`input-group-player`"
                class="floating-labels input-player mb-0"
              >
                <b-form-input
                  :id="`input-player-username`"
                  :ref="`input-player-username`"
                  v-model="form.username"
                  type="text"
                  trim
                  :placeholder="`Player`"
                  :state="validateState(form)"
                  :aria-describedby="`feedback-player-username`"
                />
                <label :for="`input-player-username`">
                  Player
                </label>             
              </b-form-group> 

              <b-form-group
                id="input-group-player"
                class="floating-labels ml-2 mb-0"
              >
                <multiselect
                  :id="`input-player-platform`"
                  v-model="form.platform"
                  :placeholder="''"
                  :preselect-first="true"
                  :multiple="false"
                  :label="'label'"
                  :close-on-select="true"
                  :clear-on-select="false"              
                  :options="platforms"
                  :track-by="'id'"
                  :searchable="false"
                  :allow-empty="false"
                  @select="onPlatformChange(form)"
                >
                  <template
                    slot="singleLabel"
                    slot-scope="{ option }"
                  >
                    {{ option.id }}
                  </template>             
                </multiselect>            
              </b-form-group>                    
            </div>       

            <small>
              <a
                v-b-modal.modal-profile-username
                class="text-gray"
              >How do I find my username?</a>
            </small>  

            <div class="p-2 mt-2">         
              <b-button
                variant="primary"
                size="sm"
                type="submit"
                @click="submit"
              >
                Go!
              </b-button>
            </div>                             
          </b-form>       
        </div>
      </div>

      <div class="row justify-content-center p-2">
        <div class="col col-12">
          <player-select
            :searchable="true"
            :allow-fav="false"
            :allow-share="false"
            :go-button="true"
          />
        </div>
      </div>       
    </template>             
  </section>
</template>

<style lang="scss" scoped>

  section {
    max-width: 860px;
    margin: 0 auto;
  }

  /deep/ form#players-form .multiselect {
    min-width: 100px;
    width: 100px;

    & > .multiselect__content-wrapper {
      min-width: 200px;
      transform: translateX(-50%)
    }
  }

  /deep/ fieldset.input-player {
    @media (min-width: 422px) {
      width: 100%;
    }
  }
</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex'
import { validationMixin } from "vuelidate"

import loader from '@/components/layout/Loader.vue'
import PlayerSelect from '@/components/players/PlayerSelect.vue'

export default {
  name: 'PlayerCreate',
  components: {
    loader,
    PlayerSelect
  },
  mixins: [validationMixin],
  props: {
   
  },
  data() {
    return {
      isLoading: false,
      hasFormError: false,
      hasError: false,
      errors: null,
      platforms: [
        {
          id: 'acti',
          label: 'Activision ID'
        },        
        {
          id: 'psn',
          label: 'Playstation'
        },
        {
          id: 'steam',
          label: 'Steam'
        },
        {
          id: 'xbl',
          label: 'Xbox Live'
        },
        {
          id: 'battle',
          label: 'Battle.net'
        }        
      ],
      form: {
        username: null,
        platform: null
      }        
    }
  },
  computed: mapState({

  }),
  watch: {

  },
  created() {

  },
  methods: {
    validateState(player) {
      if (player.username === null) {
        return true
      }
      if (this.errors !== null && this.errors[player.username] !== undefined) {
        return false
      }
      // Check if player already defined
      return player.username !== null
        && player.platform !== null

    },      
    validateForm: function() {
      // Check if players are correct
      let valid = this.validateState(this.form)
      return valid
    },
    onPlatformChange(player) {
      if (this.errors
        && this.errors[player.username]) {
          delete this.errors[player.username]
        }
    },
    submit() {
      
      this.isLoading = true

      this.hasNotSharedGames = false

      http.createPlayer({player: this.form}).then(
        (response) => {
          let playerIds = null
          try {
            playerIds = JSON.parse(window.localStorage.getItem('favoritePlayersIds'))  
            if (playerIds === null) {
              playerIds = [response.data.username]
            } else {
              playerIds.push(response.data.username)
            }
          } catch (e) {
            // Do nothing
            playerIds = [response.data.username]
          }

          window.localStorage.setItem('favoritePlayersIds', JSON.stringify(playerIds))
          window.localStorage.setItem('selectedPlayerId', response.data.username)

          this.$store.dispatch('global/setPlayerId', response.data.username)

          // Get session id
          this.$router.push({ name: 'Player', query: { username: response.data.username } })
        }
      ).catch(
        (error) => {    
          this.hasError = true      
          this.errors = error.response.data
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    }      
  }
}
</script>
