<template>
  <section>
    <div class="row justify-content-center justify-content-center">
      <div class="col col-12">
        <div>
          <b-tabs content-class="mt-0 pt-3">
            <b-tab
              title="Overview"
              active
              lazy
            >
              <admin-overview />
            </b-tab>
            <b-tab
              title="Players"
              lazy
            >
              <admin-players />
            </b-tab>              
            <b-tab
              title="Squads"
              lazy
            >
              <admin-squads />
            </b-tab>
            <b-tab
              title="Sessions"
              lazy
            >
              <admin-sessions />
            </b-tab>                                   
            <!-- <b-tab
              title="Games"
              lazy
            >
              <admin-games />
            </b-tab> -->
            <b-tab
              title="CoD API"
              lazy
            >
              <admin-cod-api />
            </b-tab>            

            <template #tabs-end>
              <b-nav-item
                id="btn-refresh"
                role="presentation"
                class="ml-auto"
                href="#"
                @click.prevent="refresh"
              >
                <b-icon icon="arrow-repeat" />
              </b-nav-item>
            </template>
          </b-tabs>
        </div>
      </div> 
    </div>   
  </section>
</template>

<style lang="scss" scoped>

  @import '~@/assets/style/colors.scss';

  /deep/ .tabs > div:not(.tab-content) > ul.nav-tabs {
    @media (max-width: 421px) {
      overflow-y: auto;
      width: calc(100vw - 80px);
      flex-wrap: nowrap;
      display: flex;  
      padding-bottom: 6px;
      
      & #btn-refresh {
        position: absolute;
        right: 16px;
      }
    }    
  }  

</style>

<script>

import { mapState } from 'vuex'

import AdminOverview from '@/components/admin/AdminOverview.vue'
import AdminSquads from '@/components/admin/AdminSquads.vue'
import AdminSessions from '@/components/admin/AdminSessions.vue'
import AdminPlayers from '@/components/admin/AdminPlayers.vue'
// import AdminGames from '@/components/admin/AdminGames.vue'
import AdminCodApi from '@/components/admin/AdminCodApi.vue'

export default {
  name: 'Admin',
  components: {
    AdminOverview,
    AdminSquads,
    AdminSessions,
    AdminPlayers,
    // AdminGames,
    AdminCodApi
  },
  props: {

  },
  data() {
    return {
      
    }
  },
  computed: mapState({
 
  }),
  watch: {

  },
  created() {
    
  },
  methods: {
    refresh() {
      this.$root.$emit('refresh-admin')
    }
  }  
}
</script>
