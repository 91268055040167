<template>
  <section class="p-4">
    <div
      ref="chart"
      class="chart"
    />
  </section>
</template>

<style lang="scss" scoped>
  
  @import '@/assets/style/colors.scss';

  section {
    border-radius: 16px;
    background-color: $color-charade;
  }
  .chart {
    width: 100%;
    height: 500px;
  }
</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'AdminOverviewChart',
  components: {

  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      chart: null
    }
  },
  computed: mapState({
    
  }),  
  watch: {

  },  
  created: function() {

  },
  mounted: function() {
    this.initChart()
  },  
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },  
  methods: {
    initChart() {
      // Create chart instance
      const data = this.getChartData()

      let chart = this.Highcharts.stockChart(this.$refs['chart'], {
          chart: {
            paddingLeft: 40,
            paddingRight: 40,
            marginTop: 16,  
            marginRight: 32,               
            marginLeft: 16,
            marginBottom: 40,
          },

          legend: {
            enabled: true,
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal'
          },          

          rangeSelector: {
            allButtonsEnabled: true,
            buttons: [{
                type: 'day',
                count: 1,
                text: '1d',
                title: 'View 1 week'
            },{
                type: 'week',
                count: 1,
                text: '1w',
                title: 'View 1 week'
            },              
            {
                type: 'month',
                count: 1,
                text: '1m',
                title: 'View 1 month'
            }, {
                type: 'month',
                count: 3,
                text: '3m',
                title: 'View 3 months'
            }],
            selected: 1
          },

          xAxis: {
            lineColor: 'rgba(143, 153, 172, 0.25)',
            gridLineColor: 'rgba(143, 153, 172, 0.25)',
            paddingRight: 40,
            marginRight: 40,            
            labels: {
              rotation: 0,
              y: 24
            }                
          }, 
          yAxis: {
            min: 0,
            visible: true,
            lineColor: 'rgba(143, 153, 172, 0.25)',
            gridLineColor: 'rgba(143, 153, 172, 0.25)',
            title: {
              text: null,
            },
            plotLines: [
              {
                value: 0,
                width: 2,
                color: 'silver'
              }
            ]
          },          

          plotOptions: {
            series: {
              showInNavigator: true,               
            }
          },

          tooltip: {
              split: true
          },

          navigator: {
              margin: 48,
              adaptToUpdatedData: true,
              outlineWidth: 0,
              height: 48,
              xAxis: {
                gridLineWidth: 0,
                labels: {
                  enabled: false
                }
              },
              yAxis: {
                gridLineWidth: 0,
              }
            },          

          series: data
      });
      
      this.chart = chart      
    },
    getChartData() {
      return [
        {
          name: 'Total',
          data: Object.values(this.data.visits.data),
          type: 'spline',
        },
        {
          name: 'Session created',
          data: Object.values(this.data.sessions.data),
          type: 'spline',
        },
        {
          name: 'Games pulled',
          data: Object.values(this.data.games.data),
          type: 'spline',
        },
        {
          name: 'Players added',
          data: Object.values(this.data.players.data),
          type: 'spline',
        }        
      ]
    }
  }  
}
</script>
