<template>
  <section>
    <template v-if="isLoading === true">
      <loader />
    </template>

    <div class="row justify-content-center">
      <div
        v-if="allowRefresh === true"
        class="update-sessions-button"
      >
        <b-button
          variant="primary"
          size="sm"
          class="p-2"
          :disabled="isRefreshing === true"
          @click="updateSquad"
        >
          <b-icon
            icon="arrow-repeat"
            :animation="isRefreshing ? 'spin' : ''"
          />
        </b-button>
      </div> 
      
      <div
        v-if="isLoading === false && allowPlayerSelection === true && sessions"
        class="col col-12 col-lg-6 col-xl-4 mb-4"
      >
        <player-select
          :add-squad="true"
          :players="sessions.players"
          :allow-fav="true"
          :allow-share="false"
        />        
      </div>

      <template v-if="isLoading === false">
        <template v-if="hasError === true">
          <div class="col col-12 col-lg-6 col-xl-4">
            <div class="d-flex align-items-center">           
              <b-alert
                class="info round"
                show
              >
                <div class="alert-box-content">
                  <b-icon
                    icon="exclamation-circle"
                    variant="danger"
                  />              
                  <span class="alert-box-message">
                    Oops, something went wrong
                  </span>
                  <span class="alert-box-details">
                    Unable to load the data
                  </span>
                </div>
              </b-alert>
            </div>
          </div>
        </template>

        <template v-else>
          <template v-if="sessions === false">
            <div
              class="start p-2"
            >
              <router-link :to="`/squads/new?player=${player}`">
                <small class="text-gray">
                  No session found, you may need to create a Squad!
                </small>
                <a class="shiny text-gray mt-3">
                  <small>Let's </small> <b class="text-white">Go</b>!
                  <i />
                </a>
              </router-link>                
            </div>
          </template>
          <template v-else>
            <div class="col col-12 col-lg-6 col-xl-4">
              <div class="d-flex align-items-center">          
                <b-form-group
                  id="input-group-mode"
                  class="floating-labels flex-grow-1 mr-2 mb-0"
                >
                  <multiselect
                    id="input-mode"
                    v-model="filters.selectedMode"
                    :placeholder="'Select a mode'"
                    :multiple="false"
                    :label="'label'"
                    :close-on-select="true"
                    :clear-on-select="false"              
                    :options="modes"
                    :track-by="'id'"
                    :searchable="false"
                    :allow-empty="false"
                    @input="onFilterChange"
                  >
                    <template
                      slot="singleLabel"
                      slot-scope="{ option }"
                    >
                      {{ option.label }}
                    </template>             
                  </multiselect>
                  <label
                    v-if="filters.selectedMode"
                    class="is-floating"
                    for="input-mode"
                  >
                    Mode
                  </label>             
                </b-form-group> 
                <label class="switch mt-0 mb-0 ml-auto">
                  <input
                    id="only-full"
                    v-model="filters.onlyFull"
                    type="checkbox"
                    @change="onFilterChange"
                  >
                  <span class="slider round" />

                  <div class="slider-label text-gray">
                    Complete
                  </div>
                </label>
                <label
                  v-if="isSmallDevice === false"
                  class="switch mt-0 mb-0 ml-3"
                >
                  <input
                    id="only-wins"
                    v-model="filters.onlyWins"
                    type="checkbox"
                    @change="onFilterChange"
                  >
                  <span class="slider round" />

                  <div class="slider-label text-gray">
                    <img
                      src="@/assets/images/crown.png"
                      class="crown"
                    >
                  </div>            
                </label>                          
              </div>
            </div>

            <div
              v-if="isSmallDevice === true"
              class="col col-12 col-lg-6 col-xl-3 pt-3"
            >
              <div class="d-flex align-items-center">
                <small class="text-gray ml-2 mr-auto">
                  <span class="text-white">
                    {{ sessions.sessions.count }}
                  </span> / {{ sessions.sessions.total }} Sessions
                </small>
                <label class="switch mt-0 mb-0 ml-auto">
                  <input
                    id="only-wins"
                    v-model="filters.onlyWins"
                    type="checkbox"
                    @change="onFilterChange"
                  >
                  <span class="slider round" />

                  <div class="slider-label text-gray">
                    <img
                      src="@/assets/images/crown.png"
                      class="crown"
                    >
                  </div> 
                </label>               
              </div>
            </div>  

            <template v-if="hasErrorUpdate === true">
              <template v-if="errors.mustConfirm !== true">
                <div class="col col-12">
                  <b-alert
                    class="info round mb-4"
                    show
                  >
                    <div class="alert-box-content">
                      <b-icon
                        icon="exclamation-circle"
                        variant="danger"
                      />              
                      <span class="alert-box-message">
                        Your Activision profile is PRIVATE?            
                      </span>
                      <span class="alert-box-details">
                        Some Players are private or mistyped!              
                        <div
                          v-for="error in Object.keys(errors)"
                          :key="error"
                          class="mt-2"
                        >
                          <strong class="text-white"> {{ error }}</strong>
                          <p class="text-gray">
                            {{ errors[error] }}
                          </p>
                        </div>

                        <a
                          v-b-modal.modal-profile-privacy
                          class="text-white"
                        >Check how to open your <b>Stats</b></a>
                      </span>
                    </div>
                  </b-alert>
                </div>
              </template>
            </template>            

            <template v-if="sessions.sessions.list.length === 0">
              <div class="col col-12">
                <b-alert
                  class="info round mt-4"
                  show
                >
                  <div class="alert-box-content">
                    <b-icon
                      icon="exclamation-circle"
                      variant="info"
                    />              
                    <span class="alert-box-message">
                      No session
                    </span>
                    <span class="alert-box-details">
                      No session for the selected filters, please change
                    </span>
                  </div>
                </b-alert>
              </div>
            </template>
            <template v-else>
              <div class="col col-12">
                <section class="sessions-list pt-2">
                  <small
                    v-if="isSmallDevice === false"
                    class="text-gray ml-2 mr-auto"
                  >
                    <span class="text-white">
                      {{ sessions.sessions.count }}
                    </span> / {{ sessions.sessions.total }} Sessions
                  </small>          
                  <session-card
                    v-for="(session, index) in sessions.sessions.list"
                    :key="`${session.id}-${index}`"
                    :session="session"
                    :kd-ratio="sessions.kdRatio"
                    class="mb-4 bg-charade clickable"
                  />
                </section>
                <section
                  v-if="sessions.sessions.count < sessions.sessions.total"
                  class="show-more"
                >
                  <b-button
                    size="sm"
                    @click="showMore"
                  >
                    Show more
                  </b-button>
                </section>
              </div>
            </template>                   
          </template>
        </template>
      </template>     

      <!-- SQUAD PULL MODAL -->
      <squad-pull-modal
        :errors="errors"
      />  
    </div>    
  </section>
</template>

<style lang="scss" scoped>
  section {
    border-radius: 16px;
    margin: 0 auto; 
  }

  .update-sessions-button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 100;
  }

  .sessions-mode {
    overflow-x: auto;
  }   

  .sessions-list,
  .sessions-filters {
    max-width: 1056px;
  }  

  .switch {
    & .slider-label {
      white-space: nowrap;
      display: inline-block;
      position: absolute;
      /* left: 50%; */
      /* transform: translateX(100%); */
      left: 0;
      top: -12px;
      font-size: 8px;
      font-weight: bold;
    }
  }

  img.crown {
    width: 18px;
    position: relative;
    top: -4px;
    left: 9px;

    @media (max-width: 422px) {
      top: 18px;
      left: -24px;      
    }
  }
  
</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import Loader from '@/components/layout/Loader.vue'
import PlayerSelect from '@/components/players/PlayerSelect.vue'
import SessionCard from '@/components/sessions/SessionCard.vue'

import SquadPullModal from '@/components/squads/SquadPullModal.vue'

export default {
  name: 'SessionsList',
  components: {
    Loader,
    PlayerSelect,
    SessionCard,
    SquadPullModal
  },
  props: {
    player: {
      type: String,
      default: null
    },
    allowRefresh: {
      type: Boolean,
      default: true
    },
    allowPlayerSelection: {
      type: Boolean,
      default: true
    }     
  },
  data() {
    return {
      sessions: null,
      isLoading: false,
      isRefreshing: false,
      hasError: false,
      hasErrorUpdate: false,
      errors: null,
      perPage: 30,
      currentPage: 1,      
      filters: {
        onlyWins: false,
        onlyFull: false,
        selectedPlayer: null,
        selectedMode: null,
      }
    }
  },
  computed: mapState({
    modes: function() {
      const modes = []
      if (this.sessions === false) {
        return modes
      }
      const keys = this.sessions.sessions.modes
      keys.forEach((key) => {
        modes.push({
          id: key,
          label: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].label : key,
          order: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].order : 9999,
        })
      })

      return modes.sort((a, b) => {
        if(a.order < b.order) { return -1; }
        if(a.order > b.order) { return 1; }
        return 0;
      })
    }
  }),  
  watch: {
    playerId: function(newVal) {
      this.filters.selectedPlayer = newVal
      this.onFilterChange()
    },
  },  
  created: function() {
    this.isLoading = true
    if (this.player !== null) {
      this.filters.selectedPlayer = this.player
      this.ajaxFct = http.getPlayerSessions
      this.ajaxUpdateFct = null
    } else {
      this.ajaxFct = http.getSquadSessions
      this.ajaxUpdateFct = http.updateSquad
    }

    if (this.filters.selectedPlayer === null && this.playerId !== null) {
      this.filters.selectedPlayer = this.playerId
    }
    this.load()
  },
  mounted: function() {

  },
  methods: {
    load() {
      this.hasError = false
      const params = this.getParams()
      this.ajaxFct(params).then(
        (response) => {
          if (response.data.sessions === false) {
            this.sessions = false
          } else {
            if (this.currentPage > 1
              && this.sessions) {
              this.sessions.kdRatio = response.data.kdRatio
              this.sessions.sessions.list = [...this.sessions.sessions.list, ...response.data.sessions.list]
              this.sessions.sessions.count += response.data.sessions.count
            } else {
              this.sessions = response.data
            }

            if (this.filters.selectedMode === null) {
              this.filters.selectedMode = this.modes[0]
            }            
          }          
        }
      ).catch(
        () => {
          this.hasError = true      
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    },
    updateSquad() {
      this.isRefreshing = true
      this.hadError = false
      this.hasErrorUpdate = false

      this.ajaxUpdateFct(this.squadId ? { id: this.squadId} : null).then(
        () => {
          this.currentPage = 1
          this.load()

          if (window.scrollTo) {
            window.scrollTo(0, 0)
          }
        }
      ).catch(
        (error) => {
          this.hasErrorUpdate = true
          if (error.response.data.mustConfirm === true) {
            this.$root.$emit('bv::show::modal', 'modal-squad-pull')
          }
          this.errors = error.response.data            
        }
      ).finally(
        () => {
          this.isRefreshing = false
        }
      )
    },
    getParams() {
      let params = {
        filters: {
          onlyWins: this.filters.onlyWins,
          onlyFull: this.filters.onlyFull,
          player: (this.filters.selectedPlayer === null || this.filters.selectedPlayer === 'All Squad') ? null : this.filters.selectedPlayer,
          mode: this.filters.selectedMode ? this.filters.selectedMode.id : 'all'
        },
        startAt: (this.currentPage - 1) * this.perPage,
        limit: this.perPage
      }

      if (this.squadId !== null) {
        params.id = this.squadId
      }

      return params
    },
    onFilterChange() {
      this.currentPage = 1
      this.load()
    },
    showMore() {
      this.currentPage += 1
      this.load()
    }  
  }  
}
</script>
