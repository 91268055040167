<template>
  <div class="video-list">
    <template v-if="videos !== null">
      <div
        v-for="(video, index) in videos"
        :key="index"
        class="col col-12 col-lg-4 col-md-6 col-sm-12 col-xl-12 video p-3 mb-4"
        :class="{'is-selected': selectedVideo && selectedVideo.id === video.id}"
        @click="setSelectedVideo(video)"
      >
        <div
          class="d-flex flex-column"
        >
          <!-- <div class="media mb-4">
            <iframe :src="video.url" />
          </div>   -->
          <div class="text-left">        
            <h5>
              {{ video.metadata.title || video.id }}
            </h5>
          </div>           
          <div class="description d-flex align-items-center">
            <div v-if="video.metadata && video.metadata.type">
              <img
                v-if="video.metadata.type === 'TOP1'"
                src="@/assets/images/crown.png"
              >
              <img
                v-else-if="video.metadata.type === 'TRIAL'"
                src="@/assets/images/trial.png"
              >   
              <img
                v-else-if="video.metadata.type === 'GAMEPLAY'"
                src="@/assets/images/gameplay.png"
              >
            </div>
            <div class="text-left">
              <p>
                {{ video.metadata.description !== 'null' ? video.metadata.description : '' }}
              </p>
              <div>
                <span
                  v-for="(player, j) in video.metadata.players"
                  :key="j"
                >
                  {{ player.label }}
                </span>
              </div>
              <div class="text-right ml-auto">  
                <small>
                  {{ getVideoDate(video) }}
                </small>
              </div>                             
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>  
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

  div.video-list {
    height: calc(100vh - (79px + 40px));
    overflow-y: auto;

    @media (max-width: 1151.98px) {
      display: flex;
      height: auto;

      & div.video {
        margin-right: 16px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  div.video {
    align-content: center;
    background-color: $color-shark;
    border-radius: 16px;

    &:hover {
      background-color: $color-manatee-opacity;
      cursor: pointer;
    }

    &.is-selected {
      background-color: $color-charade;
    }

    div.media {
      width: 100%;

      & iframe {
        width: 100%;
        border: 0;
        pointer-events: none;
      }
    }

    & h5 {
      overflow-y: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }    

    div.description {
      & img {
        width: 32px;
        margin-right: 16px;
      }

      text-align: left;
    }
  }
</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'VideoList',
  components: {
    
  },
  props: {

  },
  data() {
    return {

    }
  },
  computed: mapState({
    videos: state => state.global.videos,
    selectedVideo: state => state.global.selectedVideo
  }),  
  created: function() {

  },
  mounted: function() {
    
  },  
  destroyed() {
    
  },  
  methods: {
    setSelectedVideo(video) {
      if (video) {
        this.$store.dispatch('global/setSelectedVideo', video)
      }
    },
    getVideoDate(video) {
      return this.formatDate(new Date(Number(video.metadata.timestamp)), 'dd/MM/yyyy')
    }
  }  
}
</script>
