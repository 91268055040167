<template>
  <div
    class="loader-wrapper"
    :class="fullscreen === true ? 'fullscreen' : ''"
  >
    <div class="loader">
      <div class="loader-circle" />
      <div class="loader-circle" />
      <div class="loader-circle" />
      <div class="loader-circle" />
      <div class="loader-circle" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

  @import '@/assets/style/colors.scss';

  .loader-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    &.fullscreen {
      height: 50vh;
    }

    margin: 0 auto;

    & > .loader {      
      position: absolute;
      left: calc(50% - 24px);
      top: calc(50% - 24px);
      width: 48px;
      height: 48px;
        
      & > .loader-circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: orbit 1.8s cubic-bezier(.17,.67,.47,.98) infinite;
        
        @for $i from 1 to 6 {
            &:nth-child(#{$i}) {
              z-index: 6 - $i;
              animation-delay: $i * 0.15s; 
            }
        }
        
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: calc(50% - 4px);
            width: 8px;
            height: 8px;
            border-radius: 4px;
        }
        
        &:nth-child(1):after{background: $color-yellow;}
        &:nth-child(2):after{background: $color-green;}
        &:nth-child(3):after{background: $color-blue;}
        &:nth-child(4):after{background: $color-purple;}
        &:nth-child(5):after{background: $color-pink;}
      }

      @keyframes orbit {
        0%,30% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
    }  
  }
</style>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Loader',
  components: {

  },
  props: {
    fullscreen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  computed: mapState({

  }),
  methods: {
 
  }
}
</script>
