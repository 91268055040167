<template>
  <section>
    <div class="row justify-content-center">
      <template v-if="isLoading === true">
        <loader />
      </template>

      <template v-else>
        <template v-if="hasError === true">
          <b-alert
            class="info round"
            show
          >
            <div class="alert-box-content">
              <b-icon
                icon="exclamation-circle"
                variant="danger"
              />              
              <span class="alert-box-message">
                Oops, something went wrong
              </span>
              <span class="alert-box-details">
                Unable to load the data
              </span>
            </div>
          </b-alert>
        </template>

        <template v-else>
          <div class="col col-12">
            <swiper
              v-if="showSlider === true"
              ref="slider"
              class="swiper"
              :options="swiperOption"
              @slideChange="slideChange"
            >
              <div
                v-if="isSmallDevice === true"
                slot="pagination"
                class="swiper-pagination"
              /> 

              <swiper-slide :index="1">       
                <admin-stat-card
                  :id="'requests'"
                  :title="'Requests'"
                  :value="data.total"
                  :diff="data.diff"
                  :color="'#6794dc'"
                  :selected="selected === 'requests'"
                  :selectable="true"
                  @on-click="switchChart"
                />
              </swiper-slide>
              <swiper-slide :index="2">       
                <admin-stat-card
                  :id="'requests'"
                  :title="'Success'"
                  :value="data.repartition.successes"
                  :diff="data.repartition.diff"
                  :color="'rgba(11, 177, 74, 1)'"
                  :selected="selected === 'requests'"
                  :selectable="true"                  
                  @on-click="switchChart"
                />
              </swiper-slide>               
              <swiper-slide :index="3">       
                <admin-stat-card
                  :id="'requests'"
                  :title="'Error'"
                  :value="data.repartition.errors"
                  :diff="data.repartition.diff"
                  :color="'rgba(255, 66, 69, 1)'"
                  :selected="selected === 'requests'"
                  :selectable="true"                  
                  @on-click="switchChart"
                />
              </swiper-slide> 
              <swiper-slide :index="4">       
                <admin-stat-card
                  :id="'logs'"
                  :title="'Logs'"
                  :value="data.totalRequests"
                  :color="'white'"
                  :selected="selected === 'logs'"
                  :selectable="true"                  
                  @on-click="switchChart"
                />
              </swiper-slide>                           
            </swiper>
          </div>            
          <div class="col col-12 mt-2 charts">
            <template v-if="selected === 'requests'">
              <admin-pull-chart :data="data" />
            </template>
            <template v-else>
              <admin-pull-table />
            </template>
          </div>
        </template>
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import Loader from '@/components/layout/Loader.vue'

import AdminStatCard from '@/components/admin/cards/AdminStatCard.vue'
import AdminPullChart from '@/components/admin/charts/AdminPullChart.vue'
import AdminPullTable from '@/components/admin/tables/AdminPullTable.vue'

export default {
  name: 'AdminCodApi',
  components: {
    Loader,
    AdminStatCard,
    AdminPullChart,
    AdminPullTable
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      showSlider: false,
      selected: 'requests',
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        freeMode: false,
        grabCursor: true,
        loop: false,
        loopFillGroupWithBlank: false,
        mousewheel: {
          forceToAxis: true,
          invert: true
        },
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }        
      }       
    }
  },
  computed: mapState({

  }),  
  created: function() {
    this.$root.$on('refresh-admin', () => {
      this.load()
    })

    this.load()
  },
  mounted: function() {
    this.swiperOption.slidesPerView = this.isSmallDevice === true ? 1 : 4
    this.showSlider = true
  },  
  methods: {
    load() {
      this.isLoading = true
      this.hasError = false
      http.getAdminPull().then(
        (response) => {
          this.data = response.data
        }
      ).catch(
        () => {
          this.hasError = true
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    },    
    switchChart(id) {
      this.selected = id
    },
    slideChange() {
      const index = this.$refs['slider'].$swiper.activeIndex
      const chartId = index === 3 ? 'logs' : 'requests' 
      this.switchChart(chartId)
    }
  }  
}
</script>
