<template>
  <section>
    <div class="row justify-content-md-center">
      <template v-if="isLoading === true">
        <loader />
      </template>
      <template v-else-if="hasError === true">
        An error as occured
      </template>
      <template v-else>
        <template v-if="videos.length === 0">
          <div class="col col-12 col-xl-9 col-lg-12">
            No Video
          </div>
        </template>

        <template v-else>        
          <div class="col col-12 col-xl-9 col-lg-12">
            <video-item />
          </div>
          <div class="col col-12 col-xl-3 col-lg-12">
            <video-list />
          </div>
        </template>   
      </template>   
    </div>       
  </section>
</template>

<style lang="scss" scoped>


</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex'

import Loader from '@/components/layout/Loader.vue'
import VideoList from '@/components/videos/VideoList.vue'
import VideoItem from '@/components/videos/VideoItem.vue'

export default {
  name: 'Videos',
  components: {
    Loader,
    VideoList,
    VideoItem
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      hasError: false,
    }
  },
  computed: mapState({
    videos: state => state.global.videos
  }),
  watch: {

  },
  created() {    
    this.load()
  },
  methods: {
    load() {
      this.isLoading = true
      this.hasError = false

      http.getVideos().then(
        (response) => {
          this.$store.dispatch('global/setVideos', response.data)

          if (response.data.length > 0) {
            this.setSelectedVideo(response.data)
          }

          this.isLoading = false
        }
      ).catch(
        () => {
          this.hasError = true
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    },
    setSelectedVideo(videos) {
      let video = null

      // Get from route
      const id = this.$route.query.id
      if (id !== undefined) {
        video = videos.find((v) => {
          return v.id === id
        })

        if (video) {
          // Do nothing    
        } else {
          video = videos[0]
        }
      } else {
        video = videos[0]
      }
      
      this.$store.dispatch('global/setSelectedVideo', video)
    },    
  }  
}
</script>
