import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Admin from '@/views/Admin.vue'
import Sessions from '@/views/Sessions.vue'
import Session from '@/views/Session.vue'
import Player from '@/views/Player.vue'
import Squad from '@/views/Squad.vue'
import Videos from '@/views/Videos.vue'
import Badges from '@/views/Badges.vue'
import SquadCreate from '@/components/squads/SquadCreate.vue'
import PlayerCreate from '@/components/players/PlayerCreate.vue'
import About from '@/views/About.vue'
import TermsAndConditions from '@/views/TermsAndConditions.vue'

import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: function() {
      return '/home'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },      
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter(to, from, next) {
      if (store.state.global.isAuth === true) {
        next()
      } else {
        next('/home')
      }
    }    
  },  
  {
    path: '/sessions',
    name: 'Sessions',
    component: Sessions  
  },
  {
    path: '/player',
    name: 'Player',
    component: Player
  },
  {
    path: '/squad',
    name: 'Squad',
    component: Squad    
  },  
  {
    path: '/videos',
    name: 'Videos',
    component: Videos,
    beforeEnter(to, from, next) {
      if (store.state.global.isAuth === true) {
        next()
      } else {
        next('/home')
      }
    }     
  },
  {
    path: '/squads/new',
    name: 'SquadCreate',
    component: SquadCreate
  },
  {
    path: '/players/new',
    name: 'PlayerCreate',
    component: PlayerCreate
  },   
  {
    path: '/badges',
    name: 'Badges',
    component: Badges   
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },   
  {
    path: '/terms',
    name: 'TermsAndConditions',
    component: TermsAndConditions   
  },  
  {
    path: '*',
    name: 'Any',
    redirect: '/'
  } 

]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior () {
    return { x: 0, y: 0, behavior: 'smooth' }
  }  
})

export default router
