<template>
  <section>
    <template v-if="isLoading === true">
      <loader />
    </template>   

    <template v-else>
      <template v-if="hasError === true">
        <b-alert
          class="info round"
          show
        >
          <div class="alert-box-content">
            <b-icon
              icon="exclamation-circle"
              variant="danger"
            />              
            <span class="alert-box-message">
              Oops, something went wrong
            </span>
            <span class="alert-box-details">
              Unable to load the data
            </span>
          </div>
        </b-alert>
      </template>

      <template v-else>
        <div
          v-if="summary !== null"
          class="row justify-content-md-center"
        >
          <div class="col col-12">
            <div class="bg-charade br-16">
              <div class="d-flex justify-content-center pb-0 pt-0 pr-3 pl-3">
                <div
                  class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                  :class="isSmallDevice ? 'box-sm' : ''"
                >
                  <h6>
                    K/D Ratio
                  </h6>
                  <h2 class="text-white">
                    {{ player.stats.kdRatio ? player.stats.kdRatio.toFixed(2) : '-' }}
                  </h2>
                </div>
                <div
                  class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                  :class="isSmallDevice ? 'box-sm' : ''"
                >
                  <h6>
                    Kills
                  </h6>
                  <h2 class="text-white">
                    {{ player.stats.kills }}
                  </h2>
                </div>
                <div
                  class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                  :class="isSmallDevice ? 'box-sm' : ''"
                >
                  <h6>
                    Deaths
                  </h6>
                  <h2 class="text-white">
                    {{ player.stats.deaths }}
                  </h2>
                </div>
                <div
                  class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                  :class="isSmallDevice ? 'box-sm' : ''"
                >
                  <h6>
                    Downs
                  </h6>
                  <h2 class="text-white">
                    {{ (player.stats.downs / 1000).toFixed(1) }}k
                  </h2>
                </div> 
                <div
                  class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                  :class="isSmallDevice ? 'box-sm' : ''"
                >
                  <h6>
                    SPM
                  </h6>
                  <h2 class="text-white">
                    {{ (player.stats.scorePerMinute).toFixed(2) }}
                  </h2>
                </div>                                                                                                   
              </div>
              <div class="d-flex flex-wrap justify-content-center pb-0 pt-1 pr-3 pl-3">
                <div
                  class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                  :class="isSmallDevice ? 'box-sm' : ''"
                >
                  <h6>
                    Games
                  </h6>
                  <h2 class="text-white">
                    {{ player.stats.gamesPlayed }}
                  </h2>
                </div>
                <div
                  class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                  :class="isSmallDevice ? 'box-sm' : ''"
                >
                  <img 
                    class="mb-1"
                    src="~@/assets/images/crown.png"
                  >        
                  <h2 class="text-blue">
                    {{ player.stats.wins }}
                  </h2>
                </div>
                <div
                  class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                  :class="isSmallDevice ? 'box-sm' : ''"
                >
                  <h6>
                    Top 5
                  </h6>
                  <h2 class="text-blue">
                    {{ player.stats.topFive }}
                  </h2>
                </div>
                <div
                  class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                  :class="isSmallDevice ? 'box-sm' : ''"
                >
                  <h6>
                    Top 10
                  </h6>
                  <h2 class="text-yellow">
                    {{ player.stats.topTen }}
                  </h2>
                </div> 
                <div
                  class="box d-flex flex-column align-items-center p-3 bg-woodsmoke"
                  :class="isSmallDevice ? 'box-sm' : ''"
                >
                  <h6>
                    Top 25
                  </h6>
                  <h2 class="text-white">
                    {{ player.stats.topTwentyFive }}
                  </h2>
                </div>
              </div>
            </div>
          </div>  
          <div class="col col-12 p-2">
            <small
              v-b-tooltip.hover
              class="text-gray"
              title="Modes such as Plunder and others are not taken in consideration... no one really cares..."
            >
              <b-icon
                icon="info-circle"
                class="ml-2"
              /> All the stats are for BR modes only
            </small> 
          </div>
          <div class="col col-12 pt-3">
            <b-tabs
              content-class="mt-3"
            >
              <b-tab
                title="Last 24h"
                active
                lazy
              >
                <player-summary-stats
                  :stats="summary.last24h"
                  :player="player"
                />
              </b-tab>
              <b-tab
                title="Last 20"
                lazy
              >
                <player-summary-stats
                  :stats="summary.last20Games"
                  :player="player"
                />
              </b-tab> 
              <b-tab
                title="This week"
                lazy
              >
                <player-summary-stats
                  :stats="summary.weekly"
                  :player="player"
                />
              </b-tab>
              <b-tab
                title="This month"
                lazy
              >
                <player-summary-stats
                  :stats="summary.monthly"
                  :player="player"
                />
              </b-tab>             
            </b-tabs>
          </div>
        </div>
      </template>     
    </template>              
  </section> 
</template>

<style lang="scss" scoped>
  @import '~@/assets/style/colors.scss';

</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex'

import loader from '@/components/layout/Loader.vue'

import PlayerSummaryStats from '@/components/players/PlayerSummaryStats.vue'

export default {
  name: 'PlayerSummary',
  components: {
    loader, 
    PlayerSummaryStats
  },
  props: {
    player: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      summary: null,
    }
  },
  computed: mapState({

  }),  
  created: function() {
    this.load()
  },
  mounted: function() {

  },
  methods: {
    load() {
      this.isLoading = true

      http.getPlayerSummary({
        player: this.player.username
      }).then(
        (response) => {
          this.summary = response.data
        }
      ).catch(
        () => {
          this.hasError = true
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    }
  }  
}
</script>
