<template>
  <section>
    <template v-if="isLoading === true">
      <div class="row justify-content-center p-2">
        <div class="col col-12">
          <h4 class="mb-4">
            Data processing...
          </h4> 
          <small class="text-gray">
            A bit of patience Verdanskien, your sessions are being compiled...
          </small>
          
          <loader />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row justify-content-center p-2">
        <div class="col col-12">
          <h4 class="mb-4">
            Your Squad
          </h4> 

          <b-alert
            v-if="hasError === false"
            class="info round"
            show
          >
            <div class="alert-box-content">
              <b-icon
                icon="exclamation-circle"
                variant="warning"
              />              
              <span class="alert-box-message">
                Did you check your profile Privacy?
              </span>
              <span class="alert-box-details">
                Your data must be visible<br>
                <a
                  v-b-modal.modal-profile-privacy
                  class="text-white"
                >Check how</a>
              </span>
            </div>
          </b-alert>
          <b-alert
            v-else-if="hasError === true && hasNotSharedGames === false"
            class="info round"
            show
          >
            <div class="alert-box-content">
              <b-icon
                icon="exclamation-circle"
                variant="danger"
              />              
              <span class="alert-box-message">
                Your Activision profile is PRIVATE?            
              </span>
              <span class="alert-box-details">
                Some Players are private or mistyped!              
                <div
                  v-for="error in Object.keys(errors)"
                  :key="error"
                  class="mt-2"
                >
                  <strong class="text-white"> {{ error }}</strong>
                  <p class="text-gray">
                    {{ errors[error] }}
                  </p>
                </div>

                <a
                  v-b-modal.modal-profile-privacy
                  class="text-white"
                >Check how to open your <b>Stats</b></a>
              </span>
            </div>
          </b-alert>  
          <b-alert
            v-else-if="hasError === true && hasNotSharedGames === true"
            class="info round"
            show
          >
            <div class="alert-box-content">
              <b-icon
                icon="exclamation-circle"
                variant="danger"
              />              
              <span class="alert-box-message">
                Hum... it sounds like you didn't share any games between you!
              </span>
              <span class="alert-box-details">
                The Quantic Computer was not able to find any game you have shared.<br>
                <br>
                <div
                  v-for="player in errors.players"
                  :key="player.username"
                >
                  {{ player.username }}
                </div>
                <br>
                Please choose players who has participated to the same games.
              </span>
            </div>
          </b-alert> 
          <b-form 
            id="players-form"
            ref="players-form"        
            class="pt-4 mt-3 ml-auto mr-auto"
          >
            <div
              v-for="(player, index) in form"
              :key="index"
              class="d-flex align-items-center justify-content-center"
            >
              <b-form-group
                :id="`input-group-player-${index}`"
                class="floating-labels input-player"
                :class="{'mb-0': index === 3}"
              >
                <b-form-input
                  :id="`input-player-username-${index}`"
                  :ref="`input-player-username-${index}`"
                  v-model="player.username"
                  type="text"
                  trim
                  :placeholder="`Player #${index + 1}`"
                  :state="validateState(index, player)"
                  :aria-describedby="`feedback-player-username-${index}`"
                />
                <label :for="`input-player-username-${index}`">
                  Player #{{ index + 1 }}
                </label>             
              </b-form-group> 

              <b-form-group
                id="input-group-player"
                class="floating-labels ml-2"
                :class="{'mb-0': index === 3}"
              >
                <multiselect
                  :id="`input-player-platform-${index}`"
                  v-model="player.platform"
                  :placeholder="''"
                  :preselect-first="true"
                  :multiple="false"
                  :label="'label'"
                  :close-on-select="true"
                  :clear-on-select="false"              
                  :options="platforms"
                  :track-by="'id'"
                  :searchable="false"
                  :allow-empty="false"
                  @select="onPlatformChange(player)"
                >
                  <template
                    slot="singleLabel"
                    slot-scope="{ option }"
                  >
                    {{ option.id }}
                  </template>             
                </multiselect>            
              </b-form-group>                    
            </div>       

            <small>
              <a
                v-b-modal.modal-profile-username
                class="text-gray"
              >How do I find my username?</a>
            </small>  

            <div class="p-2 mt-2">         
              <b-button
                variant="primary"
                size="sm"
                type="submit"
                @click="submit"
              >
                Go!
              </b-button>
            </div>                             
          </b-form>       
        </div>
      </div>
      <div class="row justify-content-center p-2">
        <div class="col col-12">
          <squad-select
            :redirect-to="true"
            :allow-fav="false"
            :allow-share="false"
            :go-button="true"
          />
        </div>
      </div> 
    </template> 

    <squad-count-modal />                  
  </section>
</template>

<style lang="scss" scoped>
  section {
    margin: 0 auto;
    max-width: 860px;
    border-radius: 16px; 
  }

  /deep/ form#players-form .multiselect {
    min-width: 100px;
    width: 100px;

    & > .multiselect__content-wrapper {
      min-width: 200px;
      transform: translateX(-50%)
    }
  }

  /deep/ fieldset.input-player {
    @media (min-width: 422px) {
      width: 100%;
    }
  }
</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex'
import { validationMixin } from "vuelidate"

import loader from '@/components/layout/Loader.vue'

import SquadSelect from '@/components/squads/SquadSelect.vue'
import SquadCountModal from '@/components/squads/SquadCountModal.vue'

export default {
  name: 'SquadCreate',
  components: {
    loader,
    SquadSelect,
    SquadCountModal
  },
  mixins: [validationMixin],
  props: {
   
  },
  data() {
    return {
      isLoading: false,
      hasFormError: false,
      hasError: false,
      hasNotSharedGames: false,
      errors: null,
      platforms: [
        {
          id: 'acti',
          label: 'Activision ID'
        },        
        {
          id: 'psn',
          label: 'Playstation'
        },
        {
          id: 'steam',
          label: 'Steam'
        },
        {
          id: 'xbl',
          label: 'Xbox Live'
        },
        {
          id: 'battle',
          label: 'Battle.net'
        }        
      ],
      form: [
        {
          username: null,
          platform: null
        },
        {
          username: null,
          platform: null         
        },
        {
          username: null,
          platform: null      
        },
        {
          username: null,
          platform: null         
        }        
      ]
    }
  },
  computed: mapState({

  }),
  watch: {

  },
  created() {

  },
  methods: {
    validateState(index, player) {
      if (player.username === null) {
        return true
      }
      if (this.errors !== null && this.errors[player.username] !== undefined) {
        return false
      }
      // Check if player already defined
      return player.username !== null
        && this.isUniq(index, player)

    },      
    validateForm: function() {
      // Check if players are correct
      let valids = 0
      this.form.forEach(
        (player, index) => {
          if (player.username !== null) {
            if (this.validateState(index, player) === true) {
              valids += 1
            }
          }
        }
      )

      if (valids < 2) {
        this.$root.$emit('bv::show::modal', 'modal-squad-count')
      }

      return valids >= 2

    },
    isUniq(index, player) {
      return this.form.findIndex(
        (p, i) => {
          return i !== index && p.username === player.username
        }
      ) < 0
    },
    onPlatformChange(player) {
      if (this.errors
        && this.errors[player.username]) {
          delete this.errors[player.username]
        }
    },
    submit(e) {
      if (this.validateForm() === false) {
        return false
      }

      this.onPlayersSubmit()

      e.preventDefault()
      e.stopPropagation()

      return false
    },
    onPlayersSubmit() {
      
      this.isLoading = true

      this.hasNotSharedGames = false

      http.createSquad(this.form).then(
        (response) => {
          let squadIds = null
          try {
            squadIds = JSON.parse(window.localStorage.getItem('favoriteSquadIds'))  
            if (squadIds === null) {
              squadIds = [response.data.squadId]
            } else {
              squadIds.push(response.data.squadId)
            }
          } catch (e) {
            // Do nothing
            squadIds = [response.data.squadId]
          }

          window.localStorage.setItem('favoriteSquadIds', JSON.stringify(squadIds))
          window.localStorage.setItem('selectedSquadId', response.data.squadId)

          this.$store.dispatch('global/setSquadId', response.data.squadId)

          // Get session id
          this.$router.push({ name: 'Sessions', query: { id: response.data.squadId } })
        }
      ).catch(
        (error) => {
          if (error.response.data.error === 'NO_GAMES_FOR_PLAYERS') {
            this.hasError = true
            this.hasNotSharedGames = true
          } else {
            this.hasError = true
          } 
          
          this.errors = error.response.data
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    }      
  }
}
</script>
