<template>
  <section>
    <b-navbar
      id="topbar"
      toggleable="md"
      type="dark"
      fixed="top"
      class="bg-black"
      :class="{ 'hidden': !showNavbar }"
    >
      <b-navbar-brand href="#">
        <router-link
          to="/"
          class="navbar-brand" 
        >
          <img src="@/assets/images/logo.png">
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse
        v-if="isInitialized === true"
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav>  
          <!-- IS AUTHENTICATED -->
          <template v-if="squadId !== null">
            <b-nav-item>
              <router-link :to="{ name: 'Sessions', query: { id: squadId, username: playerId }}">
                Sessions
              </router-link>
            </b-nav-item>
            <b-nav-item class="divider" />
            <b-nav-item>
              <router-link :to="{ name: 'Squad', query: { id: squadId, username: playerId }}">
                Squad
              </router-link>
            </b-nav-item>
            <b-nav-item class="divider" />               
          </template>      
          <template v-if="squadId !== null || playerId !== null">
            <b-nav-item>
              <router-link :to="{ name: 'Player', query: { id: squadId, username: playerId }}">
                {{ playerId ? playerId : 'Players' }}
              </router-link>
            </b-nav-item>
            <b-nav-item class="divider" /> 
          </template>           
          <b-nav-item>
            <router-link to="/badges">        
              Badges
            </router-link>
          </b-nav-item>                     
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <template v-if="isAuth === true">
            <b-nav-item>
              <router-link to="/videos">        
                Videos
              </router-link>
            </b-nav-item>            
            <b-nav-item class="divider d-block d-lg-none" />   
            <b-button
              size="sm"
              variant="primary"
              class="mb-2"
              v-b-modal.modal-upload
            >
              <b-icon
                icon="camera-reels"
              />
            </b-button> 

            <b-nav-item
              class="divider"
            />
            <b-nav-item>
              <router-link to="/admin">        
                Admin
              </router-link>
            </b-nav-item>        
            <b-nav-item class="divider" />          
            <b-nav-item>
              <a @click="logout">        
                Logout
              </a>
            </b-nav-item> 
          </template> 
          <template v-else>
            <b-nav-item>
              <router-link to="/about">        
                About
              </router-link>
            </b-nav-item>             
            <b-nav-item class="divider" /> 
            <b-nav-item>
              <a @click="login">        
                I am a Rotaldoz!
              </a>
            </b-nav-item> 
          </template>                       
        </b-navbar-nav>
      </b-collapse>    
    </b-navbar>

    <!-- BUTTON SCROLL TOP -->
    <div
      v-if="lastScrollPosition > 400"
      class="scroll-top-button"
    >
      <b-button
        variant="secondary"
        size="sm"
        class="p-2"
        @click="scrollTop"
      >
        <b-icon
          icon="arrow-bar-up"
        />
      </b-button>
    </div>     
  </section>
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

  #topbar {
    padding: 16px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    align-items: center;
    transform: translate3d(0, 0, 0);
    transition: 0.1s all ease-out; 
    
    &.hidden {
      box-shadow: none;
      transform: translate3d(0, -100%, 0)      
    }

    @media (max-width: 420.89px) {
      padding: 8px;
    }


    & img {
      height: 48px;

      @media (max-width: 420.89px) {
        height: 32px;
      }
    }

    & .nav-item.divider {
      width: 1px;
      height: auto;
      background-color: rgba(255, 255, 255, 0.2);
      margin-left: 8px;
      margin-right: 8px;

      @media (max-width: 640px) {
        height: 1px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        margin-top: 8px;
        margin-bottom: 8px;        
      }
    }

    & .nav-link {

      & > a {
        color: $color-manatee;
        transition: 0.2s;

        &.router-link-exact-active {
          color: $color-yellow;
        }

        &:hover {
          color: $color-white;
          text-decoration: none;
        }
      }
    }
  }

  .scroll-top-button {
    position: fixed;
    bottom: 24px;
    left: 24px;
    z-index: 100;
  }  
</style>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Topbar',
  components: {
    
  },
  props: {

  },
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0
    }
  },  
  computed: mapState({
    isInitialized: state => state.global.isInitialized
  }),
  created() {
    
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  detroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    login() {
      this.openAuthModal('/Sessions')
    },
    logout() {
      this.$store.dispatch('global/setAuth', false)
      this.$store.dispatch('global/unsetAuthCredentials')

      window.localStorage.removeItem('user') 
      
      window.location.reload()
    },
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    },
    scrollTop() {
      window.scrollTo(0, 0)
    }    
  }  
}
</script>
