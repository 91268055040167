<template>
  <section>
    <template v-if="isLoading === true">
      <loader />
    </template>

    <template v-else>
      <template v-if="hasError === true">
        <b-alert
          class="info round"
          show
        >
          <div class="alert-box-content">
            <b-icon
              icon="exclamation-circle"
              variant="danger"
            />              
            <span class="alert-box-message">
              Oops, something went wrong
            </span>
            <span class="alert-box-details">
              Unable to load the data
            </span>
          </div>
        </b-alert>
      </template>
      <template v-else-if="selectedMode !== null">   
        <!-- PLAYERS LIST -->
        <div class="row justify-content-md-center">
          <div class="col col-12 pb-3">
            <b-tabs
              content-class="mt-3"
            >
              <b-tab
                title="Last 24h"
                lazy
                @click="setSelectedPeriod('last24h')"
              />
              <b-tab
                title="Last 20"
                lazy
                @click="setSelectedPeriod('last20Games')"
              />
              <b-tab
                title="This week"
                active
                lazy
                @click="setSelectedPeriod('weekly')"
              />
              <b-tab
                title="This month"
                lazy
                @click="setSelectedPeriod('monthly')"
              />                            
            </b-tabs>
          </div>
          <div class="col col-12 col-lg-6 col-xl-4">
            <div class="d-flex align-items-center">
              <b-form-group
                id="input-group-mode"
                class="floating-labels flex-grow-1 mb-0"
              >
                <multiselect
                  id="input-mode"
                  v-model="selectedMode"
                  :placeholder="'Select a mode'"
                  :multiple="false"
                  :label="'label'"
                  :close-on-select="true"
                  :clear-on-select="false"              
                  :options="modes"
                  :track-by="'id'"
                  :searchable="true"
                  :allow-empty="false"
                >
                  <template
                    slot="singleLabel"
                    slot-scope="{ option }"
                  >
                    {{ option.label }}
                  </template>             
                </multiselect>
                <label
                  v-if="selectedMode"
                  class="is-floating"
                  for="input-mode"
                >
                  Mode
                </label>             
              </b-form-group>
            </div>
          </div>

          <div
            v-for="player in players"
            :key="player.id"
            class="player-summary col col-12 d-flex flex-column"
          >
            <squad-player-summary
              :key="`${selectedMode.id}-${selectedPeriod}`"
              :player="player"
              :selected-mode="selectedMode"
              :selected-period="selectedPeriod"
              class="mt-0"
            />
          </div>
        </div>
      </template>
    </template>
  </section>
</template>

<style lang="scss" scoped>
  section {
    margin: 0 auto;
    max-width: 1056px;
    border-radius: 16px;
  }

  /deep/ .tabs > div:not(.tab-content) > ul.nav-tabs {
    @media (max-width: 421px) {
      overflow-y: auto;
      width: calc(100vw - 40px);
      flex-wrap: nowrap;
      display: flex;  
      padding-bottom: 6px;
      
      & li:first-child {
        margin-left: auto;
      }

      & li:last-child {
        margin-right: auto;
      }
    }    
  }  

  /deep/ .tab-content {
    display: none;
  }

  /deep/ div.player-summary > section .col.pt-3 {
    padding-top: 0px important;
  }
</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import loader from '@/components/layout/Loader.vue'
import SquadPlayerSummary from '@/components/squads/SquadPlayerSummary.vue'

export default {
  name: 'SquadPlayers',
  components: {
    loader,
    SquadPlayerSummary,
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      error: null,
      selectedMode: null,
      selectedPeriod: 'weekly',
    }
  },
  computed: mapState({
    modes: function() {
      const modes = []
      const keys = ['all', 'br_brquads', 'br_brtrios', 'br_brduos', 'br_brsolo']
      keys.forEach((key) => {
        modes.push({
          id: key,
          label: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].label : key,
          order: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].order : 9999,
        })
      })

      return modes.sort((a, b) => {
        if(a.order < b.order) { return -1; }
        if(a.order > b.order) { return 1; }
        return 0;
      })
    },    
  }),  
  created: function() {
    if (this.selectedMode === null) {
      this.selectedMode = this.modes[0]
    }

    this.load()
  },
  mounted: function() {

  },  
  methods: {
    load() {
      this.isLoading = true

      const params = {
        id: this.squadId
      }

      http.getSquadSummary(params).then(
        (response) => {
          this.players = response.data
        }
      ).catch(
        (error) => {
          this.hasError = true
          this.error = error.response.data
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    },
    setSelectedPeriod(period) {
      this.selectedPeriod = period
    }
  }  
}
</script>
