<template>
  <section>
    <div class="row justify-content-center justify-content-center">
      <div class="col col-12">
        <div
          v-for="(badge, index) in badgesPerRarity"
          :key="index"          
          :index="index"
          class="badge-row pt-2 pb-2 clickable"
          @click="openModal(badge)"
        >
          <div class="d-flex align-items-center">
            <div
              class="badge-logo"
            >           
              <div
                class="reward"
                :class="badge.god ? 'god' : (badge.special ? 'special' : 'legendary')"
              >
                <div class="circle" />
                <img  
                  width="120"
                  :src="badge.logo"
                >
              </div>  

              <b-badge
                v-if="badge.special === true"
                variant="default"
                size="sm"
                class="d-block badge-special"
              >
                SPECIAL
              </b-badge> 
              <b-badge
                v-else-if="badge.god === true"
                variant="dark"
                size="sm"
                class="d-block badge-special"
              >
                GOD
              </b-badge>                                         
            </div> 

            <div class="d-flex flex-column text-left align-items-baseline">
              <div class="p-1">
                <h6 class="color-white">
                  {{ badge.title }}
                </h6>
                <p class="badge-description">
                  {{ badge.description }}
                </p>
              </div>
              <small class="text-gray">
                {{ badge.keyStats }}
              </small>
              <b-badge
                v-if="badge.comingSoon === true"
                variant="primary"
                size="sm"
                class="d-block badge-special"
              >
                Coming Soon
              </b-badge>               
            </div>
          </div>             
        </div> 
      </div> 
    </div>   

    <badge-modal
      v-if="showModal"
      :badge="selectedBadge"
    />    
  </section>
</template>

<style lang="scss" scoped>

  @import '~@/assets/style/colors.scss';

  section {
    max-width: 860px;
    margin: 0 auto;    
  }

  .badge-row {
    border-bottom: 4px solid $color-manatee-opacity;
  }

  p.badge-description {
    font-size: 12px;
  }

  .badge-special {
    margin-top: -16px;
  }

  small {
    font-size: 10px;
  }
</style>

<script>

import { mapState } from 'vuex'

import badgeModal from '@/components/badges/BadgeModal.vue'

export default {
  name: 'Badges',
  components: {
    badgeModal
  },
  props: {

  },
  data() {
    return {
      selectedBadge: null,
      showModal: false
    }
  },
  computed: mapState({
    badgesPerRarity: function() {
      return Object.values(this.mapping.badgesMapping).sort(
        (a, b) => {
          if (b.type > a.type) {
            return 1
          } else {
            return -1
          }
        }
      )
    }
  }),
  watch: {

  },
  created() {
    this.showModal = true
  },
  destroyed() {
    this.showModal = false
  },
  methods: {
    openModal(badge) {
      this.selectedBadge = badge
      this.$root.$emit('bv::show::modal', 'modal-badge')
    }
  }  
}
</script>
