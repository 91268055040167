<template>
  <div class="row justify-content-center bg-black">
    <div class="col col-12 p-4">
      <div class="p-1">
        <hr>
        <img
          width="100"
          src="@/assets/images/logo.png"
        >
        <hr>
      </div>      
      <div class="text-gray">
        <small>Activision has not endorsed and is not responsible for this site or its content.</small>
      </div>
      <div class="p-1">
        <router-link to="/terms">
          <small class="text-white">Terms & Conditions</small>
        </router-link>
      </div>   
      <div>   
        <small class="text-gray">
          Because we truly respect your privacy!<br>
          This website does not use any Cookie or any Analytics Tools and does not attend to generate any profit out of it.
        </small>
      </div>

      <div class="pt-2">
        <small>
          <router-link 
            to="/about"
            class="text-white"
          >
            Contact the Rotaldoz!
          </router-link>
        </small>      
      </div> 

      <div class="pt-2">
        <small>
          <a 
            href="https://www.paypal.com/paypalme/rtld0?locale.x=en_US"
            target="_blank"
            class="text-gray"
          >
            Buy us a <img
              src="@/assets/images/beer.png" 
              width="24"
              class="beer"
            >
          </a>
        </small>      
      </div>      
      
      <div class="p-4">
        <small class="copyright">
          Mad Bison Software &#169; {{ year }}
        </small>      
      </div>     
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

  div.row {
    border-top: 1px solid $color-shark;
  }

  .beer {
    width: 24px;
  }

</style>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Footer',
  components: {
    
  },
  props: {

  },
  data() {
    return {

    }
  },  
  computed: mapState({
    year: function() {
      const date = new Date()
      return date.getFullYear()
    }
  }),
  methods: {

  }  
}
</script>
