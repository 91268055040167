<template>
  <section class="pt-1 pb-1">
    <template v-if="isLoading === true">
      <loader />
    </template>
    <template v-else>
      <session-games-evolution-chart
        :stats-key="'kdRatio'"
        :data="games.evolution"
        :plot-line="sessionKdRatio"
      />

      <swiper
        class="swiper"
        :options="swiperOption"
      >
        <div
          slot="pagination"
          class="swiper-pagination"
        />        
        <swiper-slide
          v-for="(game, index) in games.list"
          :key="index + 1"
          :index="index + 1"
        >
          <session-game-card
            :game="game"
            :kd-ratio="kdRatio"
            :modal="true"
          />
        </swiper-slide>
      </swiper>
    </template>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

  .list {

    background-color: $color-woodsmoke;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    & > .header {
      border-bottom: 2px solid $color-shark;
    }

    ul {
      padding-left: 0;

      & > li {
        padding: 16px;
      }
    }
  }

  img.sbmm-logo {
    filter: grayscale(100%);
    transition: all 0.2s;

    &:hover {
      filter: none;
    }
  }  
</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import Loader from '@/components/layout/Loader.vue'

import SessionGameCard from '@/components/sessions/SessionGameCard.vue'
import SessionGamesEvolutionChart from '@/components/sessions/SessionGamesEvolutionChart.vue'

export default {
  name: 'SessionGames',
  components: {
    Loader,
    SessionGamesEvolutionChart,
    SessionGameCard
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    kdRatio: {
      type: Number,
      default: null
    },
    sessionKdRatio: {
      type: Number,
      default: null      
    }  
  },
  data() {
    return {
      isLoading: false,
      games: null,
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        freeMode: false,
        grabCursor: true,
        loop: false,
        loopFillGroupWithBlank: false,
        mousewheel: {
          forceToAxis: true,
          invert: true
        },
        autoHeight: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }  
      },
      ajaxFct: null      
    }
  },
  computed: mapState({

  }),  
  created: function() {
    this.loadGames()
  },
  mounted: function() {

  },  
  methods: {
    loadGames() {
      this.isLoading = true
      let params = { session: this.session.id ? this.session.id : this.session.label }
      if (this.squadId) {
        params.id = this.squadId
      }
      http.getSessionGames(params)
        .then(
          (response) => {
            this.games = response.data
          }
        )
        .catch(
          () => {
            // Do mothing
          }
        ).finally(
          () => {
            this.isLoading = false
          }
        )      
    }
  }  
}
</script>
