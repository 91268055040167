<template>
  <section>
    <template v-if="isLoading === true">
      <loader />
    </template>   

    <template v-else>
      <template v-if="hasError === true">
        <b-alert
          class="info round"
          show
        >
          <div class="alert-box-content">
            <b-icon
              icon="exclamation-circle"
              variant="danger"
            />              
            <span class="alert-box-message">
              Oops, something went wrong
            </span>
            <span class="alert-box-details">
              Unable to load the data
            </span>
          </div>
        </b-alert>
      </template>

      <template v-else>
        <div
          v-if="summary !== null
            && summary[selectedPeriod]
            && summary[selectedPeriod].stats[selectedMode.id]"
          class="row justify-content-md-center"
        >
          <div class="col col-12 pt-3">
            <div class="d-flex align-items-center">
              <small class="mr-2">         
                <b-badge
                  :variant="player.status.isUpdated === true ? 'success' : 'danger'"
                  class="player-status"
                  size="lg"
                />                
              </small>                
              <h6 class="mb-0 mr-auto">
                {{ player.username }}              
              </h6>
              <small class="ml-auto">
                <small class="text-gray last-update">
                  <span>{{ lastUpdate }}</span>         
                </small>             
              </small>              
            </div>
            <player-summary-stats      
              :stats="summary[selectedPeriod]"
              :player="player"
              :allow-mode-selection="false"
              :selected-mode-parent="selectedMode"
            />
          </div>
        </div>
      </template>     
    </template>              
  </section> 
</template>

<style lang="scss" scoped>
  @import '~@/assets/style/colors.scss';

  .badge.player-status {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: inline-block;
    position: rel;
  }  

  .last-update {
    line-height: 14px;
  }

</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex'

import loader from '@/components/layout/Loader.vue'

import PlayerSummaryStats from '@/components/players/PlayerSummaryStats.vue'

export default {
  name: 'SquadPlayerSummary',
  components: {
    loader, 
    PlayerSummaryStats
  },
  props: {   
    player: {
      type: Object,
      required: true
    },
    selectedMode: {
      type: Object,
      required: true
    },
    selectedPeriod: {
      type: String,
      required: true
    }       
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      summary: null,
    }
  },
  computed: mapState({
    lastUpdate() {
      return this.formatDate(new Date(this.player.status.lastUpdate), 'dd/MM/yyyy hh:mm')
    }
  }),  
  created: function() {
    this.load()
  },
  mounted: function() {

  },
  methods: {
    load() {
      this.isLoading = true

      http.getPlayerSummary({
        player: this.player.username
      }).then(
        (response) => {
          this.summary = response.data
        }
      ).catch(
        () => {
          this.hasError = true
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    }
  }  
}
</script>
