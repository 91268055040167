<template>
  <section>
    <div class="row">
      <div class="col col-12 text-left">
        <h4 class="text-center">
          Terms and Conditions for {{ companyName }}
        </h4>
        <hr>
        <h5 class="text-center text-white">
          Introduction
        </h5>
        <p>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, {{ websiteName }} accessible at https://rtld0.madbison.net.</p>
        <p>These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.</p>
        <p>Minors or people below 18 years old are not allowed to use this Website.</p>
        <h5 class="text-center text-white">
          Intellectual Property Rights
        </h5>
        <p>Other than the content you own, under these Terms, {{ companyName }} and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>
        <p>You are granted limited license only for purposes of viewing the material contained on this Website.</p>
        <h5 class="text-center text-white">
          Restrictions
        </h5>
        <p>
          You are specifically restricted from all of the following:
          <ul>
            <li>publishing any Website material in any other media;</li>
            <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
            <li>publicly performing and/or showing any Website material;</li>
            <li>using this Website in any way that is or may be damaging to this Website;</li>
            <li>using this Website in any way that impacts user access to this Website;</li>
            <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
            <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
            <li>using this Website to engage in any advertising or marketing.</li>
          </ul>
        </p>
        <p>Certain areas of this Website are restricted from being access by you and {{ companyName }} may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.</p>
        <h5 class="text-center text-white">
          Your Content
        </h5>
        <p>In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant {{ companyName }} a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>
        <p>Your Content must be your own and must not be invading any third-party's rights. {{ companyName }} reserves the right to remove any of Your Content from this Website at any time without notice.</p>
        <h5 class="text-center text-white">
          No warranties
        </h5>
        <p>This Website is provided “as is,” with all faults, and {{ companyName }} express no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.</p>
        <h5 class="text-center text-white">
          Limitation of liability
        </h5>
        <p>In no event shall {{ companyName }}, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.  {{ companyName }}, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>
        <h5 class="text-center text-white">
          Indemnification
        </h5>
        <p>You hereby indemnify to the fullest extent {{ companyName }} from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
        <h5 class="text-center text-white">
          Severability
        </h5>
        <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
        <h5 class="text-center text-white">
          Variation of Terms
        </h5>
        <p>{{ companyName }} is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.</p>
        <h5 class="text-center text-white">
          Assignment
        </h5>
        <p>The {{ companyName }} is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
        <h5 class="text-center text-white">
          Entire Agreement
        </h5>
        <p>These Terms constitute the entire agreement between {{ companyName }} and you in relation to your use of this Website, and supersede all prior agreements and understandings.</p>
        <h5 class="text-center text-white">
          Governing Law & Jurisdiction
        </h5>
        <p>These Terms will be governed by and interpreted in accordance with the laws of the State of Country, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Country for the resolution of any disputes.</p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

  p {
    color: $color-manatee;
  }

</style>

<script>
import { mapState } from 'vuex'



export default {
  name: 'TermsAndConditions',
  components: {
    
  },
  props: {

  },
  data() {
    return {
      companyName: 'Mad Bison Software',
      websiteName: '[RTLD0] Ultimate Team'
    }
  },
  computed: mapState({
    
  }),
  watch: {

  },
  created() {

  },
  methods: {

  }
}
</script>
