<template>
  <div id="app">
    <topbar />       

    <template v-if="isInitialized === false">
      <loader />
    </template>
    <template v-else>   
      <div class="container">
        <transition
          name="fade"
          mode="out-in"
        >
          <router-view />
        </transition>
      </div>

      <!-- MODALS -->
      <upload-modal />
      <auth-modal />      
      <profile-privacy-modal />
      <profile-username-modal />      
    </template>

    <video
      id="video-background"
      autoplay
      muted
    >
      <source
        src="@/assets/media/intro.mp4"
        type="video/mp4"
      >
    </video> 

    <Footer />   
  </div>
</template>

<style lang="scss">

  video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    z-index: -1;
    opacity: 0.05; 
    object-fit: cover; 
  }

</style>

<script>

import { mapState } from 'vuex'

// Components
import Loader from '@/components/layout/Loader.vue'
import Topbar from '@/components/layout/Topbar.vue'
import Footer from '@/components/layout/Footer.vue'
import UploadModal from '@/components/upload/UploadModal.vue'
import AuthModal from '@/components/auth/AuthModal.vue'
import ProfilePrivacyModal from '@/components/help/ProfilePrivacyModal.vue'
import ProfileUsernameModal from '@/components/help/ProfileUsernameModal.vue'

export default {
  name: 'App',
  components: {
    Loader,
    Topbar,
    Footer,
    UploadModal,
    AuthModal,
    ProfilePrivacyModal,
    ProfileUsernameModal    
  },
  data: () => {
    return {
      
    }
  },
  computed: mapState({
    isInitialized: state => state.global.isInitialized,
    requestAuth: state => state.global.requestAuth,
    toasts: state => state.global.toasts
  }),
  watch: {
    requestAuth: function(newVal) {
      if (newVal === true) {
        this.openAuthModal()
      }
    },
    squadId: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        // Update route params
        if (history.pushState) {
          let searchParams = new URLSearchParams(window.location.search);
          searchParams.set('id', newVal);
          searchParams.set('username', null);
          let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
          window.history.pushState({path: newurl}, '', newurl);
        }
      }       
    },
    playerId: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        // Update route params
        if (history.pushState) {
          let searchParams = new URLSearchParams(window.location.search);
          searchParams.set('username', newVal);
          let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
          window.history.pushState({path: newurl}, '', newurl);
        }
      }       
    },         
    toasts: function(newVal) {
      newVal.forEach((toast, index) => {
        if (toast.displayed === false) {
          toast.displayed = true
          this.$bvToast.toast(toast.message, {
            title: toast.title,
            variant: toast.type,
            solid: true
          })

          setTimeout(() => {
            this.$store.dispatch('global/remToast', index)
          }, 3000)
        }
      })
    }
  },
  created() {

  },
  mounted() {
    document.getElementById('video-background').addEventListener('ended', myHandler, false);
    function myHandler() {
        setTimeout(function(){
          document.getElementById('video-background').play();
        }, 15000);
    }
  },
  methods: {

  }
}
</script>
