<template>
  <div class="video-item">
    <template v-if="selectedVideo !== null">
      <div class="video d-flex flex-column">
        <div
          id="video-header"
          ref="video-header"
          class="d-flex align-items-center text-left p-3"
        >
          <img
            v-if="selectedVideo.metadata.type === 'TOP1'"
            src="@/assets/images/crown.png"
          >
          <img
            v-else-if="selectedVideo.metadata.type === 'TRIAL'"
            src="@/assets/images/trial.png"
          >   
          <img
            v-else-if="selectedVideo.metadata.type === 'GAMEPLAY'"
            src="@/assets/images/gameplay.png"
          >
          <div class="d-flex align-items-center flex-grow-1">
            <h5 class="mb-0">
              {{ selectedVideo.metadata.title }}
            </h5>
            <input
              id="video-link"
              type="text"
              readonly="readonly"
              :value="videoLink"
            >
            <b-button
              id="copy-link"
              size="sm"
              class="ml-auto"
              @click="copyLink()"
            >
              Copy link
            </b-button> 
          </div>
        </div>               
        <div
          id="video-media"
          ref="video-media"
          class="media"
        >
          <iframe
            id="video-iframe"
            ref="video-iframe"       
            allowfullscreen   
            :src="selectedVideo.url"
          />
        </div>          
        <div
          id="video-footer"
          ref="video-footer"
          class="text-left p-3"
        >
          <p>
            {{ selectedVideo.metadata.description !== 'null' ? selectedVideo.metadata.description : '' }}
          </p>
          <div>
            <span
              v-for="(player, j) in selectedVideo.metadata.players"
              :key="j"
            >
              {{ player.label }}
            </span>
          </div> 
          <div class="text-right">  
            <small>
              {{ getVideoDate(selectedVideo) }}
            </small>
          </div>           
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>

  @import '@/assets/style/colors.scss';

  input#video-link {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -9999;
  }  

  div.video {
    align-content: center;
    background-color: $color-charade;
    border-radius: 16px;
    
    @media (max-width: 1151.98px) {
      margin-bottom: 24px;
    }

    & iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }

    & img {
      width: 32px;
      margin-right: 16px;
    }

    & h5 {
      overflow-y: hidden;
      text-overflow: ellipsis;
    }

    text-align: left;    
  }
</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'VideoItem',
  components: {
    
  },
  props: {

  },
  data() {
    return {

    }
  },
  computed: mapState({
    selectedVideo: state => state.global.selectedVideo,
    videoLink: function() {
      if (this.selectedVideo) {
        return `${window.location.origin}${window.location.pathname}?id=${this.selectedVideo.id}`
      }

      return null
    }
  }),  
  created() {
    window.addEventListener("resize", this.getIframeHeight);
  },
  mounted() {
    this.getIframeHeight()
  },
  destroyed() {
    window.removeEventListener("resize", this.getIframeHeight);
  }, 
  methods: {
    getIframeHeight() {
      const header = this.$refs['video-header']
      const footer = this.$refs['video-footer']
      const media = this.$refs['video-media']
      if (header && footer && media) {
        const headerHeight = header.clientHeight
        const footerHeight = footer.clientHeight
        media.setAttribute('style', `height: calc(100vh - (79px + 80px + ${headerHeight + footerHeight}px));`)
      } else {
        this.$nextTick(() => {
          this.getIframeHeight()
        })
      }
    },
    copyLink() {
      /* Get the text field */
      const input = document.getElementById('video-link');

      /* Select the text field */
      input.select();
      input.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");      
    },
    getVideoDate(video) {
      return this.formatDate(new Date(Number(video.metadata.timestamp)), 'dd/MM/yyyy')
    }    
  }  
}
</script>
