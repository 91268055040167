<template>
  <section>
    <template v-if="isLoading === true">
      <loader />
    </template>

    <template v-else>
      <template v-if="hasError === true">
        <b-alert
          class="info round"
          show
        >
          <div class="alert-box-content">
            <b-icon
              icon="exclamation-circle"
              variant="danger"
            />              
            <span class="alert-box-message">
              Oops, something went wrong
            </span>
            <span class="alert-box-details">
              Unable to load the data
            </span>
          </div>
        </b-alert>
      </template>
      <template v-else>
        <div class="row justify-content-md-center">
          <div class="col col-12 pb-2">
            <player-select
              :add-squad="true"
              :players="players"
              :allow-share="false"
            />
          </div>
        </div>    

        <!-- PLAYERS LIST -->
        <template v-if="playerId === null">
          <squad-players-summary />
        </template>
        <template v-else>
          <div
            :key="playerId"
            class="row"
          >
            <div class="col col-12">
              <player
                :username="playerId"
                :allow-player-select="false"
              />
            </div>    
          </div>
        </template>
      </template>
    </template>
  </section>
</template>

<style lang="scss" scoped>

</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import loader from '@/components/layout/Loader.vue'
import PlayerSelect from '@/components/players/PlayerSelect.vue'
import Player from '@/views/Player.vue'
import SquadPlayersSummary from '@/components/squads/SquadPlayersSummary.vue'

export default {
  name: 'SquadPlayers',
  components: {
    loader,
    PlayerSelect,
    SquadPlayersSummary,
    Player
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      error: null
    }
  },
  computed: mapState({
    playerId: state => state.global.playerId,
  }),  
  created: function() {
    this.load()
  },
  mounted: function() {

  },  
  methods: {
    load() {
      this.isLoading = true
      const params = {
        id: this.squadId
      }
      http.getSquadPlayers(params).then(
        (response) => {
          this.players = response.data
        }
      ).catch(
        (error) => {
          this.hasError = true
          this.error = error.response.data
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    }
  }  
}
</script>
