<template>
  <section>
    <div
      class="row justify-content-md-center"
    >
      <div class="col col-12">
        <div class="d-flex align-items-center justify-content-center">
          <b-badge
            :variant="player.status.isUpdated === true ? 'success' : 'danger'"
            size="lg"
            class="player-status mr-2"
          />          
          <small class="text-gray last-update">
            <span>{{ lastUpdate }}</span>         
          </small>  
        </div>
        <small class="mt-0 mt-2 mb-2 justify-content-center align-items-center d-flex">
          <small class="text-gray">
            Games pulled : <span class="text-white">
              {{ player.gamesPulled }}
            </span>
          </small> 
        </small>
        <div class="player-summary pt-3 pb-3">
          <player-summary :player="player" />
        </div>
      </div>
       
      <div class="col col-12 pt-3">
        <b-tabs
          content-class="mt-3"
        >
          <b-tab
            title="Evolution"
            active
            lazy
          >
            <player-evolution :player="player" />
          </b-tab> 
          <b-tab
            title="Results / Gulags"
            lazy
          >
            <player-results :player="player" />
          </b-tab>          
          <b-tab
            title="Sessions"
            lazy
          >
            <player-sessions
              :player="player"
            />
          </b-tab>                  
          <b-tab 
            title="Games"
            lazy
          >            
            <player-games :player="player" />
          </b-tab>
        </b-tabs>
      </div>
    </div> 
  </section> 
</template>

<style lang="scss" scoped>
  @import '~@/assets/style/colors.scss';

  section {
    margin: 0 auto;
    max-width: 1056px;
    border-radius: 16px; 
  }  

  .player-summary {
    border-top: 1px solid $color-charade;
    border-bottom: 1px solid $color-charade;
  }

  small.last-update {
    line-height: 12px;
  } 

  /deep/ .tabs > .tab-content {
    padding-top: 0px !important;
  }

  /deep/ .tabs > div:not(.tab-content) > ul.nav-tabs {
    @media (max-width: 421px) {
      overflow-y: auto;
      width: calc(100vw - 40px);
      flex-wrap: nowrap;
      display: flex;  
      padding-bottom: 6px;
      
      & li:first-child {
        margin-left: auto;
      }

      & li:last-child {
        margin-right: auto;
      }
    }    
  }

  .badge.player-status {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: inline-block;
    position: rel;
  }   
</style>

<script>
import { mapState } from 'vuex' 

import PlayerSummary from '@/components/players/PlayerSummary.vue'
import PlayerEvolution from '@/components/players/PlayerEvolution.vue'
import PlayerResults from '@/components/players/PlayerResults.vue'
import PlayerSessions from '@/components/players/PlayerSessions.vue'
import PlayerGames from '@/components/players/PlayerGames.vue'

export default {
  name: 'PlayerCard',
  components: {
    PlayerSummary,
    PlayerEvolution,
    PlayerResults,
    PlayerSessions,
    PlayerGames
  },
  props: {
    player: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: mapState({
    lastUpdate() {
      return this.formatDate(new Date(this.player.status.lastUpdate), 'dd/MM/yyyy hh:mm')
    }
  }),  
  created: function() {

  },
  mounted: function() {

  },  
  methods: {

  }  
}
</script>
