<template>
  <section>
    <div class="row justify-content-center justify-content-center">
      <div class="col col-12">
        <h4>We are the [RTLD0]!</h4>
        <p class="text-gray">
          We love to play video games together and let's be honest, Call Of Duty - Warzone is quite an awesome game.<br><br>
          <span class="text-white">
            And we love data and stats!
          </span>
        </p>
        <p class="text-gray">
          Since we can remember, any games we were playing (from Quake III to Mario Kart) we built many many statistics, charts, rules, to laught, reward, mock, punish and have fun!
        </p>
        <p class="text-gray"> 
          We are building this platform for us and we think you might be interested...
        </p>
        <p class="text-gray"> 
          With that, we try to bring to you a bit of our world...<br>
          Another way to look at your games, sessions and performances with your friends.
        </p>
        <h4 class="mt-4 mb-2">
          What do we do?
        </h4>
        <div>
          <swiper
            ref="slider"
            class="swiper"
            :options="swiperOption"
          >
            <div
              slot="pagination"
              class="swiper-pagination"
            /> 

            <swiper-slide :index="1">       
              <h5 class="text-white">
                We consolidate your sessions!
              </h5>
              <img src="@/assets/images/help/session.png">
            </swiper-slide> 
            <swiper-slide :index="2">       
              <h5 class="text-white">
                We give stupid rewards!
              </h5>
              <img src="@/assets/images/help/session-rewards.png">
            </swiper-slide> 
            <swiper-slide :index="2">       
              <h5 class="text-white">
                We provide detailed stats!
              </h5>
              <img src="@/assets/images/help/session-stats.png">
            </swiper-slide>                                                 
          </swiper>
        </div>
        <h4 class="mt-4 mb-2">
          What we don't attend to do
        </h4>
        <p class="text-gray">
          Because our focus is to bring you and your friends playing together and have something to discuss and laught we will not :
        </p>
        <ul class="p-0">
          <li><small>Provide global leaderboards</small></li>
          <li><small>Provide consolidated match details (with all players)</small></li>
          <li><small>Provide loadout details</small></li>
          <li><small>Provide any way to compare yourself with anyone else than people in your squad</small></li>
          <li><small>Pull endlessly data we don't even use and care (you decide when you update your data)</small></li>
        </ul>
        <small class="text-gray">
          You will find plenty of tools and website to get such things
        </small>
      </div> 
    </div> 
    <div class="row justify-content-center justify-content-center">
      <div class="col col-12 pt-4 pb-4">
        <a 
          href="mailto:contact@madbison.net"
          class="shiny"
        >
          Contact us!
          <i />
        </a>      
      </div>
    </div>   
  </section>
</template>

<style lang="scss" scoped>

  @import '~@/assets/style/colors.scss';

  img {
    width: 100%;
    max-width: 420px;
  }

  .beer {
    width: 24px;
  }

  ul {
    list-style: none;
  }

</style>

<script>

import { mapState } from 'vuex'

export default {
  name: 'About',
  components: {

  },
  props: {

  },
  data() {
    return {
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        freeMode: false,
        grabCursor: true,
        loop: false,
        loopFillGroupWithBlank: false,
        mousewheel: {
          forceToAxis: true,
          invert: true
        },
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }        
      }      
    }
  },
  computed: mapState({

  }),
  watch: {

  },
  created() {
    
  },
  methods: {

  }  
}
</script>
