<template>
  <section>
    <div class="row justify-content-md-center">
      <div class="col col-12">    
        <template v-if="isLoading === true">
          <loader />
        </template>

        <template v-else>
          <template v-if="session === false">
            No session found
          </template>

          <template v-else-if="session !== null">
            <!-- SESSION -->
            <section class="session">
              <session-card
                :session="session"
              />
            </section>
          </template>
        </template>  
      </div>         
    </div>      
  </section>
</template>

<style lang="scss" scoped>
  .session {
    margin: 0 auto;
    max-width: 1056px;
    border-radius: 16px;
  }  
</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex'

import Loader from '@/components/layout/Loader.vue'

import SessionCard from '@/components/sessions/SessionCard.vue'

export default {
  name: 'Session',
  components: {
    Loader,
    SessionCard
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      session: null
    }
  },
  computed: mapState({

  }),
  watch: {

  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.isLoading = true

      // Get query params
      if (this.$route.query
        && this.$route.query.filename) {
        const params = {
          squadId: this.$route.query.squadId,
          filename: this.$route.query.filename
        }

        http.getSession(params).then(
          (response) => {
            this.session = response.data
          }
        ).catch(
          () => {
            this.session = false
          }
        ).finally(
          () => {
            this.isLoading = false
          }
        )

        // Get session
      } else {
        this.session = false
        this.isLoading = false
      }
    }
  }  
}
</script>
