<template>
  <b-modal
    id="modal-badge"
    ref="modal-badge"
    size="lg"
    hide-footer
  >
    <template
      v-if="badge !== null"
      v-slot:modal-header="{ close }"
    >
      <h4 class="ml-auto mr-auto">
        {{ badge.title }}
      </h4>
      <b-button 
        size="sm"
        class="btn-close"
        @click="close"
      />
    </template>

    <template
      v-if="badge !== null"
      v-slot:default
    >
      <div class="badge-row pt-2 pb-2">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div
            class="badge-logo"
          >           
            <div
              class="reward"
              :class="badge.grade !== undefined ? badge.grade : (badge.god ? 'god' : (badge.special ? 'special' : 'legendary'))"
            >
              <div class="circle" />
              <img  
                width="120"
                :src="badge.logo"
              >
            </div>                      
          </div> 
          <h3 v-if="badge.result">
            {{ badge.result }}
          </h3>
          <small class="text-gray">
            {{ badge.keyStats }}
          </small> 

          <b-badge
            v-if="badge.special === true"
            variant="dark"
            size="sm"
            class="d-block badge-special mt-2"
          >
            SPECIAL
          </b-badge> 
          <b-badge
            v-else-if="badge.god === true"
            variant="dark"
            size="sm"
            class="d-block badge-special mt-2"
          >
            GOD
          </b-badge>           
          
          <div
            v-if="badge.players !== undefined"
            class="chart-container"
          >
            <badge-chart
              :stats="badge.players"
            />
          </div>

          <div class="good-reward pt-4 mr-auto">
            <h6>How do I get it ?</h6>
            <small
              class="text-gray"
              v-html="badge.details"
            />            
          </div>          

          <div class="good-reward pt-4 mr-auto">
            <h6>Is it a good Reward ? :)</h6>
            <small
              class="text-gray"
              v-html="badge.goodReward"
            />            
          </div>          
        </div>

        <!-- GRADES LIST  -->
        <div
          v-if="badge.grades"
          class="grades pt-4 d-flex flex-column justify-content-center"
        >
          <h6>Grades</h6>
          <div
            v-for="grade in Object.keys(badge.grades)"
            :key="grade"
            class="d-flex p-1 pl-2 grade-item"
            :class="{'active': grade === badge.grade}"
          >
            <div
              class="reward xs"
              :class="grade"
            >
              <div class="circle">
                <div class="text-circle" />
              </div>
            </div>
            <div class="d-flex align-items-center">
              <small class="text-gray pl-4">{{ badge.grades[grade] }}</small>
            </div>
          </div>
        </div>
      </div>
    </template>   
  </b-modal>
</template>

<style lang="scss" scoped>

  @import '~@/assets/style/colors.scss';

  .modal {
    & .modal-custom-footer {
      display: flex;
      margin-top: 0px;
      height: 50px;
    }
  }

  .badge-row {
    border-bottom: 4px solid $color-manatee-opacity;
  }

  p.badge-description {
    font-size: 12px;
  }

  .badge-special {
    margin-top: -16px;
  }

  .badge-logo {
    & img {
      transform: translateX(-6px) translateY(10px)
    }
  }

  .chart-container {
    width: 100%;
  }

  small {
    font-size: 10px;
    line-height: 16px !important;
  }  

  .grades {
    margin: 0 auto;

    & ul {
      padding-left: 0
    }
  }

  .grade-item {
    &.active {
      background-color: rgba(255, 255, 255, 0.1);

      & small {
        color: $color-white !important;
        font-weight: bold;
      }
    }
  }

  .good-reward {
    line-height: 16px !important;    
  }
</style>

<script>

import { mapState } from 'vuex' 

import badgeChart from '@/components/badges/BadgeChart.vue'

export default {
  name: 'BadgeModal',
  components: {
    badgeChart
  },
  props: {
    badge: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      grades: [
        'bronze',
        'silver',
        'gold',
        'epic',
        'master',
        'legendary',
      ]    
    }
  },
  computed: mapState({

  }),
  created: function() {
    
  },
  mounted: function() {

  },
  methods: {

  }  
}
</script>
