<template>
  <section>
    <div class="row justify-content-md-center">
      <div class="col col-12">
        <template v-if="isLoading === true">
          <loader />
        </template>
        <template v-else>
          <template v-if="hasError">
            <b-alert
              class="info round"
              show
            >
              <div class="alert-box-content">
                <b-icon
                  icon="exclamation-circle"
                  variant="danger"
                />              
                <span class="alert-box-message">
                  Oops, something went wrong
                </span>
                <span class="alert-box-details">
                  Unable to load the data
                </span>
              </div>
            </b-alert>            
          </template>
          <template v-else>
            <section class="squad-sessions">
              <template v-if="sessionsAvailable === false">
                <div
                  class="start p-2"
                >
                  <router-link :to="`/squads/new?player=${player}`">
                    <small
                      v-if="playerBelongsToSelectedSquad === false"
                      class="text-gray"                  
                    >
                      Sessions details only available for Squads
                    </small>
                    <a class="shiny text-gray">
                      <small>Create a </small><br><b class="text-white">Squad</b><br>!
                      <i />
                    </a>
                  </router-link>                
                </div>             
              </template>
              <template v-else>
                <sessions-list
                  :allow-player-selection="false"
                  :allow-refresh="false"
                  :player="player.username"
                />
              </template>
            </section>
          </template>
        </template>
      </div>         
    </div> 
  </section> 
</template>

<style lang="scss" scoped>
  section {
    margin: 0 auto;
    max-width: 1056px;
    border-radius: 16px;
  }
</style>

<script>
import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import loader from '@/components/layout/Loader.vue'

import SessionsList from '@/components/sessions/SessionsList.vue'

export default {
  name: 'PlayerSessions',
  components: {
    loader,
    SessionsList
  },
  props: {
    player: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      sessionsAvailable: false
    }
  },
  computed: mapState({
    
  }),  
  created: function() {
    this.load()
  },
  mounted: function() {

  },  
  methods: {
    load() {
      this.isLoading = true
      this.hasError = false

      const params = {
        player: this.player,
        squadId: this.squadId
      }
      http.getPlayerSessionsAvailable(params).then(
        (response) => {
          this.sessionsAvailable = response.data.available
        }
      ).catch(
        () => {
          this.hasError = true
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    }
  }  
}
</script>
