<template>
  <div>
    <iframe
      ref="game"
      src="https://space.clementbonenfant.com"
    />
  </div>
</template>

<style lang="scss" scoped>
  iframe {
    border: 0;
    width: 420px;
    height: 420px;
    margin: 0 auto;
  }
</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'SpaceInvader',
  components: {
    
  },
  props: {

  },
  data() {
    return {

    }
  },
  computed: mapState({

  }),  
  created: function() {

  },
  mounted: function() {
    const game = this.$refs['game']
      if (game) {
        game.focus()
      }
  },  
  methods: {

  }  
}
</script>
