<template>
  <section>
    <template v-if="isLoading === true">
      <loader />
    </template>

    <div class="row games-filters">
      <div
        v-if="isLoading === false"
        class="col col-12 col-lg-6 col-xl-4"
      >
        <div class="d-flex align-items-center">          
          <b-form-group
            id="input-group-mode"
            class="floating-labels flex-grow-1 mb-0"
          >
            <multiselect
              id="input-mode"
              v-model="filters.selectedMode"
              :placeholder="'Select a mode'"
              :multiple="false"
              :label="'label'"
              :close-on-select="true"
              :clear-on-select="false"              
              :options="modes"
              :track-by="'id'"
              :searchable="false"
              :allow-empty="false"
              @input="onFilterChange"
            >
              <template
                slot="singleLabel"
                slot-scope="{ option }"
              >
                {{ option.label }}
              </template>             
            </multiselect>
            <label
              v-if="filters.selectedMode"
              class="is-floating"
              for="input-mode"
            >
              Mode
            </label>             
          </b-form-group>
          <label
            v-if="isSmallDevice === false"
            class="switch mt-0 mb-0 ml-3"
          >
            <input
              id="only-wins"
              v-model="filters.onlyWins"
              type="checkbox"
              @change="onFilterChange"
            >
            <span class="slider round" />

            <div class="slider-label text-gray">
              <img
                src="@/assets/images/crown.png"
                class="crown"
              >
            </div>            
          </label>                          
        </div>
      </div>

      <div
        v-if="isLoading === false && isSmallDevice === true"
        class="col col-12 col-lg-6 col-xl-3 pt-3"
      >
        <div class="d-flex align-items-center">
          <small class="text-gray ml-2 mr-auto">
            <span class="text-white">
              {{ games.games.count }}
            </span> / {{ games.games.total }} Games
          </small>
          <label class="switch mt-0 mb-0 ml-auto">
            <input
              id="only-wins"
              v-model="filters.onlyWins"
              type="checkbox"
              @change="onFilterChange"
            >
            <span class="slider round" />

            <div class="slider-label text-gray">
              <img
                src="@/assets/images/crown.png"
                class="crown"
              >
            </div> 
          </label>               
        </div>
      </div>
    </div>

    <!-- SESSIONS LIST -->
    <template v-if="isLoading === false">
      <template v-if="games.games.list.length === 0">
        <b-alert
          class="info round mt-4"
          show
        >
          <div class="alert-box-content">
            <b-icon
              icon="exclamation-circle"
              variant="info"
            />              
            <span class="alert-box-message">
              No session
            </span>
            <span class="alert-box-details">
              No session for the selected filters, please change
            </span>
          </div>
        </b-alert>
      </template>
      <template v-else>
        <section class="games-list pt-2">
          <small
            v-if="isSmallDevice === false"
            class="text-gray ml-2 mr-auto"
          >
            <span class="text-white">
              {{ games.games.count }}
            </span> / {{ games.games.total }} Games
          </small>          
          <game-card
            v-for="(session) in games.games.list"
            :key="session.id"
            :game="session"
            :kd-ratio="games.kdRatio"
            class="mb-4 bg-charade clickable"
          />
        </section>
        <section
          v-if="games.games.count < games.games.total"
          class="show-more"
        >
          <b-button
            size="sm"
            @click="showMore"
          >
            Show more
          </b-button>
        </section>
      </template>
    </template>     
  </section>
</template>

<style lang="scss" scoped>
  section {
    border-radius: 16px;
    margin: 0 auto; 
  }

  .update-games-button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 100;
  }

  .games-mode {
    overflow-x: auto;
  }   

  .games-list,
  .games-filters {
    max-width: 1056px;
  }  

  .switch {
    & .slider-label {
      white-space: nowrap;
      display: inline-block;
      position: absolute;
      /* left: 50%; */
      /* transform: translateX(100%); */
      left: 0;
      top: -12px;
      font-size: 8px;
      font-weight: bold;
    }
  }

  img.crown {
    width: 18px;
    position: relative;
    top: -4px;
    left: 9px;

    @media (max-width: 422px) {
      top: 18px;
      left: -24px;      
    }
  }
  
</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import Loader from '@/components/layout/Loader.vue'
import GameCard from '@/components/games/GameCard.vue'

export default {
  name: 'GamesList',
  components: {
    Loader,
    GameCard
  },
  props: {
    player: {
      type: Object,
      required: true
    }    
  },
  data() {
    return {
      games: null,
      isRefreshing: false,
      hasError: false,
      errors: null,
      perPage: 30,
      currentPage: 1,      
      filters: {
        onlyWins: false,
        onlyFull: true,
        selectedMode: null,
      },        
    }
  },
  computed: mapState({
    isLoading: function() {
      return this.games === null || this.filters.selectedPlayer === null
    },
    modes: function() {
      const modes = []
      const keys = this.games.games.modes
      keys.forEach((key) => {
        modes.push({
          id: key,
          label: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].label : key,
          order: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].order : 9999,
        })
      })

      return modes.sort((a, b) => {
        if(a.order < b.order) { return -1; }
        if(a.order > b.order) { return 1; }
        return 0;
      })
    }
  }),  
  watch: {

  },  
  created: function() {
    this.load()
  },
  mounted: function() {

  },
  methods: {
    load() {
      const params = this.getParams()    
      http.getPlayerGames(params).then(
        (response) => {
          if (this.currentPage > 1
            && this.games) {
            this.games.kdRatio = response.data.kdRatio
            this.games.games.list = [...this.games.games.list, ...response.data.games.list]
            this.games.games.count += response.data.games.count
          } else {
            this.games = response.data
          }

          if (this.filters.selectedMode === null) {
            this.filters.selectedMode = this.modes[0]
          }          
        }
      )
    },
    getParams() {
      return {
        username: this.player.username,
        filters: {
          onlyWins: this.filters.onlyWins,
          onlyFull: this.filters.onlyFull,
          mode: this.filters.selectedMode ? this.filters.selectedMode.id : 'all'
        },
        startAt: (this.currentPage - 1) * this.perPage,
        limit: this.perPage
      }
    },
    onFilterChange() {
      this.currentPage = 1
      this.load()
    },
    showMore() {
      this.currentPage += 1
      this.load()
    }  
  }  
}
</script>
