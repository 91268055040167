<template>
  <section class="p-2">
    <div
      ref="chart"
      class="chart br-16"
    />
  </section>
</template>

<style lang="scss" scoped>
  
  @import '@/assets/style/colors.scss';

  section {
    border-radius: 16px;
    background-color: $color-charade;
  }
  .chart {
    width: 100%;
    height: 500px;
  }

  /deep/ .plot-label {
    position: relative;
    left: -12px;
    display: inline-block;
    color: $color-white;

    &.top-25 {
      color: $color-white
    }
    &.top-10 {
      color: $color-yellow
    }
    &.top-5 {
      color: $color-blue
    }
    &.top-1 {
      color: $color-success
    }             
  }

  /deep/ img.crown {
    width: 24px;
    position: relative;
    top: -24px;    
    left: 6px;
  }  

  /deep/ .gulag-result {
    position: relative;
    top: 38px;    

    & small {
      display: block;
      color: $color-manatee;
      text-align: center;
    }

    & img.gulag {
      width: 16px;

      &.lost {
        filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg);      
      }

      &.win {
        filter: brightness(50%) sepia(100) saturate(100) hue-rotate(90deg);      
      }    
    } 
  } 

  /deep/ .highcharts-navigator-series {
    & img.crown {
      top: -12px;
      left: 12px;
      width: 8px;      
    }
    & .gulag-result {
      position: relative;
      top: initial;    

      & small {
        display: block;
        color: $color-manatee;
        text-align: center;
        font-size: 6px;
      }

      & img.gulag {
        width: 6px;   
      } 
    }  
  }
</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'PlayerResultsChart',
  components: {

  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null,
      crownImage: require('@/assets/images/crown.png'),
      gulagImage: require('@/assets/images/gulag.png')
    }
  },
  computed: mapState({
    
  }),  
  watch: {

  },  
  created: function() {

  },
  mounted: function() {
    this.initChart()
  },  
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },  
  methods: {
    initChart() {
      // Create chart instance
      const data = this.getChartData()

      const vm = this

      let chart = this.Highcharts.stockChart(this.$refs['chart'], {
          chart: {
            paddingLeft: 40,
            paddingRight: 40,
            marginTop: 16,  
            marginRight: 32,               
            marginLeft: 16,
            marginBottom: 40,
          },

          legend: {
            enabled: true,
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal'
          },
          rangeSelector: {
            allButtonsEnabled: true,
            buttons: [{
                type: 'day',
                count: 1,
                text: '1d',
                title: 'View 1 week'
            },{
                type: 'week',
                count: 1,
                text: '1w',
                title: 'View 1 week'
            },              
            {
                type: 'month',
                count: 1,
                text: '1m',
                title: 'View 1 month'
            }, {
                type: 'month',
                count: 3,
                text: '3m',
                title: 'View 3 months'
            }],
            selected: 1
          },          

          xAxis: {
            lineColor: 'rgba(143, 153, 172, 0.25)',
            gridLineColor: 'rgba(143, 153, 172, 0.25)',
            paddingRight: 40,
            marginRight: 40,            
            labels: {
              rotation: 0,
              y: 24
            }                
          }, 
          yAxis: { // Team placement
            visible: true,
            lineColor: 'rgba(0,0,0,0)',
            gridLineColor: 'rgba(0,0,0,0)',
            reversed: true,
            min: 1,
            showFirstLabel: false,
            lineWidth: 0,
            title: {
              text: null,
            },
            plotLines: [
              {
                color: 'rgba(255, 255, 255, 1)',
                width: 1,
                value: 25,
                label: {
                  rotation: 0,
                  useHTML: true,              
                  formatter: function () {
                    return `<span class="plot-label top-25">Top ${this.options.value}</span>`
                  }
                }
              },
              {
                color: 'rgba(250, 185, 0, 1)',
                width: 1,
                value: 10,
                label: {
                  rotation: 0,
                  useHTML: true,              
                  formatter: function () {
                    return `<span class="plot-label top-10">Top ${this.options.value}</span>`
                  }
                }
              },
              {
                color: 'rgba(0, 178, 221, 1)',
                width: 1,
                value: 5,
                label: {
                  rotation: 0,
                  useHTML: true,              
                  formatter: function () {
                    return `<span class="plot-label top-5">Top ${this.options.value}</span>`
                  }
                }
              },
              {
                color: 'rgba(11, 177, 74, 1)',
                width: 1,
                value: 1,
                label: {
                  rotation: 0,
                  useHTML: true,              
                  formatter: function () {
                    return `<span class="plot-label top-1">Top ${this.options.value}</span>`
                  }
                }
              }                                                 
            ]                                              
          },        

          tooltip: {
            split: true
          },  
          
          plotOptions: {
            series: {
              marker: {
                enabled: true
              },
              dataLabels: [{
                enabled: true,
                useHTML: true,
                formatter: function() {
                  const index = this.point.index
                  const gulagResult = vm.data.gulagResults[index]
                  let crown = ''
                  let gulag = ''
                  if (this.y === 1) {
                    crown = `<img src="${vm.crownImage}" class="crown">`
                  }

                  if (gulagResult[1] === null) {
                    gulag = '-'
                  } else {
                    const gulagClass = gulagResult[1] > 0 ? 'win' : 'lost'
                    gulag = `
                      <div class="gulag-result">
                        <img src="${vm.gulagImage}" class="gulag ${gulagClass}">
                        <small>C${Math.abs(gulagResult[1])}</small>
                      </div>
                      `
                  }  

                  return `
                    <div class="box-xs">
                      ${crown}
                      ${gulag}
                    </div>
                  `
                }
              }]
            }
          },          

          navigator: {
              margin: 48,
              adaptToUpdatedData: true,
              outlineWidth: 0,
              height: 48,
              xAxis: {
                gridLineWidth: 0,
                labels: {
                  enabled: false
                }
              },
              yAxis: {
                gridLineWidth: 0,
              }
            },          

          series: data
      });
      
      this.chart = chart      
    },
    getChartData() {
      let series = [{
        name: 'Placement',
        yAxis: 0,
        data: this.data.teamPlacements,
        type: 'spline'
      }]

      return series
    }
  }  
}
</script>
