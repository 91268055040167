<template>
  <b-modal
    id="modal-authenticate"
    ref="modal-authenticate"
    size="lg"
    no-close-on-backdrop
    hide-footer
    @hidden="resetModal"
    @shown="focusInput"
  >
    <template v-slot:modal-header="{ }">
      <h4>Authenticate yourself soldier!</h4>
      <b-button 
        size="sm"
        class="btn-close"
        @click="closeModal()"
      />
    </template>

    <template v-slot:default>
      <template v-if="isLoading === true">
        <loader />
      </template>

      <template v-else>
        <b-form 
          ref="auth-form"
        >
          <b-form-group
            id="input-group-username"
            class="floating-labels"
          >
            <b-form-input
              id="input-username"
              ref="input-username"
              v-model="$v.form.username.$model"
              type="text"
              required
              :placeholder="'Username'"
              :state="validateState('username')"
              aria-describedby="feedback-username"
            />
            <label for="input-title">
              Username
            </label>

            <b-form-invalid-feedback id="feedback-username">
              Please type your username
            </b-form-invalid-feedback>              
          </b-form-group>

          <b-form-group
            id="input-group-password"
            class="floating-labels"
          >
            <b-form-input
              id="input-password"
              v-model="$v.form.password.$model"
              type="password"
              required
              :placeholder="'Password'"
              :state="validateState('password')"
              aria-describedby="feedback-password"
            />
            <label for="input-password">
              Password
            </label>

            <b-form-invalid-feedback id="feedback-password">
              Please type your password
            </b-form-invalid-feedback>              
          </b-form-group>                                                      
        </b-form>

        <div class="modal-custom-footer">
          <b-button
            variant="primary"
            class="ml-auto"
            :class="isLoading === true ? 'btn-loading' : ''"
            @click="submit"
          >
            Authenticate
          </b-button>  
        </div>
      </template>      
    </template>   
  </b-modal>
</template>

<style lang="scss" scoped>
  .modal {
    & .modal-custom-footer {
      display: flex;
      margin-top: 0px;
      height: 50px;
    }
  }
</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

import Loader from '@/components/layout/Loader.vue'

export default {
  name: 'AuthModal',
  components: {
    Loader
  },
  mixins: [validationMixin],  
  props: {

  },
  data() {
    return {
      isLoading: false,
      hasFormError: false,
      form: {
        username: null,
        password: null
      }      
    }
  },
  validations: {
    form: {
      username: {
        required
      },
      password: {
        required
      }
    }
  }, 
  computed: mapState({

  }),
  created: function() {

  },
  mounted: function() {

  },
  methods: {
    focusInput() {
      const inputUsername = this.$refs['input-username']
      if (inputUsername) {
        inputUsername.focus()
      }
    },
    resetModal() {
      this.form = {
        username: null,
        password: null
      }
      this.isLoading = false
    },
    closeModal() {
      this.$store.dispatch('global/setRequestAuth', false)
      this.$refs['modal-authenticate'].hide()
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];

      return $dirty ? !$error : null;
    },      
    validateForm: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      }

      return true
    },
    submit(e) {
      if (this.validateForm() === false) {
        return false
      }

      this.isLoading = true

      this.$store.dispatch('global/setAuthCredentials', {
        username: this.form.username,
        password: this.form.password
      })      

      http.auth().then(
        () => {
          this.$store.dispatch('global/setAuth', true)

          // Set default squad-id
          this.$store.dispatch('global/setSquadId', 'squad-rtld0')

          // Save credtention to session
          window.localStorage.setItem('user', JSON.stringify({
            username: this.form.username,
            password: this.form.password
          }))

          this.closeModal()
        }
      ).catch(
        () => {
          this.$store.dispatch('global/setAuth', false)
          this.$store.dispatch('global/unsetAuthCredentials')          

          this.$store.dispatch('global/addToast',
            {
              title: 'Authentication failed',
              message: `Nope! Nope! Nope! Nope! Nope!`,
              type: 'danger'
            }
          )

          window.localStorage.removeItem('user')
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )      

      e.preventDefault()
      e.stopPropagation()

      return false
    }     
  }  
}
</script>
