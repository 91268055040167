<template>
  <section>
    <div class="row justify-content-md-center">    
      <div class="col col-12 col-lg-6 col-xl-4">
        <div class="d-flex align-items-center">
          <b-form-group
            id="input-group-mode"
            class="floating-labels flex-grow-1 mb-0"
          >
            <multiselect
              id="input-mode"
              v-model="selectedMode"
              :placeholder="'Select a mode'"
              :multiple="false"
              :label="'label'"
              :close-on-select="true"
              :clear-on-select="false"              
              :options="modes"
              :track-by="'id'"
              :searchable="true"
              :allow-empty="false"
            >
              <template
                slot="singleLabel"
                slot-scope="{ option }"
              >
                {{ option.label }}
              </template>             
            </multiselect>
            <label
              v-if="selectedMode"
              class="is-floating"
              for="input-mode"
            >
              Mode
            </label>             
          </b-form-group>
        </div>
      </div>
    </div> 
    <div class="row justify-content-md-center">    
      <div class="col col-12 mb-2">
        <small class="text-gray">
          Evolution calculated based on the games statistics
        </small> 
      </div>
    </div>       
    <div
      v-if="selectedMode !== null"
      class="row justify-content-md-center"
    >
      <div class="col col-12">
        <swiper
          :key="selectedMode.id"
          ref="slider"
          class="swiper"
          :options="swiperOption"
        >
          <div
            slot="pagination"
            class="swiper-pagination"
          />      
          <swiper-slide :index="1">    
            <h5>Summary</h5>   
            <player-evolution-chart
              :spline-keys="['kdRatio']"
              :stats-keys="['deaths', 'kills', 'downs', 'assists', 'revives']"
              :data="summaryData"
              :plot-line="player.stats['kdRatio']"
            />
          </swiper-slide>  
          <swiper-slide :index="2">    
            <h5>Damages</h5>   
            <player-evolution-chart
              :spline-keys="['damageDonePerKill', 'damageTakenPerDeath']"
              :stats-keys="['damageDone', 'damageTaken']"
              :data="stats.data[selectedMode.id].damages"
            />
          </swiper-slide>                       
          <swiper-slide :index="3">    
            <h5>Score</h5>   
            <player-evolution-chart
              :spline-keys="['scorePerMinute']"
              :stats-keys="['score']"
              :data="stats.data[selectedMode.id].score"
              :plot-line="player.stats['scorePerMinute']"
            />
          </swiper-slide>                          
        </swiper>
      </div>
    </div> 
  </section> 
</template>

<style lang="scss" scoped>
  @import '~@/assets/style/colors.scss';
</style>

<script>

import { mapState } from 'vuex' 

import playerEvolutionChart from '@/components/players/PlayerEvolutionChart.vue'

export default {
  name: 'PlayerEvolutionStats',
  components: {
    playerEvolutionChart
  },
  props: {
    player: {
      type: Object,
      required: true
    },
    stats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedMode: null,
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        freeMode: false,
        grabCursor: true,
        loop: false,
        loopFillGroupWithBlank: false,
        noSwipingSelector: '.chart',
        mousewheel: {
          forceToAxis: true,
          invert: true
        },
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }        
      } 
    }
  },
  computed: mapState({
    modes: function() {
      const modes = []
      const keys = this.stats.modes
      keys.forEach((key) => {
        modes.push({
          id: key,
          label: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].label : key,
          order: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].order : 9999,
        })
      })

      return modes.sort((a, b) => {
        if(a.order < b.order) { return -1; }
        if(a.order > b.order) { return 1; }
        return 0;
      })
    },
    summaryData: function() {
      const data = {
        ...this.stats.data[this.selectedMode.id].combat,
        teamPlacements: this.stats.data[this.selectedMode.id].teamPlacements,
        gulagResults: this.stats.data[this.selectedMode.id].gulagResults
      }

      return data
    }
  }),  
  created: function() {
    if (this.selectedMode === null) {
      this.selectedMode = this.modes[0]
    }
  },
  mounted: function() {

  },  
  methods: {

  }  
}
</script>
