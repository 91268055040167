<template>
  <section>
    <div class="row justify-content-md-center">    
      <div class="col col-12 col-lg-6 col-xl-4">
        <div class="d-flex align-items-center">
          <b-form-group
            id="input-group-mode"
            class="floating-labels flex-grow-1 mb-0"
          >
            <multiselect
              id="input-mode"
              v-model="selectedMode"
              :placeholder="'Select a mode'"
              :multiple="false"
              :label="'label'"
              :close-on-select="true"
              :clear-on-select="false"              
              :options="modes"
              :track-by="'id'"
              :searchable="isSmallDevice ? false : true"
              :allow-empty="false"
            >
              <template
                slot="singleLabel"
                slot-scope="{ option }"
              >
                {{ option.label }}
              </template>             
            </multiselect>
            <label
              v-if="selectedMode"
              class="is-floating"
              for="input-mode"
            >
              Mode
            </label>             
          </b-form-group>
        </div>
      </div>
    </div>       
    <div
      v-if="selectedMode !== null"
      :key="selectedMode.id"
      class="row justify-content-md-center"
    >
      <div class="col col-12 mt-2">
        <h5>Results / Gulags</h5>   
        <player-results-chart
          :data="stats.data[selectedMode.id]"
        />
      </div>
    </div> 
  </section> 
</template>

<style lang="scss" scoped>
  @import '~@/assets/style/colors.scss';
</style>

<script>

import { mapState } from 'vuex' 

import playerResultsChart from '@/components/players/PlayerResultsChart.vue'

export default {
  name: 'PlayerSummaryStats',
  components: {
    playerResultsChart
  },
  props: {
    player: {
      type: Object,
      required: true
    },
    stats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedMode: null
    }
  },
  computed: mapState({
    modes: function() {
      const modes = []
      const keys = this.stats.modes
      keys.forEach((key) => {
        modes.push({
          id: key,
          label: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].label : key,
          order: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].order : 9999,
        })
      })

      return modes.sort((a, b) => {
        if(a.order < b.order) { return -1; }
        if(a.order > b.order) { return 1; }
        return 0;
      })
    },
  }),  
  created: function() {
    if (this.selectedMode === null) {
      this.selectedMode = this.modes[0]
    }
  },
  mounted: function() {

  },  
  methods: {

  }  
}
</script>
