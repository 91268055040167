import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import '@/assets/style/main.scss'
import { mapState } from 'vuex'

import VueAwesomeSwiper from 'vue-awesome-swiper'

import 'request-animation-frame-polyfill'

Vue.use(VueAwesomeSwiper)

// import style (>= Swiper 6.x)
import 'swiper/css/swiper.css'

import * as am4core from "@amcharts/amcharts4/core";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";

am4core.useTheme(am4themes_dark);

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import Highcharts from "highcharts/highstock"
import more from 'highcharts/highcharts-more';
more(Highcharts);
import boost from "highcharts/modules/boost"
boost(Highcharts)

import http from '@/services/http.service.js'

// Highcharts
Highcharts.theme = {
  colors: ['#6794dc', '#6771dc', '#8067dc', '#a367dc', '#c767dc', '#dc67ce', '#FF6F91', '#FF9671', '#FFC75F', '#F9F871',
           '#9BDE7E', '#4BBC8E', '#1C6E7D'],
  chart: {
    backgroundColor: 'rgba(23, 23, 28, 1)',
    marginBottom: 0,
    marginTop: 0,
    marginRight: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0
  },
  title: {
    text: null
  },
  subtitle: {
      text: null
  }, 
  credits: {
    enabled: false
  },
  legend: {
    enabled: false,
    itemHiddenStyle: { color: 'rgba(143, 153, 172, 0.25)' },
    itemHoverStyle: { color: 'rgba(255, 255, 255, 1)' },
    itemStyle: {
      color: 'rgba(143, 153, 172, 1)',
      fontWeight: 'normal',
    }    
  },
  xAxis: {
    title: {
      text: null
    },
    paddingRight: 0,
    marginRight: 0,
    lineColor: 'rgba(143, 153, 172, 0.25)',
    labels: {
      rotation: 0,
      y: -5
    }                
  }, 
  yAxis: {
    min: 0,
    visible: false,
    title: {
      text: null,
    }
  },
  plotOptions: {
    bar: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        color: '#ffffff',
        borderWidth: 0,
        borderColor: 'transparent',
        crop: false,
        overflow: 'justify',
        align: 'right',
        inside: true,
        x: 0,     
        style: {
          fontSize: '10px',
          textOutline: false
        },        
        formatter: function() {
          return this.y > 0 ?
            `${this.series.name} - ${this.y}` : this.y
        }
      }        
    }
  },
  scrollbar: {
    barBackgroundColor: 'gray',
    barBorderRadius: 7,
    barBorderWidth: 0,
    buttonBackgroundColor: 'gray',
    buttonBorderWidth: 0,
    buttonArrowColor: 'white',
    buttonBorderRadius: 7,
    rifleColor: 'white',
    trackBackgroundColor: 'transparent',
    trackBorderWidth: 5,
    trackBorderColor: 'transparent',
    trackBorderRadius: 7
  },       
}
// Apply the theme
Highcharts.setOptions(Highcharts.theme);

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// register globally
Vue.component('multiselect', Multiselect)

Vue.config.productionTip = false

Vue.mixin({
  data: () => {
    return {
      Highcharts: Highcharts
    }
  },
  computed: mapState({
    isAuth: state => state.global.isAuth,
    mapping: state => state.global.mapping,
    squadId: state => state.global.squadId,
    playerId: state => state.global.playerId,
    isSmallDevice: function() {
      const width = document.documentElement.clientWidth || document.body.clientWidth
      return width < 421
    }
  }),
  methods: {
    openAuthModal: function() {
      this.$root.$emit('bv::show::modal', 'modal-authenticate')
    },   
    formatDate: function(x, y) {
      const z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
      };
      y = y.replace(/(M+|d+|h+|m+|s+)/g, (v) => {
          return ((v.length > 1 ? '0' : '') + z[v.slice(-1)]).slice(-2)
      });
  
      return y.replace(/(y+)/g, function(v) {
          return x.getFullYear().toString().slice(-v.length)
      });
    }  
  }
})

const firstRoute = `${window.location.pathname}${window.location.search}`

// Load badge images
const badgeLogos = {
  'ABSENT': require('@/assets/images/badges/ABSENT.png'),
  'ESCAPEE': require('@/assets/images/badges/ESCAPEE.png'),
  'FRAGILE': require('@/assets/images/badges/FRAGILE.png'),
  'GRACE': require('@/assets/images/badges/GRACE.png'),
  'INCAPABLE': require('@/assets/images/badges/INCAPABLE.png'),
  'JESUS': require('@/assets/images/badges/JESUS.png'),
  'JUGGERNAUT': require('@/assets/images/badges/JUGGERNAUT.png'),
  'MEDIC': require('@/assets/images/badges/MEDIC.png'),
  'MVP': require('@/assets/images/badges/MVP.png'),
  'ONE': require('@/assets/images/badges/ONE.png'),
  'REAPER': require('@/assets/images/badges/REAPER.png'),
  'SCAVENGER': require('@/assets/images/badges/SCAVENGER.png'),
  'SNIPER': require('@/assets/images/badges/SNIPER.png'),
  'THIEF': require('@/assets/images/badges/THIEF.png'),
  'BUYER': require('@/assets/images/badges/BUYER.png'),
  'FINISHER': require('@/assets/images/badges/FINISHER.png'),
  'GLOBINOS': require('@/assets/images/badges/GLOBINOS.png'),
  'HUNTER': require('@/assets/images/badges/HUNTER.png'),
  'IRONMAN': require('@/assets/images/badges/IRONMAN.png'),
  'JOHN_WICK': require('@/assets/images/badges/JOHN_WICK.png'),
  'KILLER': require('@/assets/images/badges/KILLER.png'),
  'MOB': require('@/assets/images/badges/MOB.png'),
  'NAG': require('@/assets/images/badges/NAG.png'),
  'PROFESSIONAL': require('@/assets/images/badges/PROFESSIONAL.png'),
  'RUNNER': require('@/assets/images/badges/RUNNER.png'),
  'SIDE_WING': require('@/assets/images/badges/SIDE_WING.png'),
  'STOLEN': require('@/assets/images/badges/STOLEN.png'),
  'TREE': require('@/assets/images/badges/TREE.png')
}

new Vue({
  store,
  router,
  beforeCreate() {
    const user = window.localStorage.getItem('user')
    if (user) {
      try {
        this.$store.dispatch('global/setAuthCredentials', JSON.parse(user))   
      } catch (e) {
        // Do nothing
      }
    }

    http.auth().then(
      () => {
        this.$store.dispatch('global/setAuth', true)

        // Set default squad-id
        this.$store.dispatch('global/setSquadId', 'squad-rtld0')
        window.localStorage.setItem('selectedSquadId', 'squad-rtld0')

        // Redirect user to asked route
        this.$router.push(firstRoute)
      }
    ).catch(
      () => {
        this.$store.dispatch('global/setAuth', false)
        this.$store.dispatch('global/unsetAuthCredentials')

        window.localStorage.removeItem('user')   
      }
    ).finally(
      () => {
        // Check has selected squad id
        const selectedSquadId = window.localStorage.getItem('selectedSquadId')
        if (selectedSquadId) {
          this.$store.dispatch('global/setSquadId', selectedSquadId)            
        }

        const selectedPlayerId = window.localStorage.getItem('selectedPlayerId')
        if (selectedPlayerId) {
          this.$store.dispatch('global/setPlayerId', selectedPlayerId)            
        }        
        
        // Get squad id
        if (this.$route.query
          && this.$route.query['id']) {
          this.$store.dispatch('global/setSquadId', this.$route.query['id'])
        }       

        if (this.$route.query
          && this.$route.query['username']) {
            this.$store.dispatch('global/setPlayerId', this.$route.query['username'])
        } 
        
        // Get mapping
        http.getMapping().then(
          (response) => {
            // Set badge logos
            Object.keys(response.data.badgesMapping).forEach(
              (badge) => {
                response.data.badgesMapping[badge].logo = badgeLogos[badge]
              }
            )

            this.$store.commit('global/SET_MAPPING', response.data)
          }
        ).catch(
          () => {
            // Do nothing
          }
        ).finally(
          () => {
            this.$store.dispatch('global/setIsInitialized', true)
          }
        )
      }  
    )
  },  
  render: h => h(App),
}).$mount('#app')
