<template>
  <b-modal
    id="modal-squad-count"
    ref="modal-squad-count"
    size="lg"
    close-on-backdrop
    hide-footer
  >
    <template v-slot:modal-header="{ close }">
      <h4>Miminum 2 players!</h4>
      <b-button 
        size="sm"
        class="btn-close"
        @click="close"
      />
    </template>

    <template v-slot:default>
      <b-alert
        class="info round"
        show
      >
        <div class="alert-box-content text-center">
          <b-icon
            icon="exclamation-circle"
            variant="info"
          />              
          <span class="alert-box-message">
            We only allow a minimum of 2 players.
          </span>
          <span class="alert-box-details">
            <br>
            The features are designed for the Squads and to get the best of it, you need to set a mimimum of 2 players who performed
            at least once all togheter in their game history (1000 last games).
            <br><br>
            If a game is meeting those criteria, we consider the Squad as valid and let you access the page.
            <br><br>
            You will then, under the sessions list have access to any sessions and games performed by the members of the Squad.
            <br><br>
            Quads
            - Trios
            - Duos
            - Solos
            - ...
            <br><br>
            I hope you understand...
            <br><br>
            <span class="text-gray">
              For Custom Squad (more than 4 players...) please <a
                href="mailto:contact@madbison.net"
                class="text-white link"
              >
                send us an email
              </a> with the Usernames, platforms and some screenshots justifying the possession of the accounts. We will create that for you ;)<br>
              <small class="text-gray">
                Make sure the screenshots do not contain any data related to your privacy (firstname, lastname, email, password, geolocalisation, ...) we don't need that ;)
              </small>
            </span>             
            <b-button
              class="mt-3 d-block ml-auto mr-auto mb-3"
              @click="closeModal()"
            >
              Ok, I understand
            </b-button>
          </span>
        </div>
      </b-alert>      
    </template>   
  </b-modal>
</template>

<style lang="scss" scoped>

  @import '@/assets/style/colors.scss';

</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'SquadCountModal',
  components: {

  },
  props: {
    errors: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      
    }
  },
  computed: mapState({
    nextTry() {
      return this.formatDate(new Date(this.errors.nextTry), 'hh:mm')
    }
  }),
  created: function() {

  },
  mounted: function() {

  },
  methods: {
    closeModal() {
      this.$refs['modal-squad-count'].hide()
    }
  }  
}
</script>
