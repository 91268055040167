<template>
  <b-modal
    id="modal-profile-username"
    ref="modal-profile-username"
    size="lg"
    close-on-backdrop
    hide-footer
  >
    <template v-slot:modal-header="{ close }">
      <h4>How to find my username</h4>
      <b-button 
        size="sm"
        class="btn-close"
        @click="close"
      />
    </template>

    <template v-slot:default>
      <template>
        <b-alert
          class="info round p-3"
          show
        >
          <div class="alert-box-content text-center">
            <b-icon
              icon="exclamation-circle"
              variant="info"
            />              
            <span class="alert-box-message">
              We recommand your Activision ID!
            </span>
            <span class="alert-box-details">
              <br>
              We have experienced a bunch of problems for username other than Activision ID.
              That's why we recommand you to use your Activision ID.
            </span>
          </div>
        </b-alert>

        <div class="text-center">
          <b-badge
            size="lg"
            variant="dark"
            class="mb-2"
          >
            <a
              class="text-white"
              target="_blank"
              href="https://profile.callofduty.com/cod/login"
            >
              https://profile.callofduty.com/cod/login
            </a>
          </b-badge> 

          <img src="@/assets/images/help/activision-id.png"> 

          <small class="text-gray">
            The value of "Activision ID" with the #
          </small>          
        </div>  

        <div class="text-center">
          <h5 class="mt-4">
            For other accounts
          </h5>

          <img src="@/assets/images/help/other-accounts.png"> 

          <small class="text-gray">
            The value next to your linked account
          </small>
        </div>   
      </template>    
    </template>   
  </b-modal>
</template>

<style lang="scss" scoped>

  @import '@/assets/style/colors.scss';

  .modal {
    & .modal-custom-footer {
      display: flex;
      margin-top: 0px;
      height: 50px;
    }
  }

  img {
    width: 100%;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'ProfilePrivacyModal',
  components: {

  },
  props: {

  },
  data() {
    return {
      currentStep: 1
    }
  },
  computed: mapState({

  }),
  created: function() {

  },
  mounted: function() {

  },
  methods: {
    goNext() {
      this.currentStep += 1
    }
  }  
}
</script>
