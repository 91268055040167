<template>
  <section>
    <template v-if="stats === null">
      <b-alert
        class="info round"
        show
      >
        <div class="alert-box-content">
          <b-icon
            icon="exclamation-circle"
            variant="info"
          />              
          <span class="alert-box-message">
            No stats available
          </span>
          <span class="alert-box-details">
            It looks like you didn't play for the given period, or you didn't update your stats
          </span>
        </div>
      </b-alert>
    </template>

    <template v-else>
      <div
        v-if="allowModeSelection === true"
        class="row justify-content-md-center"
      >
        <div class="col col-12 col-lg-6 col-xl-4">
          <div class="d-flex align-items-center">
            <b-form-group
              id="input-group-mode"
              class="floating-labels flex-grow-1 mb-0"
            >
              <multiselect
                id="input-mode"
                v-model="selectedMode"
                :placeholder="'Select a mode'"
                :multiple="false"
                :label="'label'"
                :close-on-select="true"
                :clear-on-select="false"              
                :options="modes"
                :track-by="'id'"
                :searchable="true"
                :allow-empty="false"
              >
                <template
                  slot="singleLabel"
                  slot-scope="{ option }"
                >
                  {{ option.label }}
                </template>             
              </multiselect>
              <label
                v-if="selectedMode"
                class="is-floating"
                for="input-mode"
              >
                Mode
              </label>             
            </b-form-group>
          </div>
        </div>
      </div>       
      <div
        v-if="selectedMode !== null"
        :key="selectedMode.id"
        class="row justify-content-md-center"
      >
        <div class="col col-12 mt-2 d-flex">
          <div class="ml-auto donut-chart">
            <player-summary-donut-chart
              :stats-keys="['kills', 'deaths']"
              :value="'kdRatio'"
              :value-ref="player.stats.kdRatio"
              :data="stats.stats[selectedMode.id]"
            />
          </div>
          <div class="mr-auto">
            <div class="pl-2 d-flex flex-column">
              <div class="d-flex flex-wrap p-2 box-container bg-charade br-16">
                <div
                  v-for="statsKey in ['gamesPlayed', 'killsPerGame', 'deathsPerGame', 'damageDonePerKill', 'downsPerGame', 'gulagPercentage']"
                  :key="statsKey"              
                  class="box-item d-flex align-items-center p-3 bg-woodsmoke"
                >
                  <h6>
                    {{ mapping.statsMapping[statsKey].label }}
                  </h6>
                  <h2
                    class="text-white"
                    :class="diffClass(statsKey)"
                  >
                    {{ stats.stats[selectedMode.id][statsKey].toFixed(mapping.statsMapping[statsKey].fixed) }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="mr-auto d-none d-lg-flex">
            <div class="pl-2 d-flex flex-column">
              <div class="d-flex flex-wrap p-2 box-container bg-charade br-16">
                <div
                  v-for="statsKey in ['assistsPerGame', 'headshots', 'longestStreak', 'damageDone', 'damageDonePerGame', 'damageTakenPerGame']"
                  :key="statsKey"              
                  class="box-item d-flex align-items-center p-3 bg-woodsmoke"
                >
                  <h6>
                    {{ mapping.statsMapping[statsKey].label }}
                  </h6>
                  <h2
                    class="text-white"
                  >
                    {{ stats.stats[selectedMode.id][statsKey].toFixed(mapping.statsMapping[statsKey].fixed) }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="mr-auto d-none d-lg-flex">
            <div class="pl-2 d-flex flex-column">
              <div class="d-flex flex-wrap p-2 box-container bg-charade br-16">
                <div
                  v-for="statsKey in ['wins', 'topFive', 'topTen', 'topTwentyFive', 'scorePerGame', 'timeAlive']"
                  :key="statsKey"              
                  class="box-item d-flex align-items-center p-3 bg-woodsmoke"
                >
                  <h6>
                    {{ mapping.statsMapping[statsKey].label }}
                  </h6>
                  <h2
                    class="text-white"
                  >
                    {{ stats.stats[selectedMode.id][statsKey].toFixed(mapping.statsMapping[statsKey].fixed) }}
                  </h2>
                </div>
              </div>
            </div>
          </div>                     
        </div>
      </div>
    </template> 
  </section> 
</template>

<style lang="scss" scoped>
  @import '~@/assets/style/colors.scss';

  .donut-chart {
    display: flex;
    justify-content: center;
    align-items: center; 
  }

  div.box-container {
    height: 180px;

    & > div.box-item {
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;        
      }
    }
  }

  div.box-item {
    width: 100%;
    height: 26px;
    padding: 8px !important;
    text-align: left;

    &:last-child {
      margin-right: 0px;
    }

    & > h6 {
      text-align: left;
      line-height: 14px;
      font-size: 10px;
      margin-bottom: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 72px; 
      margin-bottom: auto;
      margin-top: auto;      
    }

    & > h2 {
      margin-left: auto;
      margin-bottom: auto;
      margin-top: auto;
      text-align: left;
      font-size: 12px;
      line-height: 14px;     
    }

    & > img {
      width: 24px;
    }

    &.box-md {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      margin: 4px;
      padding: 8px !important;

      & > h6 {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & > h2 {
        margin-left: auto;
        margin-bottom: auto;
        margin-top: auto;
        text-align: left;
        font-size: 12px;
        line-height: 12px;
      } 
      
      & img {
        width: 24px;
      }      
    }    

    &.box-sm {
      width: 48px;
      height: 48px;
      border-radius: 4px;
      margin: 4px;
      padding: 8px !important;

      & > h6 {
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 0px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & > h2 {
        margin-left: auto;
        margin-bottom: auto;
        margin-top: auto;
        text-align: left;
        font-size: 12px;
        line-height: 12px;
      } 
      
      & img {
        width: 16px;
        height: 16px;
      }
    }
  }  
</style>

<script>

import { mapState } from 'vuex' 

import playerSummaryDonutChart from '@/components/players/PlayerSummaryDonutChart.vue'

export default {
  name: 'PlayerSummaryStats',
  components: {
    playerSummaryDonutChart
  },
  props: {
    player: {
      type: Object,
      required: true
    },
    stats: {
      type: Object,
      default: null
    },
    allowModeSelection: {
      type: Boolean,
      default: true
    },
    selectedModeParent: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedMode: null
    }
  },
  computed: mapState({
    modes: function() {
      const modes = []
      const keys = this.stats.modes
      keys.forEach((key) => {
        modes.push({
          id: key,
          label: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].label : key,
          order: this.mapping.modesMapping[key] ? this.mapping.modesMapping[key].order : 9999,
        })
      })

      return modes.sort((a, b) => {
        if(a.order < b.order) { return -1; }
        if(a.order > b.order) { return 1; }
        return 0;
      })
    },
  }),  
  created: function() {
    if (this.stats === null) {
      return
    }
    if (this.selectedMode === null) {
      if (this.selectedModeParent) {
        this.selectedMode = this.selectedModeParent
      } else {
        this.selectedMode = this.modes[0]
      }
    }
  },
  mounted: function() {

  },  
  methods: {
    diffClass(statsKey) {
      if (['gamesPlayed', 'downsPerGame'].indexOf(statsKey) >= 0) {
        return 'text-whtie'
      }
      const value = this.stats.stats[this.selectedMode.id][statsKey]
      const valueRef = this.player.stats[statsKey]
      if (value && valueRef) {
        const diff = value - valueRef
        if (diff > 0) {
          return 'text-success'
        } else if (diff < 0) {
          return 'text-danger'
        } else {
          return 'text-white'
        }
      }
    }
  }  
}
</script>
