<template>
  <section>
    <div class="row justify-content-center">
      <template v-if="isLoading === true">
        <loader />
      </template>

      <template v-else>
        <template v-if="hasError === true">
          <b-alert
            class="info round"
            show
          >
            <div class="alert-box-content">
              <b-icon
                icon="exclamation-circle"
                variant="danger"
              />              
              <span class="alert-box-message">
                Oops, something went wrong
              </span>
              <span class="alert-box-details">
                Unable to load the data
              </span>
            </div>
          </b-alert>
        </template>

        <template v-else>
          <div class="col col-12">
            <swiper
              v-if="showSlider === true"
              ref="slider"
              class="swiper"
              :options="swiperOption"
            >
              <div
                v-if="isSmallDevice === true"
                slot="pagination"
                class="swiper-pagination"
              /> 

              <swiper-slide
                v-for="(card, index) in cards"
                :key="index"
                :index="index + 1"
              >
                <admin-stat-card
                  :id="card.id"
                  :title="card.title"
                  :value="data.repartitions[card.value]"
                  :color="card.color"
                  :selected="selected === card.id"
                  :selectable="true"
                />
              </swiper-slide>                                         
            </swiper>
          </div>            
          <div class="col col-12 mt-2 charts">
            <admin-squads-table />
          </div>
        </template>
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/assets/style/colors.scss';

</style>

<script>

import http from '@/services/http.service.js'

import { mapState } from 'vuex' 

import Loader from '@/components/layout/Loader.vue'

import AdminStatCard from '@/components/admin/cards/AdminStatCard.vue'
import AdminSquadsTable from '@/components/admin/tables/AdminSquadsTable.vue'

export default {
  name: 'AdminPlayers',
  components: {
    Loader,
    AdminStatCard,
    AdminSquadsTable
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      showSlider: false,
      selected: 'squads',      
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        freeMode: false,
        grabCursor: true,
        loop: false,
        loopFillGroupWithBlank: false,
        mousewheel: {
          forceToAxis: true,
          invert: true
        },
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }        
      }       
    }
  },
  computed: mapState({

  }),  
  created: function() {
    this.$root.$on('refresh-admin', () => {
      this.load()
    })

    this.load()
  },
  mounted: function() {
    // this.swiperOption.slidesPerView = this.isSmallDevice === true ? 2 : this.cards.length
    // this.showSlider = true
  },  
  methods: {
    load() {
      this.isLoading = true
      this.hasError = false
      http.getAdminSquads().then(
        (response) => {
          this.data = response.data
        }
      ).catch(
        () => {
          this.hasError = true
        }
      ).finally(
        () => {
          this.isLoading = false
        }
      )
    }
  }  
}
</script>
