<template>
  <section class="pt-1 pb-1">
    <template>
      <swiper
        class="swiper"
        :options="swiperOption"
      >
        <div
          slot="pagination"
          class="swiper-pagination"
        />        
        <swiper-slide
          v-for="(badge, index) in sessionBadges"
          :key="index"          
          :index="index"
        >
          <div
            class="d-flex flex-column align-items-center"
          >
            <div class="p-1">
              <h6 class="color-white">
                {{ badge.title }}
              </h6>
            </div>
            <div
              class="badge-logo"
            >
              <div
                class="reward"
                :class="badge.grade ? badge.grade : ''"
              >
                <div class="shine" />
                <div class="circle" />
                <img  
                  width="120"
                  :src="badge.logo"
                >
              </div>              
            </div>              
            <div
              class="p-3 badge-result"
            >
              <a
                v-if="badge.revealed === false"
                class="shiny sm"
                :class="badge.grade"
                @click="revealPlayer(badge)"
              >
                Reveal
                <i />
              </a>
              <template v-else>
                <div @click="openModal(badge)">
                  <h3 class="fade-in">
                    {{ badge.result }}
                  </h3>
                  <small
                    v-if="badge.max"
                    class="fade-in text-gray"
                  >
                    <b>{{ badge.max.toFixed(2) }}</b> points
                  </small>  
                  <div
                    class="fade-in text-gray ml-2"
                  >
                    <b-icon icon="question-circle" /> <small>
                      Click to get details
                    </small>
                  </div> 
                </div>               
              </template>            
            </div> 
            <div>
              <p>{{ badge.description }}</p>
              <small class="text-gray">
                <span v-if="badge.keyStats">
                  {{ badge.keyStats }}
                </span>
                <span
                  v-else
                  v-html="badge.details"
                />
              </small>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <!-- <div class="swiper-navigation">
        <div class="swiper-button-prev" />
        <div class="swiper-button-next" />
      </div> -->
    </template>

    <badge-modal
      :badge="selectedBadge"
      :stats="selectedBadgeStats"
    />
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/style/colors.scss';


.badge-result {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
  border-top: 1px solid $color-woodsmoke;
  border-bottom: 1px solid $color-woodsmoke;

  & h3 {
    height: 62px;
    line-height: 62px;
    margin-bottom: 0px;
  }
}

.special-badge {
  transform: translateY(100%);
}

</style>

<script>

import { mapState } from 'vuex' 

import badgeModal from '@/components/badges/BadgeModal.vue'

export default {
  name: 'SessionBadges',
  components: {
    badgeModal
  },
  props: {
    session: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      badgesDisplayed: true,
      badges: null,
      selectedBadge: null,
      selectedBadgeStats: null,
      badgesOrder: {
        'bronze': 0,
        'silver': 1,
        'gold': 2,
        'epic': 3,
        'master': 4,
        'legendary': 5,
        'special': 6,
        'god': 7
      },
      reveal: null,
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        freeMode: false,
        grabCursor: true,
        loop: false,
        loopFillGroupWithBlank: false,
        mousewheel: {
          forceToAxis: true,
          invert: true
        },
        autoHeight: false,
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }        
      }          
    }
  },
  computed: mapState({
    sessionBadges: function() {
      let badges = []
      Object.keys(this.mapping.badgesMapping).forEach(
        (key) => {
          if (key !== 'MVP' && this.badges[key] && this.badges[key].result !== null) {
            badges.push(
              { ...this.mapping.badgesMapping[key], ...this.badges[key],
                ...{
                  revealed: key !== 'MVP' && ['god', 'special', 'legendary'].indexOf(this.badges[key].grade) < 0,
                  id: key 
                }
              }
            )
          }
        }
      )

      badges.sort((a, b) => {
        a.order = this.badgesOrder[a.grade]
        b.order = this.badgesOrder[b.grade]

        if (this.badgesOrder[b.grade] > this.badgesOrder[a.grade]) {
          return 1
        } else if (this.badgesOrder[b.grade] < this.badgesOrder[a.grade]) {
          return -1
        } else {
          return 0
        }
      })

      // Ad MVP to the begining
      if (this.badges['MVP']) {
        badges.unshift(
          { ...this.mapping.badgesMapping['MVP'], ...this.badges['MVP'], ...{ revealed: false }}
        )
      }

      return badges
    }
  }),  
  created: function() {
    this.badges = this.session.badges.list
  },
  mounted: function() {

  },  
  methods: {
    displayBadges() {
      this.badgesDisplayed = true
    },
    revealPlayer(badge) {
      badge.revealed = true
      this.$forceUpdate()
    },
    revealSpecial(badge) {
      badge.specialRevealed = true
      this.$forceUpdate()
    },
    openModal(badge) {
      this.selectedBadge = badge
      this.$root.$emit('bv::show::modal', 'modal-badge')
    }   
  }  
}
</script>
