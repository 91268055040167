import { render, staticRenderFns } from "./SquadPlayersSummary.vue?vue&type=template&id=3215f47b&scoped=true&"
import script from "./SquadPlayersSummary.vue?vue&type=script&lang=js&"
export * from "./SquadPlayersSummary.vue?vue&type=script&lang=js&"
import style0 from "./SquadPlayersSummary.vue?vue&type=style&index=0&id=3215f47b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3215f47b",
  null
  
)

export default component.exports