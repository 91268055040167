<template>
  <section>
    <template v-if="hasError === true">
      <b-alert
        class="info round"
        show
      >
        <div class="alert-box-content">
          <b-icon
            icon="exclamation-circle"
            variant="danger"
          />              
          <span class="alert-box-message">
            Oops, something went wrong
          </span>
          <span class="alert-box-details">
            Unable to load the data
          </span>
        </div>
      </b-alert>      
    </template>    
    <template v-else>
      <div class="d-flex table-filters pb-4 align-items-center">
        <div class="flex-grow-1">
          <b-form-group
            :id="`input-group-username`"
            class="floating-labels mb-0"
          >
            <b-form-input
              :id="`input-username`"
              :ref="`input-username`"
              v-model="filters.username"
              type="text"
              trim
              :placeholder="`Search for Username`"
              :aria-describedby="`feedback-username`"
            />
            <label :for="`input-username`">
              Search for Username
            </label>             
          </b-form-group> 
        </div>
      </div>
      <div class="table">
        <b-table
          id="pull-table"
          :items="items.list"
          :fields="fields"      
          :busy="tableLoading === true"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          small      
        >
          <!-- EMPTY -->
          <template v-slot:empty>
            <div class="text-center my-2">
              <strong>
                No data found, please change the filters
              </strong>
            </div>            
          </template>
        
          <!-- LOADER -->
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
              <strong>
                Loading, please wait...
              </strong>
            </div>
          </template>

          <template v-slot:cell(platform)="data">
            <b-badge variant="dark">
              {{ data.item.platform.toUpperCase() }}
            </b-badge>             
          </template>       

          <template v-slot:cell(timestamp)="data">
            {{ data.item.timestamp ? formatDate(new Date(data.item.timestamp), 'dd/MM/yyyy hh:mm:ss') : '-' }}
          </template>       

          <template v-slot:cell(username)="data">
            <div
              class="text-left clickable"
              @click="goToPlayer(data.item.username)"
            >
              {{ data.item.username }}
            </div>
          </template> 

          <template v-slot:cell(kdRatio)="data">
            <div class="text-center">       
              <b-badge
                variant="dark"
                class="badge-pull"
                :class="getRatioStatus(data.item.kdRatio)"
              >
                {{ data.item.kdRatio ? data.item.kdRatio.toFixed(2) : '-' }}
              </b-badge>
            </div>
          </template>           

          <template v-slot:cell(nbGames)="data">
            <div class="text-center overflow">
              {{ data.item.nbGames }}
            </div>
          </template> 
          <template v-slot:cell(status)="data">
            <div class="text-left overflow">
              <div>
                <b-badge
                  :variant="getStatus(data.item, 'MWBattleData')"
                  class="badge-pull"
                >
                  GAMES
                </b-badge>
                {{ data.item.MWBattleData ? formatDate(new Date(data.item.MWBattleData), 'dd/MM/yyyy hh:mm:ss') : '-' }}
              </div>
              <div>
                <b-badge
                  :variant="getStatus(data.item, 'MWcombatwz')"
                  class="badge-pull"
                >
                  TOTAL
                </b-badge>
                {{ data.item.MWcombatwz ? formatDate(new Date(data.item.MWcombatwz), 'dd/MM/yyyy hh:mm:ss') : '-' }}
              </div>
              <div>
                <b-badge
                  :variant="getStatus(data.item, 'MWweeklystats')"
                  class="badge-pull"
                >
                  WEEKLY
                </b-badge>
                {{ data.item.MWweeklystats ? formatDate(new Date(data.item.MWweeklystats), 'dd/MM/yyyy hh:mm:ss') : '-' }}</div>
            </div>
          </template>                         
        </b-table>

        <div class="table-footer p-3">
          <span class="table-caption text-white">
            {{ items.count }} / {{ items.total }}
          </span>        
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            @change="onPageChange"
          />
        </div>
      </div> 
    </template> 
  </section>
</template>

<style lang="scss" scoped>

  @import '@/assets/style/colors.scss';

  div.table {
    border-radius: 16px;
    background-color: $color-charade;
    width: auto;
    overflow-x: auto;    
  }

  /deep/ table > thead {
    background-color: $color-charade;
    border-radius: 16px;
  }

  /deep/ table > tbody {
    background-color: $color-woodsmoke;

    & > tr {

      &:hover {
        background-color: $color-charade;
      }

      & > td {

      }
    }
  }

  div.overflow {
    overflow-y: auto;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .badge-pull {
    width: 60px;
  }

</style>

<script>

import { mapState } from 'vuex' 

import http from '@/services/http.service.js'

export default {
  name: 'AdminPlayersTable',
  components: {
    
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      tableLoading: false,
      hasError: false,
      items: {
        total: 0,
        list: []
      },
      perPage: 15,
      currentPage: 1,
      sortBy: 'timestamp',
      sortDesc: true,
      filters: {
        username: null
      },
      fields: [
        { key: 'platform', sortable: false },
        { key: 'username', sortable: false },
        { key: 'kdRatio', sortable: false },
        { key: 'nbGames', sortbale: false, label: '# Games' },
        { key: 'status', sortable: false },
        { key: 'timestamp', sortable: false, label: 'Added on' },        
      ], 
      timeoutFct: null      
    }
  },
  computed: mapState({
    rows() {
      return this.items.total
    }
  }),
  watch: {
    'filters.username': function(newVal, oldVal) {
      if (newVal !== oldVal
        && (
            newVal === null
          || newVal.length === 0
          || newVal.length >= 3)
       ) {
          if (this.timeoutFct) {
            clearTimeout(this.timeoutFct)
          }
          this.timeoutFct = setTimeout(
            () => {
              this.load()
            }, 500
          )
        }
    }
  },    
  created: function() {

  },
  mounted: function() {
    this.isLoading = true
    this.load()
  },  
  methods: {
    load() {
      this.hasError = false
      this.tableLoading = true

      const params = {
        filters: this.filters,
        startAt: (this.currentPage - 1) * this.perPage,
        limit: this.perPage
      }

      http.getAdminPlayersList(params).then(
        (response) => {
          this.items = response.data
        }
      ).catch(
        () => {
          this.hasError = true
        }
      ).finally(
        () => {
          this.isLoading = false
          this.tableLoading = false
        }
      )
    },
    updateFilterStatus(type) {
      this.filters.status[type] = !this.filters.status[type]

      this.load()
    },
    onPageChange: function(page) {
      this.currentPage = page
      
      this.load()
    }, 
    getRatioStatus(ratio) {
      if (ratio >= 6) {
        return 'orange'
      } else if (ratio >= 5) {
        return 'purple'
      } else if (ratio >= 4) {
        return 'teal'
      } else if (ratio >= 2) {
        return 'gold'
      } else if (ratio >= 1) {
        return 'silver'
      } else {
        return ''
      }
    },  
    getStatus(item, type) {
      const today = new Date().getTime()
      if (item[type]) {
        try {
          const time = item[time]

          if (today - time > 7 * 1000 * 60 * 60 * 24) { // one week
            return 'danger'
          } else if (today - time > 1 * 1000 * 60 * 60 * 24) { // Yesterday
            return 'warning'
          } else {
            return 'success'
          }
        } catch (e) {
          return 'dark'
        }
      }

      return 'dark'
    },
    goToPlayer(username) {
      this.$store.commit('global/SET_PLAYER_ID', username)
      this.$router.push(
        {
          name: 'Player',
          query: { 
            username: username
          }
        }
      )
    }    
  }  
}
</script>
