<template>
  <section>
    <div
      ref="chart"
      class="chart"
    />

    <div
      class="chart-value fade-in"
      :class="diffClass"
    >
      {{ data[value].toFixed(mapping.statsMapping[value].fixed) }}
    </div>
  </section>
</template>

<style lang="scss" scoped>
  
  @import '@/assets/style/colors.scss';

  section {
    border-radius: 16px;
    background-color: $color-charade;
    position: relative;
  }
  .chart {
    width: 180px;
    height: 180px;
    transform: translateX(-4px);
  }

  .chart-value {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(50%, 50%); */
    width: 72px;
    margin-left: -35px;
    height: 72px;
    margin-top: -36px;
    /* display: flex; */
    line-height: 72px;
    font-size: 28px;
    border-radius: 50%;
    background-color: $color-woodsmoke-opacity;
  }

  /deep/ .plot-label {
    position: relative;
    top: -8px;
    left: -12px;
    display: inline-block;
    padding: 4px;
    background-color: $color-blue;
    color: $color-white;
  }
</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'PlayerSummaryDonutChart',
  components: {

  },
  props: {    
    statsKeys: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    valueRef: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: mapState({
    diffClass() {
      const diff = this.data[this.value] - this.valueRef
      if (diff < 0) {
        return 'text-danger'
      } else if (diff > 0) {
        return 'text-success'
      } else {
        return 'text-white'
      }
    }
  }),  
  watch: {

  },  
  created: function() {

  },
  mounted: function() {
    this.initChart()
  },  
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },  
  methods: {
    initChart() {
      // Create chart instance
      const data = this.getChartData()

      let chart = this.Highcharts.chart(this.$refs['chart'], {
        chart: {
          animation: true,
          type: 'pie',
          backgroundColor: null
        },
        tooltip: {
          enabled: true
        },
        plotOptions: {
          pie: {
            animation: {
              duration: 750,
              easing: 'easeOutQuad'
            },
            shadow: true,
            center: ['50%', '50%'],
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              crop: false,
              shadow: true
            }
          },
          series: {
            animation: {
              duration: 750,
              easing: 'easeOutQuad'
            }
          }
        },
        series: [{
          animation: {
            duration: 750,
            easing: 'easeOutQuad'
          },
          name: '',
          data: data,
          size: '90%',
          innerSize: '65%',
          dataLabels: {
            formatter: function () {
              return this.y;
            },
            color: '#ffffff',
            distance: -10
          }
        }]
      })
      
      
      this.chart = chart      
    },
    getChartData() {
      let data = []
      this.statsKeys.forEach(
        (key) => {
          const category = this.mapping.statsMapping[key].label ? this.mapping.statsMapping[key].label : key
          const color = this.mapping.statsMapping[key].chartColor ? this.mapping.statsMapping[key].chartColor : null
          
          data.push({
            y: this.data[key],
            color: color,
            name: category
          })
        }
      )

      return data
    }
  }  
}
</script>
