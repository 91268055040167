<template>
  <div
    ref="chart"
    class="chart bg-woodsmoke mb-2"
    :style="{'height': `${chartHeight}px`}"
  />
</template>

<style lang="scss" scoped>
 .chart {
    width: 100%;
    border-radius: 16px;
 }
</style>

<script>

import { mapState } from 'vuex' 

export default {
  name: 'SessionGameChart',
  components: {
    
  },
  props: {   
    statsKeys: {
      type: Array,
      required: true
    },
    stats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: mapState({
    isSmallDevice: function() {
      const width = document.documentElement.clientWidth || document.body.clientWidth
      return width < 421
    },
    barWidth: function() {
      if (this.isSmallDevice === true) {
        return 24
      } else {
        return 32
      }
    },    
    chartHeight: function() {
      const bars = this.statsKeys.length
      let multiplier = Object.keys(this.stats).length

      return this.barWidth * bars * multiplier + bars * 48
    },    
    labelsWidth: function() {
      if (this.isSmallDevice === true) {
        return 75
      } else {
        return 150
      }      
    },
    labelsSize: function() {
      if (this.isSmallDevice === true) {
        return 10
      } else {
        return 16
      }      
    }    
  }),  
  watch: {
    selectedMode: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.chart.destroy()
        this.initChart()
      }
    } 
  },  
  created: function() {

  },
  mounted: function() {
    this.initChart()
  },  
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },  
  methods: {
    initChart() {
      // Create chart instance
      const data = this.getChartData()

      let chart = this.Highcharts.chart(this.$refs['chart'], {
        chart: {
          type: 'bar',
          marginLeft: this.labelsWidth
        },
        xAxis: {
          categories: data.categories                         
        },
        plotOptions: {
          series: {
            pointWidth: this.barWidth          
          }    
        },
        series: data.series
      });
      
      this.chart = chart;      
    },
    getChartData() {
      let series = {}
      let categories = []
      this.statsKeys.forEach(
        (key) => {
          const toFixed = this.mapping.statsMapping[key].fixed ? this.mapping.statsMapping[key].fixed : 0
          const divider = this.mapping.statsMapping[key].divider ? this.mapping.statsMapping[key].divider : 1          
          const category = this.mapping.statsMapping[key].label ? this.mapping.statsMapping[key].label : key
          categories.push(category)
          Object.keys(this.stats).forEach(
            (player) => {
              if (series[player] === undefined) {
                series[player] = {
                  name: player,
                  data: []                  
                }
              } 
              series[player].data.push(
                this.stats[player][key] 
                  ? parseFloat((this.stats[player][key] / divider).toFixed(toFixed))
                  : 0                
              )
            }
          )
        }
      )

      return {
        categories: categories,
        series: Object.values(series)
      }
    }
  }  
}
</script>
